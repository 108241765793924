<template>
  <v-container fluid class="container">
    <v-row class="mt-3 div-default">
      <div class="header mt-md-7 mr-md-3">
        Controle de Leads
      </div>
      <v-col cols="12" md="6" lg="5" xl="4">
        <base-text-field id="pesquisa" name="pesquisa" v-model="pesquisa" label="Pesquisar por nome, e-mail, telefone ou origem" icon-append="mdi-magnify" clearable outlined hide-details />
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" outlined @click="showFilters = !showFilters">
          <v-icon size="25" color="primary" class="mr-2">
            mdi-filter-variant
          </v-icon>
          FILTROS ({{ countFilters }})
        </v-btn>
      </v-col>
    </v-row>
    <div class="div-default">
      <v-row>
        <v-col>
          <v-tabs v-model="tab" background-color="#fff" color="primary">
            <v-tab v-for="(item, key) in colLeads" :key="key" :disabled="loading" class="text-capitalize width-100">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <div v-if="tab == 0" class="mt-5">
        <v-btn v-for="(item, key) in boolFilters" :key="key" color="primary" rounded height="30" class="btn-filter mr-2 my-3 my-md-1">
          <div v-if="item.simpleKey && $vuetify.breakpoint.xs">
            Ord.:
            <v-icon size="20" color="white"> {{ item.icon }} </v-icon>
            {{ item.simpleKey }}
          </div>
          <span v-else>{{ item.newKey }}</span>
          <span class="btn-x ml-2" @click="onClickX(item.key)"> x </span>
        </v-btn>
        <v-progress-circular v-if="loading" color="primary" indeterminate class="loading" />
        <default-kanban
          v-else
          tipoTab="ativos"
          :leads="filteredLeads.ativos"
          :defaultURL="defaultURL"
          :leadSelecionado="leadDefaultSelecionado"
          @carrega-dados="carregaDados"
          @visualizado="changeFlagVisualizado"
          @modal="modal"
          @setOrdenacao="setOrdenacao"
          class="mt-5"
        />
      </div>
      <div v-if="tab == 1" class="mt-5">
        <v-btn v-for="(item, key) in boolFilters" :key="key" color="primary" rounded height="30" class="btn-filter mr-2 my-3 my-md-1">
          <div v-if="item.simpleKey && $vuetify.breakpoint.xs">
            Ord.:
            <v-icon size="20" color="white"> {{ item.icon }} </v-icon>
            {{ item.simpleKey }}
          </div>
          <span v-else>{{ item.newKey }}</span>
          <span class="btn-x ml-2" @click="onClickX(item.key)"> x </span>
        </v-btn>
        <v-progress-circular v-if="loading" color="primary" indeterminate class="loading" />
        <default-kanban
          v-else
          tipoTab="mailing"
          :leads="filteredLeads.mailing"
          :defaultURL="defaultURL"
          :leadSelecionado="leadDefaultSelecionado"
          @carrega-dados="carregaDados"
          @visualizado="changeFlagVisualizado"
          @modal="modal"
          @setOrdenacao="setOrdenacao"
          class="mt-5"
        />
      </div>
      <div v-if="tab == 2" class="mt-5">
        <v-btn v-for="(item, key) in boolFilters" :key="key" color="primary" rounded height="30" class="btn-filter mr-2 my-3 my-md-1">
          <div v-if="item.simpleKey && $vuetify.breakpoint.xs">
            Ord.:
            <v-icon size="20" color="white"> {{ item.icon }} </v-icon>
            {{ item.simpleKey }}
          </div>
          <span v-else>{{ item.newKey }}</span>
          <span class="btn-x ml-2" @click="onClickX(item.key)"> x </span>
        </v-btn>
        <v-progress-circular v-if="loading" color="primary" indeterminate class="loading" />
        <finalizados
          v-else
          :leads="filteredLeads.finalizados"
          :defaultURL="defaultURL"
          :leadSelecionado="leadFinalizadoSelecionado"
          @carrega-dados="carregaDados"
          @modal="modal"
          @setOrdenacao="setOrdenacao"
          class="mt-5"
        />
      </div>
    </div>
    <filter-component :drawer="showFilters" @apply-filters="applyFilters" @close-filters="closeFilters" @clear-filters="clearFilters" :form="filtersSideBar" :tab="tab" />
  </v-container>
</template>
<script>
import BaseTextField from "@/components/BaseTextField.vue";
import DefaultKanban from "@/components/leads/DefaultKanban.vue";
import Finalizados from "@/components/leads/Finalizados.vue";
import FilterComponent from "@/components/leads/FilterComponent.vue";
import filter from "lodash/filter";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import first from "lodash/first";
import leadService from "@/services/leadService";
import { mapState } from "vuex";

export default {
  data() {
    return {
      pesquisa: "",
      timer: "",
      tab: 0,
      possuiPlantao: false,
      leads: {
        ativos: {
          recebidos: [],
          semSucesso: [],
          negociacao: [],
        },
        mailing: {
          recebidos: [],
          semSucesso: [],
          negociacao: [],
        },
        finalizados: {
          vendidos: [],
          naoVendidos: [],
        },
      },
      showFilters: false,
      filters: {},
      filtersSideBar: {
        leadsSemAgendamento: false,
        dataRecebimento: false,
        dataRecebimentoInicioFim: [],
        dataAtualizacao: false,
        dataAtualizacaoInicioFim: [],
        dataFinalizacao: false,
        dataFinalizacaoInicioFim: [],
        cotacaoEnviada: false,
        propostasEnviadas: false,
        negociacaoHotline: false,
        negociacaoQualiatende: false,
      },
      defaultObjectLeads: {
        ativos: {
          recebidos: [],
          semSucesso: [],
          negociacao: [],
        },
        mailing: {
          recebidos: [],
          semSucesso: [],
          negociacao: [],
        },
        finalizados: {
          vendidos: [],
          naoVendidos: [],
        },
      },
      loading: true,
      defaultURL: null,
      flagAutoUpdate: true,
      leadDefaultSelecionado: 0,
      leadFinalizadoSelecionado: 0,
      leadCarregado: false,
      colLeads: ["Ativos", "Mailing", "Finalizados"],
      ordenacao: {
        recebidos: null,
        semSucesso: null,
        negociacao: null,
        vendidos: null,
        naoVendidos: null,
      },
    };
  },
  components: {
    BaseTextField,
    DefaultKanban,
    Finalizados,
    FilterComponent,
  },
  async mounted() {
    this.defaultURL = window.location.href.slice(-1) === "/" ? window.location.href.slice(0, -1) : window.location.href;
    if (this.$route.params.codigo) this.defaultURL = this.defaultURL.slice(0, (this.$route.params.codigo.length + 1) * -1);
    if (this.$route.params.plantao) this.$router.go();
    else {
      await this.carregaDados(this.$route.params.codigo || 0);
      this.timer = setInterval(this.fetchEventsList, 300000);
    }
  },
  created() {
    this.possuiPlantaoCorretor();
  },
  watch: {
    tab(newVal, oldVal) {
      if (!this.leadCarregado) {
        if (newVal === 1 || oldVal === 1) {
          const { recebidos, semSucesso, negociacao } = this.ordenacao;
          if (recebidos === "Menor SLA" || recebidos === "Maior SLA") this.ordenacao.recebidos = null;
          if (semSucesso === "Menor SLA" || semSucesso === "Maior SLA") this.ordenacao.semSucesso = null;
          if (negociacao === "Menor SLA" || negociacao === "Maior SLA") this.ordenacao.negociacao = null;
        }
        if (newVal === 2 || oldVal === 2) {
          this.clearSort();
          this.clearAppliedFilters(false);
        } else this.carregaDados();
      } else this.leadCarregado = false;
    },
  },
  methods: {
    async carregaDados(leadSelecionado = 0) {
      /* eslint-disable */
      try {
        // Reset data leads
        this.resetData();

        this.loading = true;
        const status = ["Leads recebidos", "Em negociação", "Sem sucesso"];
        const statusFinalizacao = [
          "Expirado",
          "Proposta gerada por outro corretor",
          "Convertido",
          "Convertido por outro corretor",
          "Proposta cancelada",
          "Não trabalhado",
          "Finalizado",
          "Proposta em andamento",
        ];

        let filtroStatus = [];
        if (leadSelecionado && !isNaN(parseInt(leadSelecionado))) filtroStatus = status.concat(statusFinalizacao);
        else if (this.tab === 2) filtroStatus = statusFinalizacao;
        else filtroStatus = status;

        const leads = await leadService.lista({ status: filtroStatus, filtroEstatico: this.setFiltros(), plantaoAtivo: this.possuiPlantao, leadMailing: !!(this.tab === 1) });
        const listLeads = orderBy(leads.leads, ["alertaTratamento"], ["desc"]);

        for (let i = 0; i < listLeads.length; i++) {
          const lead = listLeads[i];

          let newOrigem = lead.plataforma ? lead.plataforma : "";
          if (newOrigem !== "" && lead.grupoOrigem) {
            newOrigem = newOrigem.concat(" - ".concat(lead.grupoOrigem ? lead.grupoOrigem : ""));
          }

          const defaultLead = {
            idLead: lead.idLead,
            propostaGerada: lead.propostaGerada,
            nome: lead.nome,
            codigo: lead.codigo,
            segmentacao: lead.segmentacao || "--",
            telefoneList: lead.telefones && lead.telefones.length ? lead.telefones : [],
            emailList: lead.emails && lead.emails.length ? lead.emails : [],
            local: lead.cidade && lead.estado ? lead.cidade.concat(" - ").concat(lead.estado) : null,
            origem: newOrigem || "--",
            dataHoraCriacao: lead.dataHoraCriacao ? lead.dataHoraCriacao : "--",
            dataHoraTratamento: lead.dataHoraTratamento ? lead.dataHoraTratamento : "--",
            dataHoraEncerramento: lead.dataHoraEncerramento ? lead.dataHoraEncerramento : lead.dataHoraTratamento ? lead.dataHoraTratamento : "--",
            expiracao: lead.prazoSLA ? this.formataExpiracao(lead.prazoSLA) : "--",
            flagExpirando: lead.prazoSLA ? this.showExpiracao(lead.prazoSLA) : false,
            tipoLead: lead.ratingLead ? this.setTermometro(lead.ratingLead) : "0%",
            prazoSLA: lead.prazoSLA ? lead.prazoSLA : {},
            motivoNaoVenda: lead.tratamentoAplicado ? lead.tratamentoAplicado : statusFinalizacao.includes(lead.status) ? lead.status : "--",
            possuiAlerta: !!lead.alertaTratamento,
            visualizacao: lead.visualizacao ? lead.visualizacao : false,
            // Utilizados no campo de pesquisa
            strTelefones: lead.telefones && lead.telefones.length ? lead.telefones.map((e) => (e.ddd + e.numero).replace(/[^\d]+/g, "")).toString() : "--",
            strEmails: lead.emails && lead.emails.length ? lead.emails.map((e) => e.email).toString() : "--",
            strNome: lead.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            strOrigem: lead.grupoOrigem ? lead.grupoOrigem.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : null,
            status: lead.status,
          };

          if (lead.status) {
            if (lead.status === "Leads recebidos") lead.leadMailing ? this.leads.mailing.recebidos.push(defaultLead) : this.leads.ativos.recebidos.push(defaultLead);
            else if (lead.status === "Em negociação") lead.leadMailing ? this.leads.mailing.negociacao.push(defaultLead) : this.leads.ativos.negociacao.push(defaultLead);
            else if (["Sem sucesso"].includes(lead.status)) lead.leadMailing ? this.leads.mailing.semSucesso.push(defaultLead) : this.leads.ativos.semSucesso.push(defaultLead);
            else if (lead.status === "Finalizado") lead.gerouVenda ? this.leads.finalizados.vendidos.push(defaultLead) : this.leads.finalizados.naoVendidos.push(defaultLead);
            else if (statusFinalizacao.includes(lead.status)) this.leads.finalizados.naoVendidos.push(defaultLead);
          }
        }

        // Ordenação leads não vendidos com possibilidade de reativação e criação de proposta
        this.leads.finalizados.naoVendidos = sortBy(this.leads.finalizados.naoVendidos, function(e) {
          return e.motivoNaoVenda !== "Expirado";
        }).reverse();

        const { recebidos, semSucesso, negociacao, vendidos, naoVendidos } = this.ordenacao;
        this.ordenar({ recebidos, semSucesso, negociacao, vendidos, naoVendidos });

        if (leadSelecionado && !isNaN(parseInt(leadSelecionado))) {
          this.leadCarregado = true;
          const leadEncontrado = find(listLeads, { codigo: leadSelecionado });
          if (leadEncontrado) {
            if (leadEncontrado.status === "Leads recebidos" || leadEncontrado.status === "Em negociação" || leadEncontrado.status === "Sem sucesso") {
              this.tab = leadEncontrado.leadMailing ? 1 : 0;
              this.leadDefaultSelecionado = parseInt(leadSelecionado);
            } else {
              this.tab = 2;
              this.leadFinalizadoSelecionado = parseInt(leadSelecionado);
            }
          }
        } else {
          this.leadDefaultSelecionado = 0;
          this.leadFinalizadoSelecionado = 0;
          this.leadCarregado = false;
        }
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
        this.clearFilters();
        this.clearAppliedFilters(true);
      } finally {
        this.loading = false;
      }
      /* eslint-enable */
    },
    setFiltros() {
      let filtro;
      if (this.filtersSideBar) {
        let dataRecebimento;
        let dataAtualizacao;
        let dataFinalizacao;

        if (this.filtersSideBar.dataRecebimento) {
          dataRecebimento = {
            dataIni: this.filtersSideBar.dataRecebimentoInicioFim[0],
            dataFin: this.filtersSideBar.dataRecebimentoInicioFim[1],
          };
        }

        if (this.filtersSideBar.dataAtualizacao) {
          dataAtualizacao = {
            dataIni: this.filtersSideBar.dataAtualizacaoInicioFim[0],
            dataFin: this.filtersSideBar.dataAtualizacaoInicioFim[1],
          };
        }

        if (this.filtersSideBar.dataFinalizacao) {
          dataFinalizacao = {
            dataIni: this.filtersSideBar.dataFinalizacaoInicioFim[0],
            dataFin: this.filtersSideBar.dataFinalizacaoInicioFim[1],
          };
        }

        filtro = {
          leadsSemAgendamento: this.filtersSideBar.leadsSemAgendamento,
          propostasEnviadas: this.filtersSideBar.propostasEnviadas,
          cotacaoEnviada: this.filtersSideBar.cotacaoEnviada,
          negociacaoHotline: this.filtersSideBar.negociacaoHotline,
          negociacaoQualiatende: this.filtersSideBar.negociacaoQualiatende,
          dataRecebimento,
          dataAtualizacao,
          dataFinalizacao,
        };
      }
      return filtro;
    },
    setTermometro(value) {
      // Verificar, foi alinhado que o termometro vai de 80 a 100
      if (value) {
        if (value >= 80 && value <= 84) return "25%";
        if (value >= 85 && value <= 89) return "50%";
        if (value >= 90 && value <= 95) return "75%";
      }
      return "100%";
    },
    /* eslint-disable */
    formataExpiracao(expiracao) {
      if (expiracao) {
        if (parseInt(expiracao.horasValidas) === 0 && parseInt(expiracao.minutosValidos) === 0) return "--";
        return expiracao.horasValidas
          .toString()
          .concat(" hora(s) e ")
          .concat(
            parseInt(expiracao.minutosValidos)
              .toString()
              .concat(" minuto(s)")
          );
      }
      return "--";
    },
    showExpiracao(expiracao) {
      return parseInt(expiracao.horasValidas) === 0 && parseInt(expiracao.minutosValidos) !== 0;
    },
    /* eslint-enable */
    closeFilters() {
      this.showFilters = false;
    },
    applyFilters(value) {
      this.filters = value;
      this.carregaDados();
      this.showFilters = false;
    },
    clearFilters() {
      this.filtersSideBar = {
        leadsSemAgendamento: false,
        dataRecebimento: false,
        dataRecebimentoInicioFim: [],
        dataAtualizacao: false,
        dataAtualizacaoInicioFim: [],
        dataFinalizacao: false,
        dataFinalizacaoInicioFim: [],
        cotacaoEnviada: false,
        propostasEnviadas: false,
        negociacaoHotline: false,
        negociacaoQualiatende: false,
      };
    },
    clearAppliedFilters(error) {
      const defaultFilters = {
        leadsSemAgendamento: false,
        dataRecebimento: false,
        dataRecebimentoInicioFim: [],
        dataAtualizacao: false,
        dataAtualizacaoInicioFim: [],
        dataFinalizacao: false,
        dataFinalizacaoInicioFim: [],
        cotacaoEnviada: false,
        propostasEnviadas: false,
        negociacaoHotline: false,
        negociacaoQualiatende: false,
      };
      this.filters = cloneDeep(defaultFilters);
      this.filtersSideBar = cloneDeep(defaultFilters);
      if (!error) this.carregaDados();
    },
    onClickX(key) {
      if (key === "ordenacaoRecebidos") this.ordenacao.recebidos = null;
      else if (key === "ordenacaoSemSucesso") this.ordenacao.semSucesso = null;
      else if (key === "ordenacaoNegociacao") this.ordenacao.negociacao = null;
      else if (key === "ordenacaoVendidos") this.ordenacao.vendidos = null;
      else if (key === "ordenacaoNaoVendidos") this.ordenacao.naoVendidos = null;
      else {
        this.filters[key] = false;
        this.filtersSideBar[key] = false;
        if (key === "dataRecebimento") {
          this.filtersSideBar.dataRecebimentoInicioFim = [];
        } else if (key === "dataAtualizacao") {
          this.filtersSideBar.dataAtualizacaoInicioFim = [];
        } else if (key === "dataFinalizacao") {
          this.filtersSideBar.dataFinalizacaoInicioFim = [];
        }
      }
      this.carregaDados();
    },
    resetData() {
      this.leads = {
        ativos: {
          recebidos: [],
          semSucesso: [],
          negociacao: [],
        },
        mailing: {
          recebidos: [],
          semSucesso: [],
          negociacao: [],
        },
        finalizados: {
          vendidos: [],
          naoVendidos: [],
        },
      };
    },
    mostrarMensagem(msg) {
      this.$root.$snackBar.open({
        color: "success",
        message: msg,
      });
    },
    async fetchEventsList() {
      if (this.$route.name === "areaLogada.lead" && this.flagAutoUpdate) {
        await this.carregaDados();
        this.mostrarMensagem("Recarregando informações dos Leads!");
      }
    },
    changeFlagVisualizado(idLead, mailing) {
      const index = findIndex(mailing ? this.leads.mailing.recebidos : this.leads.ativos.recebidos, { codigo: idLead });
      if (mailing) {
        this.leads.mailing.recebidos[index] = {
          ...this.leads.mailing.recebidos[index],
          visualizacao: true,
        };
      } else {
        this.leads.ativos.recebidos[index] = {
          ...this.leads.ativos.recebidos[index],
          visualizacao: true,
        };
      }
    },
    modal(e) {
      this.flagAutoUpdate = e;
    },
    setOrdenacao(e) {
      this.ordenacao[e.coluna] = e.item;
    },
    /* eslint-disable */
    ordenar({ recebidos, semSucesso, negociacao, vendidos, naoVendidos }) {
      const ordenacaoArray = [{ recebidos }, { semSucesso }, { negociacao }, { vendidos }, { naoVendidos }];
      for (let i = 0; i < ordenacaoArray.length; i++) {
        const item = ordenacaoArray[i];
        const key = first(Object.keys(item));
        if (item[key] === "Atualizações mais recentes") {
          if (this.tab === 2) this.leads.finalizados[key] = sortBy(this.leads.finalizados[key], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao)).reverse();
          else {
            this.leads.mailing[key] = sortBy(this.leads.mailing[key], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao)).reverse();
            this.leads.ativos[key] = sortBy(this.leads.ativos[key], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao)).reverse();
          }
        } else if (item[key] === "Atualizações menos recentes") {
          if (this.tab === 2) this.leads.finalizados[key] = sortBy(this.leads.finalizados[key], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao));
          else {
            this.leads.mailing[key] = sortBy(this.leads.mailing[key], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao));
            this.leads.ativos[key] = sortBy(this.leads.ativos[key], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao));
          }
        } else if (item[key] === "Data de recebimento mais recente") {
          if (this.tab === 2) this.leads.finalizados[key] = sortBy(this.leads.finalizados[key], (e) => new Date(e.dataHoraCriacao)).reverse();
          else {
            this.leads.mailing[key] = sortBy(this.leads.mailing[key], (e) => new Date(e.dataHoraCriacao)).reverse();
            this.leads.ativos[key] = sortBy(this.leads.ativos[key], (e) => new Date(e.dataHoraCriacao)).reverse();
          }
        } else if (item[key] === "Data de recebimento menos recente") {
          if (this.tab === 2) this.leads.finalizados[key] = sortBy(this.leads.finalizados[key], (e) => new Date(e.dataHoraCriacao));
          else {
            this.leads.mailing[key] = sortBy(this.leads.mailing[key], (e) => new Date(e.dataHoraCriacao));
            this.leads.ativos[key] = sortBy(this.leads.ativos[key], (e) => new Date(e.dataHoraCriacao));
          }
        } else if (item[key] === "Menor SLA" && this.tab === 0) {
          this.leads.ativos[key] = sortBy(this.leads.ativos[key], function(e) {
            return parseInt(e?.prazoSLA?.horasValidas || 0) * 60 + parseInt(e?.prazoSLA?.minutosValidos || 0);
          });
        } else if (item[key] === "Maior SLA" && this.tab === 0) {
          this.leads.ativos[key] = sortBy(this.leads.ativos[key], function(e) {
            return parseInt(e?.prazoSLA?.horasValidas || 0) * 60 + parseInt(e?.prazoSLA?.minutosValidos || 0);
          }).reverse();
        }
      }
    },
    /* eslint-enable */
    clearSort() {
      this.ordenacao = {
        recebidos: null,
        semSucesso: null,
        negociacao: null,
        vendidos: null,
        naoVendidos: null,
      };
    },
    possuiPlantaoCorretor() {
      this.possuiPlantao = false;
      if (this.plantaoLeads && this.plantaoLeads.length) {
        this.possuiPlantao = !!(this.plantaoLeads.filter((item) => item.status === "Em andamento" && !item.pausado) || []).length;
      }
    },
    /* eslint-disable */
    simpleKey(key, col) {
      const tipo = key.includes("SLA") ? "SLA" : key.includes("Data") ? "Data" : "Atualizações";
      return `${tipo} (${col})`;
    },
    icon(key) {
      return key.includes("Maior") || key.includes("mais") ? "mdi-arrow-up-thin" : "mdi-arrow-down-thin";
    },
    /* eslint-enable */
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
      plantaoLeads: (state) => state.lead.plantaoLeads,
    }),
    countFilters() {
      return Object.values(this.filters).filter((item) => item === true).length;
    },
    /* eslint-disable */
    boolFilters() {
      const formattedKeys = [];
      const keys = Object.keys(this.filters).filter((key) => this.filters[key] === true);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        let newKey = null;
        if (key === "leadsSemAgendamento") newKey = "Leads sem agendamento";
        else if (key === "dataRecebimento") newKey = "Data de recebimento";
        else if (key === "dataAtualizacao") newKey = "Data de atualização";
        else if (key === "dataFinalizacao") newKey = "Data de finalização";
        else if (key === "cotacaoEnviada") newKey = "Cotação enviada";
        else if (key === "propostasEnviadas") newKey = "Propostas enviadas";
        else if (key === "negociacaoHotline") newKey = "Negociação iniciada no Hotline";
        else if (key === "negociacaoQualiatende") newKey = "Negociação iniciada no Qualiatende";
        formattedKeys.push({ key, newKey });
      }

      const { recebidos, semSucesso, negociacao, vendidos, naoVendidos } = this.ordenacao;
      if (recebidos) {
        formattedKeys.push({ key: "ordenacaoRecebidos", newKey: `Ord.: ${recebidos} (Recebidos)`, simpleKey: this.simpleKey(recebidos, "Recebidos"), icon: this.icon(recebidos) });
      }
      if (semSucesso) {
        formattedKeys.push({ key: "ordenacaoSemSucesso", newKey: `Ord.: ${semSucesso} (Sem sucesso)`, simpleKey: this.simpleKey(semSucesso, "Sem sucesso"), icon: this.icon(semSucesso) });
      }
      if (negociacao) {
        formattedKeys.push({ key: "ordenacaoNegociacao", newKey: `Ord.: ${negociacao} (Em negociação)`, simpleKey: this.simpleKey(negociacao, "Em negociação"), icon: this.icon(negociacao) });
      }
      if (vendidos) {
        formattedKeys.push({ key: "ordenacaoVendidos", newKey: `Ord.: ${vendidos} (Vendidos)`, simpleKey: this.simpleKey(vendidos, "Vendidos"), icon: this.icon(vendidos) });
      }
      if (naoVendidos) {
        formattedKeys.push({ key: "ordenacaoNaoVendidos", newKey: `Ord.: ${naoVendidos} (Não vendidos)`, simpleKey: this.simpleKey(naoVendidos, "Não vendidos"), icon: this.icon(naoVendidos) });
      }
      return formattedKeys;
    },
    filteredLeads() {
      const pesquisa = (this.pesquisa || "").toLowerCase();
      if (pesquisa) {
        const keys = Object.keys(this.leads);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const subKeys = Object.keys(this.leads[key]);
          for (let ii = 0; ii < subKeys.length; ii++) {
            const subKey = subKeys[ii];
            const filtro = filter(this.leads[key][subKey], function(o) {
              return Object.keys(o).some(function(k) {
                return o[k]
                  ? o[k]
                      .toString()
                      .toLowerCase()
                      .indexOf(pesquisa) != -1
                  : false;
              });
            });
            this.defaultObjectLeads[key][subKey] = filtro;
          }
        }
        return this.defaultObjectLeads;
      }
      return this.leads;
    },
    /* eslint-enable */
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

@media (max-width: $media-md) {
  .container {
    width: 100vw;
  }
}

.div-default {
  margin: 0 2rem 0 2rem;
  @media (max-width: $media-sm) {
    margin: 0;
  }
}

.header {
  color: $azul-qualicorp-hex;
  font-size: 1.5rem;
  font-weight: bold;
}

.btn-filter {
  text-transform: capitalize;
  cursor: default;
}

.btn-x {
  cursor: pointer;
}

.loading {
  z-index: 999;
  position: fixed;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  width: 100%;
}

.width-100 {
  width: 100%;
}
</style>
