<template>
  <div>
    <v-row class="d-none d-lg-flex">
      <v-col cols="12" lg="4">
        <div class="column pa-5">
          <v-row>
            <v-col cols="10">
              <h3 class="header">
                Leads recebidos
              </h3>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item>
                    <span class="ordenacao">
                      <strong>Ordenar por:</strong>
                    </span>
                  </v-list-item>
                  <v-list-item link v-for="(item, key) in ordenacao" :key="'A' + key" @click="ordenar(item.title, 'recebidos')">
                    <v-list-item-title class="ordenacao-item">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col class="text-right">
              <span class="count-leads">
                {{ countLeads.recebidos > 1 ? countLeads.recebidos + " leads" : countLeads.recebidos + " lead" }}
              </span>
            </v-col>
          </v-row>
          <draggable id="recebidos" class="list-group overflow" :list="leads.recebidos" group="leads" @change="onChangeCardLead($event, 'recebidos')" :move="onMoveCallback">
            <v-card v-for="(item, key) in leadsRecebidos" :key="'B' + key" class="card click mx-auto mb-5 mr-2" :loading="item.loading" outlined @click="showDetails(item, key, 'recebidos')">
              <v-list-item three-line>
                <v-list-item-avatar
                  v-if="tipoTab == 'ativos'"
                  tile
                  size="8"
                  :class="{
                    term: true,
                    'vinte-cinco': item.tipoLead == '25%',
                    cinquenta: item.tipoLead == '50%',
                    'setenta-cinco': item.tipoLead == '75%',
                    cem: item.tipoLead == '100%',
                  }"
                />
                <v-list-item-content>
                  <v-row>
                    <v-col cols="9">
                      <span class="title-custom">{{ item.nome }}</span>
                      <p v-if="tipoTab == 'ativos'" class="mb-0 custom">Expira em: {{ item.expiracao }}</p>
                    </v-col>
                    <v-col cols="3" v-if="tipoTab == 'ativos' || item.visualizacao" class="text-right">
                      <div class="tooltip-clock" v-if="tipoTab == 'ativos' && item.flagExpirando">
                        <v-icon color="#FFB61E">
                          mdi-clock-time-four
                        </v-icon>
                        <span class="tooltip-clock-text">
                          Cliente está quase expirando!
                        </span>
                      </div>
                      <div class="tooltip-fire" v-if="tipoTab == 'ativos' && item.possuiAlerta">
                        <v-icon color="red">mdi-fire</v-icon>
                        <span class="tooltip-fire-text">
                          Ligue agora! O cliente fez uma nova simulação!
                        </span>
                      </div>
                      <div class="tooltip-eye" v-if="item.visualizacao">
                        <v-icon color="primary">mdi-eye</v-icon>
                        <span class="tooltip-eye-text">
                          Lead já visualizado!
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-4" />
                  <p class="custom"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                  <p class="custom"><strong>E-mail:</strong> {{ item.emailList.length ? item.emailList[0].email : " -- " }}</p>
                  <v-divider class="mt-2 mb-4" />
                  <p class="mb-0 custom">Entrada: {{ formataData(item.dataHoraCriacao) }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </draggable>
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="column pa-5">
          <v-row>
            <v-col cols="10">
              <h3 class="header">
                Sem sucesso
              </h3>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item>
                    <span class="ordenacao">
                      <strong>Ordenar por:</strong>
                    </span>
                  </v-list-item>
                  <v-list-item link v-for="(item, key) in ordenacao" :key="'C' + key" @click="ordenar(item.title, 'semSucesso')">
                    <v-list-item-title class="ordenacao-item">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col class="text-right">
              <span class="count-leads">
                {{ countLeads.semSucesso > 1 ? countLeads.semSucesso + " leads" : countLeads.semSucesso + " lead" }}
              </span>
            </v-col>
          </v-row>
          <draggable id="semSucesso" class="list-group overflow" :list="leads.semSucesso" group="leads" @change="onChangeCardLead($event, 'sem-sucesso')" :move="onMoveCallback">
            <v-card v-for="(item, key) in leadsSemSucesso" :key="'D' + key" class="card click mx-auto mb-5 mr-2" :loading="item.loading" outlined @click="showDetails(item, key, 'sem-sucesso')">
              <v-list-item three-line>
                <v-list-item-avatar
                  v-if="tipoTab == 'ativos'"
                  tile
                  size="8"
                  :class="{
                    term: true,
                    'vinte-cinco': item.tipoLead == '25%',
                    cinquenta: item.tipoLead == '50%',
                    'setenta-cinco': item.tipoLead == '75%',
                    cem: item.tipoLead == '100%',
                  }"
                />
                <v-list-item-content>
                  <v-row>
                    <v-col cols="9">
                      <span class="title-custom">{{ item.nome }}</span>
                      <p v-if="tipoTab == 'ativos'" class="mb-0 custom">Expira em: {{ item.expiracao }}</p>
                    </v-col>
                    <v-col cols="3" v-if="tipoTab == 'ativos'" class="text-right">
                      <div class="tooltip-clock" v-if="item.flagExpirando">
                        <v-icon color="#FFB61E">
                          mdi-clock-time-four
                        </v-icon>
                        <span class="tooltip-clock-text">
                          Cliente está quase expirando!
                        </span>
                      </div>
                      <div class="tooltip-fire" v-if="item.possuiAlerta">
                        <v-icon color="red">mdi-fire</v-icon>
                        <span class="tooltip-fire-text">
                          Ligue agora! O cliente fez uma nova simulação!
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-4" />
                  <p class="custom"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                  <p class="custom"><strong>E-mail:</strong> {{ item.emailList.length ? item.emailList[0].email : " -- " }}</p>
                  <v-divider class="mt-2 mb-4" />
                  <p class="mb-0 custom">Entrada: {{ formataData(item.dataHoraCriacao) }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </draggable>
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="column pa-5">
          <v-row>
            <v-col cols="10">
              <h3 class="header">
                Em negociação
              </h3>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item>
                    <span class="ordenacao">
                      <strong>Ordenar por:</strong>
                    </span>
                  </v-list-item>
                  <v-list-item link v-for="(item, key) in ordenacao" :key="'E' + key" @click="ordenar(item.title, 'negociacao')">
                    <v-list-item-title class="ordenacao-item">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col class="text-right">
              <span class="count-leads">
                {{ countLeads.negociacao > 1 ? countLeads.negociacao + " leads" : countLeads.negociacao + " lead" }}
              </span>
            </v-col>
          </v-row>
          <draggable id="negociacao" class="list-group overflow" :list="leads.negociacao" group="leads" @change="onChangeCardLead($event, 'negociacao')" :move="onMoveCallback">
            <v-card v-for="(item, key) in leadsNegociacao" :key="'F' + key" class="card click mx-auto mb-5 mr-2" :loading="item.loading" outlined @click="showDetails(item, key, 'negociacao')">
              <v-list-item three-line>
                <v-list-item-avatar
                  v-if="tipoTab == 'ativos'"
                  tile
                  size="8"
                  :class="{
                    term: true,
                    'vinte-cinco': item.tipoLead == '25%',
                    cinquenta: item.tipoLead == '50%',
                    'setenta-cinco': item.tipoLead == '75%',
                    cem: item.tipoLead == '100%',
                  }"
                />
                <v-list-item-content>
                  <v-row>
                    <v-col cols="9">
                      <span class="title-custom">{{ item.nome }}</span>
                      <p v-if="tipoTab == 'ativos'" class="mb-0 custom">Expira em: {{ item.expiracao }}</p>
                    </v-col>
                    <v-col cols="3" v-if="tipoTab == 'ativos'" class="text-right">
                      <div class="tooltip-clock" v-if="item.flagExpirando">
                        <v-icon color="#FFB61E">
                          mdi-clock-time-four
                        </v-icon>
                        <span class="tooltip-clock-text">
                          Cliente está quase expirando!
                        </span>
                      </div>
                      <div class="tooltip-fire" v-if="item.possuiAlerta">
                        <v-icon color="red">mdi-fire</v-icon>
                        <span class="tooltip-fire-text">
                          Ligue agora! O cliente fez uma nova simulação!
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-4" />
                  <p class="custom"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                  <p class="custom"><strong>E-mail:</strong> {{ item.emailList.length ? item.emailList[0].email : " -- " }}</p>
                  <v-divider class="mt-2 mb-4" />
                  <p class="mb-0 custom">Entrada: {{ formataData(item.dataHoraCriacao) }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </draggable>
        </div>
      </v-col>
    </v-row>
    <!-- Estrutura Mobile -->
    <v-container fluid class="container px-0 d-flex d-lg-none">
      <div class="column pa-5 mr-5">
        <v-row>
          <v-col cols="10">
            <h3 class="header">
              Leads recebidos
            </h3>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <span class="ordenacao">
                    <strong>Ordenar por:</strong>
                  </span>
                </v-list-item>
                <v-list-item link v-for="(item, key) in ordenacao" :key="'G' + key" @click="ordenar(item.title, 'recebidos')">
                  <v-list-item-title class="ordenacao-item">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col class="text-right">
            <span class="count-leads">
              {{ countLeads.recebidos > 1 ? countLeads.recebidos + " leads" : countLeads.recebidos + " lead" }}
            </span>
          </v-col>
        </v-row>
        <div id="recebidos-mobile" class="overflow">
          <v-card v-for="(item, key) in leadsRecebidos" :key="'H' + key" class="card click mx-auto mb-5 mr-2 d-flex" :loading="item.loading" outlined @click="showDetails(item, key, 'recebidos')">
            <v-list-item three-line>
              <v-list-item-avatar
                v-if="tipoTab == 'ativos'"
                tile
                size="8"
                :class="{
                  term: true,
                  'vinte-cinco': item.tipoLead == '25%',
                  cinquenta: item.tipoLead == '50%',
                  'setenta-cinco': item.tipoLead == '75%',
                  cem: item.tipoLead == '100%',
                }"
              />
              <v-list-item-content>
                <v-row>
                  <v-col cols="9">
                    <span class="title-custom">{{ item.nome }}</span>
                    <p v-if="tipoTab == 'ativos'" class="mb-0 custom">Expira em: {{ item.expiracao }}</p>
                  </v-col>
                  <v-col cols="3" v-if="tipoTab == 'ativos' || item.visualizacao" class="text-right">
                    <div class="tooltip-clock" v-if="tipoTab == 'ativos' && item.flagExpirando">
                      <v-icon color="#FFB61E">
                        mdi-clock-time-four
                      </v-icon>
                      <span class="tooltip-clock-text">
                        Cliente está quase expirando!
                      </span>
                    </div>
                    <div class="tooltip-fire" v-if="tipoTab == 'ativos' && item.possuiAlerta">
                      <v-icon color="red">mdi-fire</v-icon>
                      <span class="tooltip-fire-text">
                        Ligue agora! O cliente fez uma nova simulação!
                      </span>
                    </div>
                    <div class="tooltip-eye" v-if="item.visualizacao">
                      <v-icon color="primary">mdi-eye</v-icon>
                      <span class="tooltip-eye-text">
                        Lead já visualizado!
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mb-4" />
                <p class="custom"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                <p v-if="item.emailList.length" class="custom">
                  <strong>E-mail:</strong> {{ item.emailList[0].email.length > 25 ? item.emailList[0].email.substr(0, 25) + "..." : item.emailList[0].email }}
                </p>
                <p v-else class="custom"><strong>E-mail:</strong> {{ " -- " }}</p>
                <v-divider class="mt-2 mb-4" />
                <p class="mb-0 custom">Entrada: {{ formataData(item.dataHoraCriacao) }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
      <div class="column pa-5 mr-5">
        <v-row>
          <v-col cols="10">
            <h3 class="header">
              Sem sucesso
            </h3>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <span class="ordenacao">
                    <strong>Ordenar por:</strong>
                  </span>
                </v-list-item>
                <v-list-item link v-for="(item, key) in ordenacao" :key="'I' + key" @click="ordenar(item.title, 'semSucesso')">
                  <v-list-item-title class="ordenacao-item">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col class="text-right">
            <span class="count-leads">
              {{ countLeads.semSucesso > 1 ? countLeads.semSucesso + " leads" : countLeads.semSucesso + " lead" }}
            </span>
          </v-col>
        </v-row>
        <div id="semSucesso-mobile" class="overflow">
          <v-card v-for="(item, key) in leadsSemSucesso" :key="'J' + key" class="card click mx-auto mb-5 mr-2 d-flex" :loading="item.loading" outlined @click="showDetails(item, key, 'sem-sucesso')">
            <v-list-item three-line>
              <v-list-item-avatar
                v-if="tipoTab == 'ativos'"
                tile
                size="8"
                :class="{
                  term: true,
                  'vinte-cinco': item.tipoLead == '25%',
                  cinquenta: item.tipoLead == '50%',
                  'setenta-cinco': item.tipoLead == '75%',
                  cem: item.tipoLead == '100%',
                }"
              />
              <v-list-item-content>
                <v-row>
                  <v-col cols="9">
                    <span class="title-custom">{{ item.nome }}</span>
                    <p v-if="tipoTab == 'ativos'" class="mb-0 custom">Expira em: {{ item.expiracao }}</p>
                  </v-col>
                  <v-col cols="3" v-if="tipoTab == 'ativos'" class="text-right">
                    <div class="tooltip-clock" v-if="item.flagExpirando">
                      <v-icon color="#FFB61E">
                        mdi-clock-time-four
                      </v-icon>
                      <span class="tooltip-clock-text">
                        Cliente está quase expirando!
                      </span>
                    </div>
                    <div class="tooltip-fire" v-if="item.possuiAlerta">
                      <v-icon color="red">mdi-fire</v-icon>
                      <span class="tooltip-fire-text">
                        Ligue agora! O cliente fez uma nova simulação!
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mb-4" />
                <p class="custom"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                <p v-if="item.emailList.length" class="custom">
                  <strong>E-mail:</strong> {{ item.emailList[0].email.length > 25 ? item.emailList[0].email.substr(0, 25) + "..." : item.emailList[0].email }}
                </p>
                <p v-else class="custom"><strong>E-mail:</strong> {{ " -- " }}</p>
                <v-divider class="mt-2 mb-4" />
                <p class="mb-0 custom">Entrada: {{ formataData(item.dataHoraCriacao) }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
      <div class="column pa-5">
        <v-row>
          <v-col cols="10">
            <h3 class="header">
              Em negociação
            </h3>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <span class="ordenacao">
                    <strong>Ordenar por:</strong>
                  </span>
                </v-list-item>
                <v-list-item link v-for="(item, key) in ordenacao" :key="'K' + key" @click="ordenar(item.title, 'negociacao')">
                  <v-list-item-title class="ordenacao-item">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col class="text-right">
            <span class="count-leads">
              {{ countLeads.negociacao > 1 ? countLeads.negociacao + " leads" : countLeads.negociacao + " lead" }}
            </span>
          </v-col>
        </v-row>
        <div id="negociacao-mobile" class="overflow">
          <v-card v-for="(item, key) in leadsNegociacao" :key="'L' + key" class="card click mx-auto mb-5 mr-2 d-flex" :loading="item.loading" outlined @click="showDetails(item, key, 'negociacao')">
            <v-list-item three-line>
              <v-list-item-avatar
                v-if="tipoTab == 'ativos'"
                tile
                size="8"
                :class="{
                  term: true,
                  'vinte-cinco': item.tipoLead == '25%',
                  cinquenta: item.tipoLead == '50%',
                  'setenta-cinco': item.tipoLead == '75%',
                  cem: item.tipoLead == '100%',
                }"
              />
              <v-list-item-content>
                <v-row>
                  <v-col cols="9">
                    <span class="title-custom">{{ item.nome }}</span>
                    <p v-if="tipoTab == 'ativos'" class="mb-0 custom">Expira em: {{ item.expiracao }}</p>
                  </v-col>
                  <v-col cols="3" v-if="tipoTab == 'ativos'" class="text-right">
                    <div class="tooltip-clock" v-if="item.flagExpirando">
                      <v-icon color="#FFB61E">
                        mdi-clock-time-four
                      </v-icon>
                      <span class="tooltip-clock-text">
                        Cliente está quase expirando!
                      </span>
                    </div>
                    <div class="tooltip-fire" v-if="item.possuiAlerta">
                      <v-icon color="red">mdi-fire</v-icon>
                      <span class="tooltip-fire-text">
                        Ligue agora! O cliente fez uma nova simulação!
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mb-4" />
                <p class="custom"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                <p v-if="item.emailList.length" class="custom">
                  <strong>E-mail:</strong> {{ item.emailList[0].email.length > 25 ? item.emailList[0].email.substr(0, 25) + "..." : item.emailList[0].email }}
                </p>
                <p v-else class="custom"><strong>E-mail:</strong> {{ " -- " }}</p>
                <v-divider class="mt-2 mb-4" />
                <p class="mb-0 custom">Entrada: {{ formataData(item.dataHoraCriacao) }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="showModal" max-width="1000" @click:outside="close" @keydown.esc="close">
      <v-card v-if="!showFinishedLeadModal" class="card pa-3">
        <v-row>
          <v-col class="pa-5">
            <div v-if="form.opcaoMoverLead == 'Sem sucesso' || form.opcaoMoverLead == 'Em negociação'">
              <v-card-title class="title">
                <strong>Qual a interação você teve com o cliente?</strong>
              </v-card-title>
              <v-list v-show="form.opcaoMoverLead == 'Sem sucesso'" class="transparent mx-n4">
                <v-expansion-panels accordion flat multiple :value="checkTratamentoSel(form.tratamentosSemSucesso)">
                  <v-expansion-panel v-for="(tratamento, key) in form.tratamentosSemSucesso" :key="key">
                    <v-expansion-panel-header>
                      {{ key }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-for="(item, key) in tratamento" :key="key" accordion flat :value="0">
                        <v-expansion-panel v-if="item.Descricao">
                          <v-radio-group v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                            <v-radio :value="item.Descricao" :label="item.Descricao" color="primary" />
                          </v-radio-group>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="!item.Descricao">
                          <v-expansion-panel-header>
                            {{ key }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-radio-group v-for="(subitem, key) in item" :key="key" v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                              <v-radio :value="subitem.Descricao" :label="subitem.Descricao" color="primary" />
                            </v-radio-group>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list>
              <v-list v-show="form.opcaoMoverLead == 'Em negociação'" class="transparent mx-n4">
                <v-expansion-panels accordion flat multiple :value="checkTratamentoSel(form.tratamentosNegociacao)">
                  <v-expansion-panel v-for="(tratamento, key) in form.tratamentosNegociacao" :key="key">
                    <v-expansion-panel-header>
                      {{ key }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-for="(item, key) in tratamento" :key="key" accordion flat :value="0">
                        <v-expansion-panel v-if="item.Descricao">
                          <v-radio-group v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                            <v-radio :value="item.Descricao" :label="item.Descricao" color="primary" />
                          </v-radio-group>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="!item.Descricao">
                          <v-expansion-panel-header>
                            {{ key }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-radio-group v-for="(subitem, key) in item" :key="key" v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                              <v-radio :value="subitem.Descricao" :label="subitem.Descricao" color="primary" />
                            </v-radio-group>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list>
            </div>
            <div v-if="form.permiteAgendamento">
              <v-card-title class="title">
                <strong>Faça um agendamento:</strong>
              </v-card-title>
              <v-card-text class="card-text">
                <p>Selecione uma nova data e hora para entrar em contato com o cliente. Em seguida, clique em SALVAR.</p>
                <p>Esse evento será salvo automaticamente no seu calendário.</p>
              </v-card-text>
              <validation-observer ref="form">
                <validation-provider v-slot="{ errors }" name="agendamento" vid="agendamento" :rules="!form.flagSemAgendamento ? 'required' : null">
                  <v-datetime-picker
                    :datetime="dataHoraAgendamentoFormatado(form.dataHoraAgendamento)"
                    :disabled="form.flagSemAgendamento"
                    label="Agendamento"
                    no-title
                    date-format="dd/MM/yyyy"
                    @ok="ok($event)"
                    :errors="errors"
                  />
                </validation-provider>
              </validation-observer>
              <v-divider />
              <v-checkbox v-model="form.flagSemAgendamento" label="Não quero fazer um agendamento agora" color="primary" />
            </div>
          </v-col>
          <v-divider vertical class="my-5" />
          <v-col class="mt-5 pa-5">
            <v-select :items="opcoesMoverLead" item-text="label" label="Mover lead para:" v-model="form.opcaoMoverLead" @change="form.opcaoInteracao = null" />
            <v-textarea outlined auto-grow label="Observação" v-model="form.observacao" class="mt-3" />
            <v-card-actions :class="form.opcaoMoverLead == 'Sem sucesso' || form.opcaoMoverLead == 'Em negociação' ? 'margin-buttons' : null">
              <v-spacer></v-spacer>
              <v-btn color="#FFB600" class="white--text mr-4" @click="criarProposta(form.idLead)">
                Criar proposta
              </v-btn>
              <v-btn color="primary" outlined class="mr-3" @click="showFinishedLeadModal = true">
                Finalizar o lead
              </v-btn>
              <v-btn color="primary" @click="salvar">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-else class="card pa-3">
        <v-row>
          <v-col cols="12" sm="6" class="pa-5">
            <v-card-title class="title">
              <strong>Conseguiu fechar a venda?</strong>
            </v-card-title>
            <v-radio-group v-model="form.flagVendaFechada" row hide-details class="mt-0 mx-3">
              <v-radio label="Sim" :value="true" />
              <v-radio label="Não" :value="false" />
            </v-radio-group>
            <div v-if="!form.flagVendaFechada">
              <v-card-title class="title mt-3">
                <strong>Qual o motivo?</strong>
              </v-card-title>
              <v-list class="transparent mx-n4">
                <v-expansion-panels accordion flat>
                  <v-expansion-panel v-for="(motivo, key) in form.motivos" :key="key">
                    <v-expansion-panel-header>
                      {{ key }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-for="(item, key) in motivo" :key="key" accordion flat>
                        <v-expansion-panel v-if="item.Descricao">
                          <v-radio-group v-model="form.motivoNaoVenda" hide-details class="ma-0 pa-0">
                            <v-radio :value="item.Descricao" :label="item.Descricao" color="primary" />
                          </v-radio-group>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="!item.Descricao">
                          <v-expansion-panel-header>
                            {{ key }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-radio-group v-for="(subitem, key) in item" :key="key" v-model="form.motivoNaoVenda" hide-details class="ma-0 pa-0">
                              <v-radio :value="subitem.Descricao" :label="subitem.Descricao" color="primary" />
                            </v-radio-group>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list>
            </div>
            <div v-else>
              <v-card-title class="title mt-3">
                <strong>Número da proposta</strong>
              </v-card-title>
              <v-card-text class="card-text">
                <p class="mb-0">Insira o número da proposta fechada para vincularmos as informações do cliente com a sua venda:</p>
              </v-card-text>
              <v-radio-group v-model="form.flagPesquisarProposta" row hide-details class="mt-0 mb-5 mx-3" @change="form.nrProposta = null">
                <v-radio label="Pesquisar proposta" :value="true" />
                <v-radio label="Inserir manualmente" :value="false" />
              </v-radio-group>
              <validation-observer ref="form">
                <validation-provider v-if="form.flagPesquisarProposta" v-slot="{ errors }" name="proposta" vid="proposta" rules="required">
                  <base-autocomplete-field
                    :items="propostaList"
                    rules="required"
                    id="proposta"
                    name="proposta"
                    v-model="form.nrProposta"
                    :item-text="'nrProposta'"
                    :sub-text="'titular'"
                    item-value="nrProposta"
                    outlined
                    :errors="errors"
                  />
                </validation-provider>
                <validation-provider v-else v-slot="{ errors }" name="proposta" vid="proposta" rules="required">
                  <v-text-field name="proposta" v-model="form.nrProposta" type="number" label="Proposta" :error-messages="errors" required></v-text-field>
                </validation-provider>
              </validation-observer>
            </div>
          </v-col>
          <v-divider vertical class="my-5" />
          <v-col cols="12" sm="6" class="text-right pa-5">
            <v-textarea outlined auto-grow label="Observação" v-model="form.observacao" class="my-3" hide-details />
            <v-card-title>
              <strong>Avaliação do Lead</strong>
            </v-card-title>
            <v-row>
              <v-col cols="12">
                <validation-observer ref="avaliacao" class="mb-10" @submit.prevent="submit" autocomplete="off">
                  <v-rating class="text-center" color="blue" hover length="5" :size="$vuetify.breakpoint.smAndDown ? 32 : 64" v-model="form.notaAvaliacao" @input="getAvaliacaoMotivos" />
                  <validation-provider ref="textFieldProvider" slim v-slot="{ errors }" rules="required">
                    <input type="hidden" v-model="form.notaAvaliacao" name="nota" />
                    <div class="v-messages theme--light error--text text-center">{{ errors[0] }}</div>
                  </validation-provider>
                  <validation-provider v-if="form.motivosAvaliacao && form.motivosAvaliacao.length" v-slot="{ errors }" name="tratamento" vid="tratamento" rules="required">
                    <v-radio-group v-model="form.descricaoAvaliacao" :error-messages="errors" class="px-14 d-flex mt-0">
                      <v-list-item-content style="width: 100%" class="pl-4 align-self-flex-start" v-for="(motivoAvaliacao, index) in form.motivosAvaliacao" v-bind:key="index">
                        <v-radio :value="motivoAvaliacao.descricao" :label="motivoAvaliacao.descricao" @change="changeMotivo(index)" />
                      </v-list-item-content>
                    </v-radio-group>
                  </validation-provider>
                  <base-text-field
                    v-if="showJustificativa"
                    mode="aggressive"
                    rules="required"
                    id="justificativa"
                    name="Justificativa"
                    v-model="form.justificativaAvaliacao"
                    label="Justificativa *"
                    clearable
                    outlined
                  />
                </validation-observer>
              </v-col>
            </v-row>
            <div :class="$vuetify.breakpoint.smAndUp ? 'margin-buttons-finalizacao' : null">
              <v-btn color="primary" outlined class="mr-3" @click="showFinishedLeadModal = false">
                Voltar
              </v-btn>
              <v-btn color="primary" @click="finalizarLead()">
                Finalizar o lead
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDetailModal" max-width="1050" @click:outside="close" @keydown.esc="close">
      <v-card v-if="!showFinishedLeadModal" class="card pa-3">
        <v-list-item-content>
          <v-row>
            <v-col v-if="tipoTab == 'ativos'" cols="1" class="text-center">
              <v-list-item-avatar
                tile
                size="15"
                :class="{
                  'term modal': true,
                  'vinte-cinco': form.tipoLead == '25%',
                  cinquenta: form.tipoLead == '50%',
                  'setenta-cinco': form.tipoLead == '75%',
                  cem: form.tipoLead == '100%',
                }"
              />
              <span v-if="$vuetify.breakpoint.mdAndUp" class="tooltip-term-text">
                {{ form.tipoLead == "0%" || form.tipoLead == "25%" ? "Lead Frio" : "Lead Quente" }}
              </span>
            </v-col>
            <v-col :class="tipoTab == 'ativos' ? 'pl-0' : 'pl-7'">
              <v-row no-gutters>
                <v-col cols="10">
                  <v-card-title :class="{ 'title-modal pa-0': true, 'ml-3': tipoTab == 'ativos' && $vuetify.breakpoint.xs }">
                    <strong>{{ form.nome }}</strong>
                  </v-card-title>
                </v-col>
                <v-col v-if="tipoTab == 'ativos' && form.possuiAlerta" align-self="center" class="text-right">
                  <div class="tooltip-fire">
                    <v-icon color="red">mdi-fire</v-icon>
                    <span class="tooltip-fire-text">
                      Ligue agora! O cliente fez uma nova simulação!
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-card-subtitle :class="{ 'subtitle-modal pt-2 pl-0': true, 'ml-3': tipoTab == 'ativos' && $vuetify.breakpoint.xs }">
                  <strong>Segmentação: {{ form.segmentacao }}</strong>
                </v-card-subtitle>
              </v-row>
              <div :class="{ 'mt-3': true, 'ml-3': tipoTab == 'ativos' && $vuetify.breakpoint.xs }">
                <p v-for="(item, key) in form.telefoneList" :key="'M' + key">
                  <strong>
                    Telefone:
                  </strong>
                  <v-icon v-if="item.tipo === 'Celular'" small color="green darken-2">mdi-whatsapp</v-icon>
                  <v-icon v-else small color="grey darken-2">mdi-phone</v-icon>
                  <a :href="linkTelefone(item.tipo) + (item.ddd + item.numero).replace(/[^\d]+/g, '')" class="pl-0 text-decoration-none black--text text--lighter-1" target="_blank">
                    ({{ item.ddd }}) {{ item.numero }}
                  </a>
                </p>
                <p v-for="(item, key) in form.emailList" :key="'N' + key">
                  <strong>
                    E-mail:
                  </strong>
                  <v-icon small color="grey darken-2">mdi-email</v-icon>
                  <a :href="`mailto:${item.email}`" class="pl-1 text-caption text-decoration-none black--text text--lighter-1">{{ item.email }}</a>
                </p>
                <p v-if="form.local"><strong>Cidade/UF:</strong> {{ form.local }}</p>
                <p v-if="form.dataNascimento"><strong>Data de nascimento:</strong> {{ form.dataNascimento }}</p>
                <p v-if="form.profissao"><strong>Profissão:</strong> {{ form.profissao }}</p>
                <p v-if="form.operadora"><strong>Operadora:</strong> {{ form.operadora }}</p>
                <p v-if="form.origem"><strong>Origem:</strong> {{ form.origem }}</p>
                <p v-if="form.plano"><strong>Plano:</strong> {{ form.plano }}</p>
                <p v-if="form.qntVidas"><strong>Quantidade de vidas:</strong> {{ form.qntVidas }}</p>
                <p v-if="form.nomeEmpresa"><strong>Nome da empresa:</strong> {{ form.nomeEmpresa }}</p>
                <p v-if="form.cnpjEmpresa"><strong>CNPJ da empresa:</strong> {{ form.cnpjEmpresa }}</p>
              </div>
              <p v-if="tipoTab == 'ativos' && $vuetify.breakpoint.smAndDown" :class="{ 'margin-tipo-lead': true, 'ml-3': $vuetify.breakpoint.xs }">
                {{ form.tipoLead == "0%" || form.tipoLead == "25%" ? "Lead Frio" : "Lead Quente" }}
              </p>
              <v-row :class="{ 'ml-0': tipoTab == 'ativos' && $vuetify.breakpoint.xs }">
                <v-col>
                  <v-expansion-panels accordion flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pl-0">
                        <strong>Visualizar histórico</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels accordion flat>
                          <v-expansion-panel v-for="(item, i) in form.historico" :key="i">
                            <v-expansion-panel-header>
                              {{ formataData(item[0].dataHoraCriacao) }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p v-for="(subitem, ii) in item" :key="ii" class="mb-2">
                                {{ subitem.descricao }}
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical class="my-5" />
            <v-col class="mt-5 pa-5">
              <v-select :items="opcoesMoverLead" item-text="label" label="Mover lead para:" v-model="form.opcaoMoverLead" @change="form.opcaoInteracao = null" />
              <div v-if="form.opcaoMoverLead == 'Sem sucesso' || form.opcaoMoverLead == 'Em negociação'">
                <v-card-title class="title-modal pa-0 mb-3">
                  <strong>Qual a interação você teve com o cliente?</strong>
                </v-card-title>
                <v-list v-show="form.opcaoMoverLead == 'Sem sucesso'" class="transparent mx-n4">
                  <v-expansion-panels accordion flat multiple :value="checkTratamentoSel(form.tratamentosSemSucesso)">
                    <v-expansion-panel v-for="(tratamento, key) in form.tratamentosSemSucesso" :key="key">
                      <v-expansion-panel-header>
                        {{ key }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels v-for="(item, key) in tratamento" :key="key" accordion flat :value="0">
                          <v-expansion-panel v-if="item.Descricao">
                            <v-radio-group v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                              <v-radio :value="item.Descricao" :label="item.Descricao" color="primary" />
                            </v-radio-group>
                          </v-expansion-panel>
                          <v-expansion-panel v-if="!item.Descricao">
                            <v-expansion-panel-header>
                              {{ key }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-radio-group v-for="(subitem, key) in item" :key="key" v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                                <v-radio :value="subitem.Descricao" :label="subitem.Descricao" color="primary" />
                              </v-radio-group>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-list>
                <v-list v-show="form.opcaoMoverLead == 'Em negociação'" class="transparent mx-n4">
                  <v-expansion-panels accordion flat multiple :value="checkTratamentoSel(form.tratamentosNegociacao)">
                    <v-expansion-panel v-for="(tratamento, key) in form.tratamentosNegociacao" :key="key">
                      <v-expansion-panel-header>
                        {{ key }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels v-for="(item, key) in tratamento" :key="key" accordion flat :value="0">
                          <v-expansion-panel v-if="item.Descricao">
                            <v-radio-group v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                              <v-radio :value="item.Descricao" :label="item.Descricao" color="primary" />
                            </v-radio-group>
                          </v-expansion-panel>
                          <v-expansion-panel v-if="!item.Descricao">
                            <v-expansion-panel-header>
                              {{ key }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-radio-group v-for="(subitem, key) in item" :key="key" v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                                <v-radio :value="subitem.Descricao" :label="subitem.Descricao" color="primary" />
                              </v-radio-group>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-list>
              </div>
              <v-textarea v-if="form.opcaoMoverLead == 'Sem sucesso' || form.opcaoMoverLead == 'Em negociação'" outlined auto-grow rows="3" label="Observação" v-model="form.observacao" class="mt-3" />
              <div v-if="form.permiteAgendamento">
                <v-card-title class="title-modal pa-0">
                  <strong>Agendamentos</strong>
                </v-card-title>
                <p class="my-3">
                  Esse evento será salvo automaticamente no seu calendário.
                </p>
                <validation-observer ref="form">
                  <validation-provider v-slot="{ errors }" name="agendamento" vid="agendamento" :rules="!form.flagSemAgendamento ? 'required' : null">
                    <v-datetime-picker
                      :datetime="dataHoraAgendamentoFormatado(form.dataHoraAgendamento)"
                      :disabled="form.flagSemAgendamento"
                      label="Agendamento"
                      no-title
                      date-format="dd/MM/yyyy"
                      @ok="ok($event)"
                      :errors="errors"
                    />
                  </validation-provider>
                </validation-observer>
                <v-checkbox v-model="form.flagSemAgendamento" label="Não quero fazer um agendamento agora" color="primary" class="mt-0 pt-0" />
              </div>
              <v-divider v-if="form.flagExpirando" class="mb-5" />
              <v-alert v-if="form.flagExpirando" type="warning" :icon="false" color="#FFFD99">
                <v-icon color="black">mdi-alert</v-icon>
                <span class="alert mx-2">Expira em: {{ form.expiracao }}</span>
              </v-alert>
              <v-card-actions v-if="$vuetify.breakpoint.lgAndUp">
                <v-spacer></v-spacer>
                <v-btn color="#FFB600" class="white--text mr-4" @click="criarProposta(form.idLead)">
                  Criar proposta
                </v-btn>
                <v-btn color="primary" outlined class="mr-3" @click="showFinishedLeadModal = true">
                  Finalizar o lead
                </v-btn>
                <v-btn color="primary" @click="salvar">
                  Salvar
                </v-btn>
              </v-card-actions>
              <div v-else>
                <v-btn color="#FFB600" block class="white--text" @click="criarProposta(form.idLead)">
                  Criar proposta
                </v-btn>
                <v-btn color="primary" block outlined class="my-3" @click="showFinishedLeadModal = true">
                  Finalizar o lead
                </v-btn>
                <v-btn color="primary" block class="my-3" @click="salvar">
                  Salvar
                </v-btn>
                <v-btn color="error" block @click="close">
                  Fechar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-card>
      <v-card v-else class="card pa-3">
        <v-row>
          <v-col cols="12" sm="6" class="pa-5">
            <v-card-title class="title">
              <strong>Conseguiu fechar a venda?</strong>
            </v-card-title>
            <v-radio-group v-model="form.flagVendaFechada" row hide-details class="mt-0 mx-3">
              <v-radio label="Sim" :value="true" />
              <v-radio label="Não" :value="false" />
            </v-radio-group>
            <div v-if="!form.flagVendaFechada">
              <v-card-title class="title mt-3">
                <strong>Qual o motivo?</strong>
              </v-card-title>
              <v-list class="transparent mx-n4">
                <v-expansion-panels accordion flat>
                  <v-expansion-panel v-for="(motivo, key) in form.motivos" :key="key">
                    <v-expansion-panel-header>
                      {{ key }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-for="(item, key) in motivo" :key="key" accordion flat>
                        <v-expansion-panel v-if="item.Descricao">
                          <v-radio-group v-model="form.motivoNaoVenda" hide-details class="ma-0 pa-0">
                            <v-radio :value="item.Descricao" :label="item.Descricao" color="primary" />
                          </v-radio-group>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="!item.Descricao">
                          <v-expansion-panel-header>
                            {{ key }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-radio-group v-for="(subitem, key) in item" :key="key" v-model="form.motivoNaoVenda" hide-details class="ma-0 pa-0">
                              <v-radio :value="subitem.Descricao" :label="subitem.Descricao" color="primary" />
                            </v-radio-group>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list>
            </div>
            <div v-else>
              <v-card-title class="title mt-3">
                <strong>Número da proposta</strong>
              </v-card-title>
              <v-card-text class="card-text">
                <p class="mb-0">Insira o número da proposta fechada para vincularmos as informações do cliente com a sua venda:</p>
              </v-card-text>
              <v-radio-group v-model="form.flagPesquisarProposta" row hide-details class="mt-0 mb-5 mx-3" @change="form.nrProposta = null">
                <v-radio label="Pesquisar proposta" :value="true" />
                <v-radio label="Inserir manualmente" :value="false" />
              </v-radio-group>
              <validation-observer ref="form">
                <validation-provider v-if="form.flagPesquisarProposta" v-slot="{ errors }" name="proposta" vid="proposta" rules="required">
                  <base-autocomplete-field
                    :items="propostaList"
                    rules="required"
                    id="proposta"
                    name="proposta"
                    v-model="form.nrProposta"
                    :item-text="'nrProposta'"
                    :sub-text="'titular'"
                    item-value="nrProposta"
                    outlined
                    :errors="errors"
                  />
                </validation-provider>
                <validation-provider v-else v-slot="{ errors }" name="proposta" vid="proposta" rules="required">
                  <v-text-field name="proposta" v-model="form.nrProposta" type="number" label="Proposta" :error-messages="errors" required></v-text-field>
                </validation-provider>
              </validation-observer>
            </div>
          </v-col>
          <v-divider vertical class="my-5" />
          <v-col cols="12" sm="6" class="text-right pa-5">
            <v-textarea outlined auto-grow label="Observação" v-model="form.observacao" class="my-3" hide-details />
            <v-card-title>
              <strong>Avaliação do Lead</strong>
            </v-card-title>
            <v-row>
              <v-col cols="12">
                <validation-observer ref="avaliacao" class="mb-10" @submit.prevent="submit" autocomplete="off">
                  <v-rating class="text-center" color="blue" hover length="5" :size="$vuetify.breakpoint.smAndDown ? 32 : 64" v-model="form.notaAvaliacao" @input="getAvaliacaoMotivos" />
                  <validation-provider ref="textFieldProvider" slim v-slot="{ errors }" rules="required">
                    <input type="hidden" v-model="form.notaAvaliacao" name="nota" />
                    <div class="v-messages theme--light error--text text-center">{{ errors[0] }}</div>
                  </validation-provider>
                  <validation-provider v-if="form.motivosAvaliacao && form.motivosAvaliacao.length" v-slot="{ errors }" name="tratamento" vid="tratamento" rules="required">
                    <v-radio-group v-model="form.descricaoAvaliacao" :error-messages="errors" class="px-14 d-flex mt-0">
                      <v-list-item-content style="width: 100%" class="pl-4 align-self-flex-start" v-for="(motivoAvaliacao, index) in form.motivosAvaliacao" v-bind:key="index">
                        <v-radio :value="motivoAvaliacao.descricao" :label="motivoAvaliacao.descricao" @change="changeMotivo(index)" />
                      </v-list-item-content>
                    </v-radio-group>
                  </validation-provider>
                  <base-text-field
                    v-if="showJustificativa"
                    mode="aggressive"
                    rules="required"
                    id="justificativa"
                    name="Justificativa"
                    v-model="form.justificativaAvaliacao"
                    label="Justificativa *"
                    clearable
                    outlined
                  />
                </validation-observer>
              </v-col>
            </v-row>
            <div :class="$vuetify.breakpoint.smAndUp ? 'margin-buttons-finalizacao' : null">
              <v-btn color="primary" outlined class="mr-3" @click="showFinishedLeadModal = false">
                Voltar
              </v-btn>
              <v-btn color="primary" @click="finalizarLead()">
                Finalizar o lead
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Datetimepicker from "@/components/DatetimePicker.vue";
import BaseAutocompleteField from "@/components/BaseAutocompleteField.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import moment from "moment";
import filters from "@/filters";
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import flatten from "flat";
import leadService from "@/services/leadService";
import { mapActions, mapState } from "vuex";

export default {
  name: "DefaultKanban",
  components: {
    draggable,
    "v-datetime-picker": Datetimepicker,
    BaseAutocompleteField,
    BaseTextField,
  },
  props: {
    leads: {
      type: Object,
      required: true,
    },
    tipoTab: {
      type: String,
      required: true,
      default: null,
    },
    defaultURL: {
      type: String,
      required: true,
      default: null,
    },
    leadSelecionado: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {},
      checkChanges: {},
      opcoesMoverLead: [],
      showModal: false,
      showDetailModal: false,
      showFinishedLeadModal: false,
      flagAlteracao: false,
      ultimaAlteracao: {},
      propostaList: [],
      requiredRule: [(v) => !!v || "O campo interação é obrigatório"],
      showJustificativa: false,
      qtdItensNegociacao: 20,
      qtdItensSemSucesso: 20,
      qtdItensRecebidos: 20,
    };
  },
  async mounted() {
    // Funcionalidade de carregar lista conforme scroll
    const listElm = ["#recebidos", "#semSucesso", "#negociacao", "#recebidos-mobile", "#semSucesso-mobile", "#negociacao-mobile"];
    for (let i = 0; i < listElm.length; i++) {
      const elem = document.querySelector(listElm[i]);
      elem.addEventListener("scroll", (e) => {
        if (elem.scrollTop + elem.offsetHeight >= elem.scrollHeight - 100) this.loadMore(listElm[i]);
      });
    }
    this.loadMore();

    this.propostaList = await leadService.getPropostasAssociacao();
    if (this.leadSelecionado) await this.showDetailsLeadSelecionado(this.leadSelecionado);
  },
  watch: {
    /* eslint-disable */
    "form.opcaoInteracao"(opcaoInteracao) {
      if (opcaoInteracao && (this.form.opcaoMoverLead === "Em negociação" || this.form.opcaoMoverLead === "Sem sucesso")) {
        const tratamentos = this.form.opcaoMoverLead === "Em negociação" ? this.form.tratamentosNegociacao : this.form.tratamentosSemSucesso;
        Object.keys(flatten(tratamentos, { delimiter: "||" })).forEach((item) => {
          if (item.includes(opcaoInteracao)) {
            let permiteAgendamento = false;
            const keys = item.split("||");
            if (keys && keys.length === 3) permiteAgendamento = tratamentos[keys[0]][keys[1]].PermiteAgendamento;
            else if (keys && keys.length === 4) permiteAgendamento = tratamentos[keys[0]][keys[1]][keys[2]].PermiteAgendamento;
            this.form = cloneDeep({ ...this.form, permiteAgendamento });
          }
        });
      } else this.form = cloneDeep({ ...this.form, permiteAgendamento: false });
    },
    /* eslint-enable */
  },
  methods: {
    ...mapActions({
      setVinculoPropostaManualToLead: "lead/setVinculoPropostaManualToLead",
      setIdLead: 'cadastroProposta/setIdLead',
    }),
    async onChangeCardLead(e, option) {
      /* eslint-disable */
      if (e.added) {
        try {
          this.ultimaAlteracao = { ...this.ultimaAlteracao, added: e.added };

          if (option === "sem-sucesso") this.opcoesMoverLead = ["Sem sucesso", "Em negociação"];
          else if (option === "negociacao") this.opcoesMoverLead = ["Em negociação", "Sem sucesso"];

          const lead = await leadService.getLead({ codigo: e.added.element.codigo, idLead: e.added.element.idLead });

          this.form = {
            codigo: lead.codigo,
            observacao: lead.observacao || null,
            opcaoMoverLead: this.opcoesMoverLead[0],
            tratamentosSemSucesso: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos, "Sem sucesso") : {},
            tratamentosNegociacao: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos, "Em negociação") : {},
            opcaoInteracao: null,
            flagSemAgendamento: false,
            dataHoraAgendamento: lead.agendamentoTratamento ? lead.agendamentoTratamento.dataHoraAgendamento : "",
            flagVendaFechada: true,
            motivos: lead.tratamentos && lead.tratamentos.length ? this.setMotivos(lead.tratamentos) : {},
            motivoNaoVenda: null,
            flagPesquisarProposta: true,
            idLead: lead.idLead,
          };

          history.pushState({}, null, `${this.defaultURL}/${e.added.element.codigo}`);
          this.$emit("modal", false);

          this.checkChanges = cloneDeep(this.form);

          this.flagAlteracao = true;
          this.showModal = true;
        } catch (error) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Erro ao carregar dados do lead",
          });
          history.pushState({}, null, this.defaultURL);
          this.$emit("modal", true);
        }
      } else if (e.removed) this.ultimaAlteracao = { ...this.ultimaAlteracao, removed: e.removed };
      /* eslint-enable */
    },
    dataHoraAgendamentoFormatado(date) {
      return filters.convertDatetimeFormat(date);
    },
    ok(event) {
      this.form.dataHoraAgendamento = event;
    },
    async showDetails(item, key, option) {
      /* eslint-disable */
      let nomeColuna = null;

      try {
        // De acordo com a coluna clicada, define os destinos possíveis
        if (option === "recebidos") {
          this.opcoesMoverLead = ["Em negociação", "Sem sucesso"];
          nomeColuna = "recebidos";
        } else if (option === "sem-sucesso") {
          this.opcoesMoverLead = ["Sem sucesso", "Em negociação"];
          nomeColuna = "semSucesso";
        } else {
          this.opcoesMoverLead = ["Em negociação", "Sem sucesso"];
          nomeColuna = "negociacao";
        }

        this.$set(this.leads[nomeColuna][key], "loading", true);

        const lead = await leadService.getLead({ codigo: item.codigo, idLead: item.idLead });
        const historicoLead = await leadService.searchHistory(item.codigo);
        const groupByHistoricoLead = groupBy(historicoLead, "dataHoraCriacao");

        const sortedGroupByHistoricoLead = Object.keys(groupByHistoricoLead)
          .sort()
          .reverse()
          .reduce((accumulator, key) => {
            accumulator[key] = groupByHistoricoLead[key];
            return accumulator;
          }, {});

        // Altera flag visualização lead
        const { message } = await leadService.visualizar({ idLead: lead.codigo });
        if (message === "OK") {
          this.$set(this.leads[nomeColuna][key], "visualizacao", true);
          if (lead.alertaTratamento) await leadService.visualizouAlertaTratamento({ codigo: lead.codigo, cpf: this.user.cpf });
        }

        let newOrigem = lead.plataforma ? lead.plataforma : "";
        if (newOrigem !== "" && lead.grupoOrigem) {
          newOrigem = newOrigem.concat(" - ".concat(lead.grupoOrigem ? lead.grupoOrigem : ""));
        }

        this.form = {
          nome: lead.nome,
          codigo: lead.codigo,
          segmentacao: lead.segmentacao || "--",
          telefoneList: lead.telefones && lead.telefones.length ? lead.telefones : [],
          emailList: lead.emails && lead.emails.length ? lead.emails : [],
          local: lead.cidade && lead.estado ? lead.cidade.concat(" - ").concat(lead.estado) : null,
          origem: newOrigem || "--",
          expiracao: lead.prazoSLA ? this.formataExpiracao(lead.prazoSLA) : "--",
          flagExpirando: lead.prazoSLA ? this.showExpiracao(lead.prazoSLA) : false,
          tipoLead: lead.ratingLead ? this.setTermometro(lead.ratingLead) : "0%",
          possuiAlerta: !!lead.alertaTratamento,
          tratamentosSemSucesso: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos, "Sem sucesso") : {},
          tratamentosNegociacao: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos, "Em negociação") : {},
          historico: sortedGroupByHistoricoLead ?? {},
          opcaoMoverLead: this.opcoesMoverLead[0],
          dataHoraAgendamento: lead.agendamentoTratamento ? lead.agendamentoTratamento.dataHoraAgendamento : null,
          opcaoInteracao: lead.tratamentoAplicado || null,
          flagSemAgendamento: false,
          flagVendaFechada: true,
          motivos: lead.tratamentos && lead.tratamentos.length ? this.setMotivos(lead.tratamentos) : {},
          motivoNaoVenda: null,
          flagPesquisarProposta: true,
          observacao: lead.observacao || null,
          dataNascimento: lead.dataNascimento ? this.formataDataNascimento(lead.dataNascimento) : null,
          profissao: lead.profissao || null,
          operadora: lead.operadora || null,
          plano: lead.plano || null,
          qntVidas: lead.qntVidas || null,
          nomeEmpresa: lead.nomeEmpresa || null,
          cnpjEmpresa: lead.cnpjEmpresa ? lead.cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : null,
          idLead: lead.idLead,
        };

        history.pushState({}, null, `${this.defaultURL}/${item.codigo}`);
        this.$emit("modal", false);

        this.checkChanges = cloneDeep(this.form);

        this.flagAlteracao = false;
        this.showDetailModal = true;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao carregar dados do lead",
        });
        history.pushState({}, null, this.defaultURL);
        this.$emit("modal", true);
      } finally {
        this.$set(this.leads[nomeColuna][key], "loading", false);
      }
      /* eslint-enable */
    },
    async showDetailsLeadSelecionado(codigo) {
      try {
        const lead = await leadService.getLead({ codigo });
        const historicoLead = await leadService.searchHistory(codigo);
        const groupByHistoricoLead = groupBy(historicoLead, "dataHoraCriacao");

        const sortedGroupByHistoricoLead = Object.keys(groupByHistoricoLead)
          .sort()
          .reverse()
          .reduce((accumulator, key) => {
            accumulator[key] = groupByHistoricoLead[key];
            return accumulator;
          }, {});

        // De acordo com a coluna clicada, define os destinos possíveis
        if (lead.status === "Leads recebidos") this.opcoesMoverLead = ["Em negociação", "Sem sucesso"];
        else if (lead.status === "Sem sucesso") this.opcoesMoverLead = ["Sem sucesso", "Em negociação"];
        else this.opcoesMoverLead = ["Em negociação", "Sem sucesso"];

        // Altera flag visualização lead
        const { message } = await leadService.visualizar({ idLead: lead.codigo });
        if (message === "OK") {
          this.$emit("visualizado", lead.codigo, lead.leadMailing);
          if (lead.alertaTratamento) await leadService.visualizouAlertaTratamento({ codigo: lead.codigo, cpf: this.user.cpf });
        }

        let newOrigem = lead.plataforma ? lead.plataforma : "";
        if (newOrigem !== "" && lead.grupoOrigem) {
          newOrigem = newOrigem.concat(" - ".concat(lead.grupoOrigem ? lead.grupoOrigem : ""));
        }

        this.form = {
          nome: lead.nome,
          codigo: lead.codigo,
          segmentacao: lead.segmentacao || "--",
          telefoneList: lead.telefones && lead.telefones.length ? lead.telefones : [],
          emailList: lead.emails && lead.emails.length ? lead.emails : [],
          local: lead.cidade && lead.estado ? lead.cidade.concat(" - ").concat(lead.estado) : null,
          origem: newOrigem || "--",
          expiracao: lead.prazoSLA ? this.formataExpiracao(lead.prazoSLA) : "--",
          flagExpirando: lead.prazoSLA ? this.showExpiracao(lead.prazoSLA) : false,
          tipoLead: lead.ratingLead ? this.setTermometro(lead.ratingLead) : "0%",
          possuiAlerta: !!lead.alertaTratamento,
          tratamentosSemSucesso: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos, "Sem sucesso") : {},
          tratamentosNegociacao: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos, "Em negociação") : {},
          historico: sortedGroupByHistoricoLead ?? {},
          opcaoMoverLead: this.opcoesMoverLead[0],
          dataHoraAgendamento: lead.agendamentoTratamento ? lead.agendamentoTratamento.dataHoraAgendamento : null,
          opcaoInteracao: lead.tratamentoAplicado || null,
          flagSemAgendamento: false,
          flagVendaFechada: true,
          motivos: lead.tratamentos && lead.tratamentos.length ? this.setMotivos(lead.tratamentos) : {},
          motivoNaoVenda: null,
          flagPesquisarProposta: true,
          observacao: lead.observacao || null,
          dataNascimento: lead.dataNascimento ? this.formataDataNascimento(lead.dataNascimento) : null,
          profissao: lead.profissao || null,
          operadora: lead.operadora || null,
          plano: lead.plano || null,
          qntVidas: lead.qntVidas || null,
          nomeEmpresa: lead.nomeEmpresa || null,
          cnpjEmpresa: lead.cnpjEmpresa ? lead.cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : null,
          idLead: lead.idLead,
        };

        this.$emit("modal", false);

        this.checkChanges = cloneDeep(this.form);

        this.flagAlteracao = false;
        this.showDetailModal = true;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao carregar dados do lead",
        });
        this.$emit("modal", true);
      }
    },
    onMoveCallback(event) {
      if (event.to.id === "semSucesso" || event.to.id === "negociacao") {
        this.ultimaAlteracao = { ...this.ultimaAlteracao, from: event.from.id, to: event.to.id };
        return true;
      }
      return false;
    },
    ordenar(item, coluna) {
      /* eslint-disable */
      if (item === "Atualizações mais recentes") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao)).reverse();
      else if (item === "Atualizações menos recentes") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao));
      else if (item === "Data de recebimento mais recente") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraCriacao)).reverse();
      else if (item === "Data de recebimento menos recente") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraCriacao));
      else if (item === "Menor SLA") {
        this.leads[coluna] = sortBy(this.leads[coluna], function(e) {
          return parseInt(e?.prazoSLA?.horasValidas || 0) * 60 + parseInt(e?.prazoSLA?.minutosValidos || 0);
        });
      } else if (item === "Maior SLA") {
        this.leads[coluna] = sortBy(this.leads[coluna], function(e) {
          return parseInt(e?.prazoSLA?.horasValidas || 0) * 60 + parseInt(e?.prazoSLA?.minutosValidos || 0);
        }).reverse();
      }
      this.$emit("setOrdenacao", { item, coluna });
      /* eslint-enable */
    },
    showMessage(msg, tipo) {
      this.$root.$snackBar.open({
        color: tipo,
        message: msg,
      });
    },
    async salvar() {
      /* eslint-disable */
      try {
        const isValid = this.$refs.form ? await this.$refs.form.validate() : true;
        if (!isValid) return;

        const { codigo, opcaoInteracao, opcaoMoverLead, flagSemAgendamento, dataHoraAgendamento, permiteAgendamento, observacao, idLead } = this.form;
        let dataHoraAgendamentoFormatado = "";
        if (!flagSemAgendamento && permiteAgendamento) dataHoraAgendamentoFormatado = dataHoraAgendamento;

        if (!opcaoInteracao) {
          this.showMessage("O campo interação deve ser selecionado", "warning");
          return;
        }

        const formData = {
          codigo,
          tipoTratamento: opcaoInteracao,
          agendamento: { dataHoraAgendamento: dataHoraAgendamentoFormatado, msgDataAgendamento: "" },
          observacao: observacao || "",
          status: opcaoMoverLead,
          reativado: false,
          idLead: idLead,
        };

        await leadService.alterar({ codigo: formData.codigo, body: formData });

        const historico = this.checkInsertOrChange({ opcaoInteracao, observacao, dataHoraAgendamento: dataHoraAgendamentoFormatado });

        // Rever inclusão de histórico
        const historicoFormData = {
          historico,
        };

        await leadService.updateHistoryTreatment(formData.codigo, historicoFormData);

        // Recarrega lista de leads
        this.$emit("carrega-dados");
        this.showMessage("Lead atualizado com sucesso", "success");

        // Reset validações
        if (this.$refs.form) this.$refs.form.reset();

        this.showModal = false;
        this.showDetailModal = false;
        this.form = {};
      } catch (error) {
        if (error && error.message === "O status atual do lead não permite alteração.") {
          this.$emit("carrega-dados");
          if (this.$refs.form) this.$refs.form.reset();
          this.$root.$snackBar.open({
            color: "error",
            message: error.message,
          });
          this.showModal = false;
          this.showDetailModal = false;
        } else {
          this.$root.$snackBar.open({
            color: "error",
            message: "Erro ao atualizar tabulação",
          });
        }
      }
      /* eslint-enable */
    },
    async finalizarLead() {
      /* eslint-disable */
      try {
        const isValid = this.$refs.form ? await this.$refs.form.validate() : true;
        const isAvaliacaoValid = this.$refs.avaliacao ? await this.$refs.avaliacao.validate() : true;
        if (!isValid || !isAvaliacaoValid) return;

        const { codigo, motivoNaoVenda, flagVendaFechada, flagPesquisarProposta, nrProposta, observacao, notaAvaliacao, descricaoAvaliacao, justificativaAvaliacao, idLead } = this.form;

        if (!flagVendaFechada && !motivoNaoVenda) {
          this.showMessage("O campo motivo deve ser selecionado", "warning");
          return;
        }

        const formData = {
          codigo,
          tipoTratamento: !flagVendaFechada ? motivoNaoVenda : "Venda",
          agendamento: { dataHoraAgendamento: "", msgDataAgendamento: "" },
          observacao: observacao || "",
          status: "Finalizado",
          reativado: false,
          idLead: idLead,
        };

        const { data } = await leadService.alterar({ codigo: formData.codigo, body: formData });
        const idNeo4j = data.length ? data[0].id : null;

        await leadService.avaliar({
          codigo: formData.codigo,
          obs: observacao || "",
          nota: notaAvaliacao,
          motivo: descricaoAvaliacao,
          justificativa: justificativaAvaliacao || "",
        });

        const historico = [];

        if (!flagVendaFechada)
          historico.push({
            tipo: "alteracao-tratamento",
            descricao: `Alterou o tratamento para: ${motivoNaoVenda}`,
          });

        if (observacao && !this.checkChanges.observacao)
          historico.push({
            tipo: "inclusao-observacao",
            descricao: `Foi inserida uma observação: ${observacao}`,
          });
        else if (observacao !== this.checkChanges.observacao)
          historico.push({
            tipo: "alteracao-observacao",
            descricao: `Alterou a observação para: ${observacao}`,
          });

        if (flagVendaFechada && idNeo4j) {
          if (flagPesquisarProposta) {
            const { guid } = find(this.propostaList, { nrProposta });
            await leadService.vincularPropostaToLead(idNeo4j, [guid]);
          } else await leadService.vincularPropostaManualToLead(idNeo4j, nrProposta, "");
          historico.push({
            tipo: "vincula-proposta",
            descricao: `Proposta vinculada: ${nrProposta}`,
          });
          this.setVinculoPropostaManualToLead(true);
        }

        const historicoFormData = {
          historico,
        };

        await leadService.updateHistoryTreatment(formData.codigo, historicoFormData);

        // Recarrega lista de leads
        this.$emit("carrega-dados");
        this.showMessage("Lead finalizado com sucesso", "success");

        // Reset validações
        if (this.$refs.form) this.$refs.form.reset();
        if (this.$refs.avaliacao) this.$refs.avaliacao.reset();

        history.pushState({}, null, this.defaultURL);
        this.$emit("modal", true);

        this.showJustificativa = false;
        this.showModal = false;
        this.showDetailModal = false;
        this.showFinishedLeadModal = false;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao finalizar lead",
        });
      }
      /* eslint-enable */
    },
    async close() {
      /* eslint-disable */
      const resp = await this.$root.$confirmdialog.open("Confirmação", "Deseja sair sem salvar a informação?");
      if (!resp) {
        if (this.flagAlteracao) this.showModal = true;
        else this.showDetailModal = true;
        return;
      }
      this.showJustificativa = false;
      this.showDetailModal = false;
      this.showFinishedLeadModal = false;
      this.form = {};
      if (this.$refs.form) this.$refs.form.reset();
      if (this.flagAlteracao) {
        const alt = this.ultimaAlteracao;
        const moved = this.leads[alt.to].splice(alt.added.newIndex, 1);
        this.leads[alt.from].splice(alt.removed.oldIndex, 0, moved[0]);
      }
      history.pushState({}, null, this.defaultURL);
      this.$emit("modal", true);
      /* eslint-enable */
    },
    formataData(data) {
      return moment(data).format("DD/MM/YYYY HH[h]mm");
    },
    formataDataNascimento(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    setTermometro(value) {
      // Verificar, foi alinhado que o termometro vai de 80 a 100
      if (value) {
        if (value >= 80 && value <= 84) return "25%";
        if (value >= 85 && value <= 89) return "50%";
        if (value >= 90 && value <= 95) return "75%";
      }
      return "100%";
    },
    setTratamentos(tratamentos, option) {
      const tratamentoList = {};
      for (let i = 0; i < tratamentos.length; i++) {
        const tratamento = tratamentos[i];
        if (tratamento.statusLead === option && tratamento.Descricao !== "Finalização") {
          if (tratamento.Lista && !tratamento.Lista.length) tratamentoList[tratamento.Descricao] = tratamento;
          else tratamentoList[tratamento.Descricao] = {};
          if (tratamento.Lista && tratamento.Lista.length) {
            for (let ii = 0; ii < tratamento.Lista.length; ii++) {
              const itemList = tratamento.Lista[ii];
              if (itemList.statusLead === option) {
                if (itemList.Lista && !itemList.Lista.length) tratamentoList[tratamento.Descricao][itemList.Descricao] = itemList;
                else tratamentoList[tratamento.Descricao][itemList.Descricao] = {};
                if (itemList && tratamento.Descricao && itemList.Lista && itemList.Lista.length) {
                  for (let iii = 0; iii < itemList.Lista.length; iii++) {
                    const itemList2 = itemList.Lista[iii];
                    if (itemList2 && itemList.Descricao && itemList2.Descricao && itemList.Lista && itemList.Lista.length) {
                      tratamentoList[tratamento.Descricao][itemList.Descricao][itemList2.Descricao] = itemList2;
                    }
                  }
                }
              }
            }
          }
        }
      }
      const objKeys = Object.keys(tratamentoList);
      for (let i = 0; i < objKeys.length; i++) {
        if (tratamentoList[objKeys[i]].id) {
          tratamentoList[objKeys[i]] = { "Não categorizado": tratamentoList[objKeys[i]] };
        }
      }
      const sortedTratamentoList = Object.keys(tratamentoList)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = tratamentoList[key];
          return accumulator;
        }, {});
      return sortedTratamentoList;
    },
    setMotivos(motivos) {
      const motivoList = {};
      for (let i = 0; i < motivos.length; i++) {
        const motivo = motivos[i];
        if (motivo && motivo.Status === "Ativo" && motivo.Descricao === "Finalização") {
          if (motivo.Lista && !motivo.Lista.length) motivoList[motivo.Descricao] = motivo;
          else motivoList[motivo.Descricao] = {};
          if (motivo.Lista && motivo.Lista.length) {
            for (let ii = 0; ii < motivo.Lista.length; ii++) {
              const itemList = motivo.Lista[ii];
              if (itemList.Status === "Ativo" && itemList.statusLead === "Finalizado" && itemList.Descricao !== "Venda") {
                if (itemList.Lista && !itemList.Lista.length) motivoList[motivo.Descricao][itemList.Descricao] = itemList;
                else motivoList[motivo.Descricao][itemList.Descricao] = {};
                if (itemList && motivo.Descricao && itemList.Lista && itemList.Lista.length) {
                  for (let iii = 0; iii < itemList.Lista.length; iii++) {
                    const itemList2 = itemList.Lista[iii];
                    if (itemList2 && itemList.Descricao && itemList2.Descricao && itemList.Lista && itemList.Lista.length) {
                      motivoList[motivo.Descricao][itemList.Descricao][itemList2.Descricao] = itemList2;
                    }
                  }
                }
              }
            }
          }
        }
      }
      const objKeys = Object.keys(motivoList);
      for (let i = 0; i < objKeys.length; i++) {
        if (motivoList[objKeys[i]].id) {
          motivoList[objKeys[i]] = { "Não categorizado": motivoList[objKeys[i]] };
        }
      }
      const sortedMotivoList = Object.keys(motivoList)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = motivoList[key];
          return accumulator;
        }, {});
      return sortedMotivoList;
    },
    /* eslint-disable */
    checkInsertOrChange({ opcaoInteracao, observacao, dataHoraAgendamento }) {
      const historico = [];
      if (opcaoInteracao && !this.checkChanges.opcaoInteracao)
        historico.push({
          tipo: "inclusao-tratamento",
          descricao: `Foi inserido um tratamento: ${opcaoInteracao}`,
        });
      else if (opcaoInteracao && opcaoInteracao !== this.checkChanges.opcaoInteracao)
        historico.push({
          tipo: "alteracao-tratamento",
          descricao: `Alterou o tratamento para: ${opcaoInteracao}`,
        });

      if (observacao && !this.checkChanges.observacao)
        historico.push({
          tipo: "inclusao-observacao",
          descricao: `Foi inserida uma observação: ${observacao}`,
        });
      else if (observacao !== this.checkChanges.observacao)
        historico.push({
          tipo: "alteracao-observacao",
          descricao: `Alterou a observação para: ${observacao}`,
        });

      if (dataHoraAgendamento && !this.checkChanges.dataHoraAgendamento)
        historico.push({
          tipo: "inclusao-data-agendamento",
          descricao: `Foi inserida uma data de agendamento: ${this.formataData(dataHoraAgendamento)}`,
        });
      else if (dataHoraAgendamento && dataHoraAgendamento !== this.checkChanges.dataHoraAgendamento)
        historico.push({
          tipo: "alteracao-data-agendamento",
          descricao: `Alterou a data de agendamento para: ${this.formataData(dataHoraAgendamento)}`,
        });

      return historico;
    },
    checkTratamentoSel(tratamentos) {
      const indexList = [];
      for (let i = 0; i < Object.keys(tratamentos).length; i++) indexList.push(i);
      return indexList;
    },
    formataExpiracao(expiracao) {
      if (expiracao) {
        if (parseInt(expiracao.horasValidas) === 0 && parseInt(expiracao.minutosValidos) === 0) return "--";
        return expiracao.horasValidas
          .toString()
          .concat(" hora(s) e ")
          .concat(
            parseInt(expiracao.minutosValidos)
              .toString()
              .concat(" minuto(s)")
          );
      }
      return "--";
    },
    showExpiracao(expiracao) {
      return parseInt(expiracao.horasValidas) === 0 && parseInt(expiracao.minutosValidos) !== 0;
    },
    async getAvaliacaoMotivos(avaliacao) {
      try {
        this.showJustificativa = false;
        this.form.descricaoAvaliacao = null;
        const motivosAvaliacao = await leadService.getAvaliacaoMotivos(avaliacao);
        this.form = cloneDeep({ ...this.form, motivosAvaliacao });
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao listar motivos para avaliação do lead",
        });
      }
    },
    changeMotivo(index) {
      if (this.form.motivosAvaliacao[index].justificativa) this.showJustificativa = true;
      else this.showJustificativa = false;
    },
    loadMore(elem) {
      if (elem === "#negociacao" || elem === "#negociacao-mobile") this.qtdItensNegociacao = this.qtdItensNegociacao + 20;
      if (elem === "#semSucesso" || elem === "#semSucesso-mobile") this.qtdItensSemSucesso = this.qtdItensSemSucesso + 20;
      if (elem === "#recebidos" || elem === "#recebidos-mobile") this.qtdItensRecebidos = this.qtdItensRecebidos + 20;
    },
    linkTelefone(tipo) {
      return tipo === "Celular" ? "https://api.whatsapp.com/send?phone=55" : "tel:+55";
    },
    criarProposta(idLead) {
      this.setIdLead(idLead);
      this.$router.push({ name: 'areaLogada.criarProposta', query: { idLead: idLead } });
    },
    /* eslint-enable */
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
    countLeads() {
      return {
        recebidos: this.leads.recebidos.length,
        semSucesso: this.leads.semSucesso.length,
        negociacao: this.leads.negociacao.length,
      };
    },
    ordenacao() {
      const ordenacao = [
        { title: "Atualizações mais recentes" },
        { title: "Atualizações menos recentes" },
        { title: "Data de recebimento mais recente" },
        { title: "Data de recebimento menos recente" },
        { title: "Menor SLA" },
        { title: "Maior SLA" },
      ];
      if (this.tipoTab === "mailing") {
        ordenacao.pop();
        ordenacao.pop();
      }
      return ordenacao;
    },
    leadsRecebidos() {
      const { recebidos } = this.leads;
      return recebidos.slice(0, this.qtdItensRecebidos);
    },
    leadsSemSucesso() {
      const { semSucesso } = this.leads;
      return semSucesso.slice(0, this.qtdItensSemSucesso);
    },
    leadsNegociacao() {
      const { negociacao } = this.leads;
      return negociacao.slice(0, this.qtdItensNegociacao);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.column {
  background-color: #f4f2f1;
  max-height: 100%;
  border: 1px solid darken(#f4f2f1, 10%);
  border-radius: 10px;

  @media (max-width: $media-sm) {
    min-width: 80%;
    position: relative;
    flex: 1;
    height: 100%;
  }

  @media (min-width: $media-sm) and (max-width: $media-md) {
    min-width: 70%;
    position: relative;
    flex: 1;
    height: 100%;
  }

  @media (min-width: $media-md) and (max-width: $media-lg) {
    min-width: 60%;
    position: relative;
    flex: 1;
    height: 100%;
  }

  @media (min-width: $media-sm) and (max-width: $media-lg) {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  }
}

.container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  flex-direction: row;
  @media (min-width: $media-sm) and (max-width: $media-lg) {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  }
}

.container::-webkit-scrollbar {
  height: 7.5px;
  background-color: #f4f2f1;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 100px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #616166;
}

.overflow {
  height: calc(100vh - 26rem);
  @media (max-width: $media-md) {
    height: calc(100vh - 31.5rem);
  }
  width: 100%;
  overflow-y: auto;
}

.overflow::-webkit-scrollbar {
  width: 7.5px;
  background-color: #f4f2f1;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 100px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #616166;
}

.header {
  color: $azul-qualicorp-hex;
  font-size: 1.3rem;
}

.count-leads {
  color: $azul-qualicorp-hex;
}

.title-custom {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.custom {
  font-size: 0.9rem;
}

.card {
  .title,
  .subtitle-modal {
    font-size: 1.2rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .margin-tipo-lead {
    margin-top: 15%;
  }

  .margin-buttons {
    margin-top: 70%;
  }

  .margin-buttons-finalizacao {
    margin-top: 10%;
  }

  .alert {
    color: #000000;
  }

  &.click {
    cursor: pointer;
  }

  .term {
    min-height: 10rem;
    margin-left: 1px;
    border: 1px solid black;

    &.modal {
      min-height: 100%;
      margin: 4% 3% 2% 0;
    }

    &.vinte-cinco {
      background: -webkit-linear-gradient(bottom, #99e1ff 25%, #ffffff 25%);
      background: -moz-linear-gradient(bottom, #99e1ff 25%, #ffffff 25%);
      background: -ms-linear-gradient(bottom, #99e1ff 25%, #ffffff 25%);
      background: -o-linear-gradient(bottom, #99e1ff 25%, #ffffff 25%);
      background: linear-gradient(to top, #99e1ff 25%, #ffffff 25%);
    }

    &.cinquenta {
      background: -webkit-linear-gradient(bottom, #ffa6b5 50%, #ffffff 50%);
      background: -moz-linear-gradient(bottom, #ffa6b5 50%, #ffffff 50%);
      background: -ms-linear-gradient(bottom, #ffa6b5 50%, #ffffff 50%);
      background: -o-linear-gradient(bottom, #ffa6b5 50%, #ffffff 50%);
      background: linear-gradient(to top, #ffa6b5 50%, #ffffff 50%);
    }

    &.setenta-cinco {
      background: -webkit-linear-gradient(bottom, #ff738b 75%, #ffffff 75%);
      background: -moz-linear-gradient(bottom, #ff738b 75%, #ffffff 75%);
      background: -ms-linear-gradient(bottom, #ff738b 75%, #ffffff 75%);
      background: -o-linear-gradient(bottom, #ff738b 75%, #ffffff 75%);
      background: linear-gradient(to top, #ff738b 75%, #ffffff 75%);
    }

    &.cem {
      background: -webkit-linear-gradient(bottom, #ff4162 100%, #ffffff 100%);
      background: -moz-linear-gradient(bottom, #ff4162 100%, #ffffff 100%);
      background: -ms-linear-gradient(bottom, #ff4162 100%, #ffffff 100%);
      background: -o-linear-gradient(bottom, #ff4162 100%, #ffffff 100%);
      background: linear-gradient(to top, #ff4162 100%, #ffffff 100%);
    }
  }

  .tooltip-term-text {
    visibility: hidden;
    background-color: $azul-qualicorp-hex;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    width: 110px;
    top: 90%;
    left: 60px;
    position: absolute;
    z-index: 2;
  }

  .term:hover + .tooltip-term-text {
    visibility: visible;
  }
}

.ordenacao {
  color: $azul-qualicorp-hex;
  font-size: 1.2rem;
}

.ordenacao-item:hover {
  color: $azul-qualicorp-hex;
}

.tooltip-clock,
.tooltip-fire,
.tooltip-eye {
  position: relative;
  display: inline-block;
}

.tooltip-fire .tooltip-fire-text,
.tooltip-clock .tooltip-clock-text,
.tooltip-eye .tooltip-eye-text {
  visibility: hidden;
  background-color: $azul-qualicorp-hex;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  width: 110px;
  top: 120%;
  left: -50px;
  margin-left: -40px;
  position: absolute;
  z-index: 1;
}

.tooltip-clock:hover .tooltip-clock-text,
.tooltip-fire:hover .tooltip-fire-text,
.tooltip-eye:hover .tooltip-eye-text {
  visibility: visible;
}
</style>
