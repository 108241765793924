var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "d-none d-lg-flex" },
        [
          _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "column pa-5" },
              [
                _c(
                  "v-row",
                  { staticClass: "mb-3" },
                  [
                    _c("v-col", { attrs: { cols: "8" } }, [
                      _c("h3", { staticClass: "header vendidos" }, [
                        _vm._v(" Vendidos "),
                      ]),
                    ]),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-right",
                        attrs: { cols: "4", "align-self": "start" },
                      },
                      [
                        _c("span", { staticClass: "count-leads mr-3" }, [
                          _vm._v(
                            " Total: " +
                              _vm._s(
                                _vm.countLeads.vendidos > 1
                                  ? _vm.countLeads.vendidos + " leads"
                                  : _vm.countLeads.vendidos + " lead"
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "primary" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-dots-horizontal ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c("v-list-item", [
                                  _c("span", { staticClass: "ordenacao" }, [
                                    _c("strong", [_vm._v("Ordenar por:")]),
                                  ]),
                                ]),
                                _vm._l(_vm.ordenacao, function (item, key) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: "A" + key,
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ordenar(
                                            item.title,
                                            "vendidos"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "ordenacao-item" },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "overflow", attrs: { id: "vendidos" } },
                  _vm._l(_vm.leadsVendidos, function (item, key) {
                    return _c(
                      "v-card",
                      {
                        key: "B" + key,
                        staticClass: "card click vendidos mx-auto mb-5 mr-2",
                        attrs: { loading: item.loading, outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(item, key, "vendidos")
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "three-line": "" } },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pb-0",
                                        attrs: { cols: "7", md: "8" },
                                      },
                                      [
                                        _c("span", { staticClass: "title" }, [
                                          _vm._v(" " + _vm._s(item.nome) + " "),
                                        ]),
                                        _c("v-divider", {
                                          staticClass: "divider vendidos mb-4",
                                        }),
                                      ],
                                      1
                                    ),
                                    !!item.propostaGerada
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right mt-1",
                                            attrs: { cols: "5", md: "4" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "btn-reativar text-none pa-0",
                                                attrs: { text: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClickReativarLead()
                                                  },
                                                },
                                              },
                                              [_vm._v(" Reativar lead ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "mb-3" }, [
                                  _c("strong", [_vm._v("Telefone:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.telefoneList.length
                                          ? item.telefoneList[0].ddd +
                                              " " +
                                              item.telefoneList[0].numero
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("p", { staticClass: "mb-3" }, [
                                  _c("strong", [_vm._v("E-mail:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.emailList.length
                                          ? item.emailList[0].email
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("strong", [_vm._v("Finalização:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formataData(
                                          item.dataHoraEncerramento
                                        )
                                      )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "column pa-5" },
              [
                _c(
                  "v-row",
                  { staticClass: "mb-3" },
                  [
                    _c("v-col", { attrs: { cols: "8" } }, [
                      _c("h3", { staticClass: "header nao-vendidos" }, [
                        _vm._v(" Não vendidos "),
                      ]),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "4" } },
                      [
                        _c("span", { staticClass: "count-leads mr-3" }, [
                          _vm._v(
                            " Total: " +
                              _vm._s(
                                _vm.countLeads.naoVendidos > 1
                                  ? _vm.countLeads.naoVendidos + " leads"
                                  : _vm.countLeads.naoVendidos + " lead"
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "primary" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-dots-horizontal ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c("v-list-item", [
                                  _c("span", { staticClass: "ordenacao" }, [
                                    _c("strong", [_vm._v("Ordenar por:")]),
                                  ]),
                                ]),
                                _vm._l(_vm.ordenacao, function (item, key) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: "C" + key,
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ordenar(
                                            item.title,
                                            "naoVendidos"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "ordenacao-item" },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "overflow", attrs: { id: "naoVendidos" } },
                  _vm._l(_vm.leadsNaoVendidos, function (item, key) {
                    return _c(
                      "v-card",
                      {
                        key: "D" + key,
                        staticClass:
                          "card click nao-vendidos mx-auto mb-5 mr-2",
                        attrs: { loading: item.loading, outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(item, key, "nao-vendidos")
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "three-line": "" } },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pb-0",
                                        attrs: { cols: "7", md: "8" },
                                      },
                                      [
                                        _c("span", { staticClass: "title" }, [
                                          _vm._v(" " + _vm._s(item.nome) + " "),
                                        ]),
                                        _c("v-divider", {
                                          staticClass:
                                            "divider nao-vendidos mb-4",
                                        }),
                                      ],
                                      1
                                    ),
                                    item.motivoNaoVenda !== "Expirado"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right mt-1",
                                            attrs: { cols: "5", md: "4" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "btn-reativar text-none pa-0",
                                                attrs: { text: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClickReativarLead()
                                                  },
                                                },
                                              },
                                              [_vm._v(" Reativar lead ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "mb-3" }, [
                                  _c("strong", [_vm._v("Telefone: ")]),
                                  _c("span", { staticClass: "blur" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.telefoneList.length
                                            ? item.telefoneList[0].ddd +
                                                " " +
                                                item.telefoneList[0].numero
                                            : " -- "
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "mb-3" }, [
                                  _c("strong", [_vm._v("E-mail: ")]),
                                  _c("span", { staticClass: "blur" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.emailList.length
                                            ? item.emailList[0].email
                                            : " -- "
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "9", md: "8", sm: "10" },
                                      },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _c("strong", [_vm._v("Motivo:")]),
                                          _vm._v(
                                            " " + _vm._s(item.motivoNaoVenda)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    item.motivoNaoVenda !== "Expirado"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right pb-3",
                                            attrs: {
                                              cols: "3",
                                              md: "4",
                                              sm: "2",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "btn-plus",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.criarProposta = true
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "35",
                                                      color: "white",
                                                    },
                                                  },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tooltip-plus-text",
                                                  },
                                                  [_vm._v(" Criar proposta ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "container px-0 d-flex d-lg-none",
          attrs: { fluid: "" },
        },
        [
          _c(
            "div",
            { staticClass: "column pa-5 mr-5" },
            [
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("h3", { staticClass: "header vendidos" }, [
                      _vm._v(" Vendidos "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "6", "align-self": "start" },
                    },
                    [
                      _c("span", { staticClass: "count-leads mr-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countLeads.vendidos > 1
                                ? _vm.countLeads.vendidos + " leads"
                                : _vm.countLeads.vendidos + " lead"
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { color: "primary" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" mdi-dots-horizontal ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c("v-list-item", [
                                _c("span", { staticClass: "ordenacao" }, [
                                  _c("strong", [_vm._v("Ordenar por:")]),
                                ]),
                              ]),
                              _vm._l(_vm.ordenacao, function (item, key) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: "E" + key,
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.ordenar(
                                          item.title,
                                          "vendidos"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ordenacao-item" },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "overflow", attrs: { id: "vendidos-mobile" } },
                _vm._l(_vm.leadsVendidos, function (item, key) {
                  return _c(
                    "v-card",
                    {
                      key: "F" + key,
                      staticClass: "card click vendidos mx-auto mb-5 mr-2",
                      attrs: { loading: item.loading, outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showDetails(item, key, "vendidos")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "three-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(" " + _vm._s(item.nome) + " "),
                              ]),
                              _c("v-divider", {
                                staticClass: "divider vendidos mb-4",
                              }),
                              _c("p", { staticClass: "mb-3" }, [
                                _c("strong", [_vm._v("Telefone:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.telefoneList.length
                                        ? item.telefoneList[0].ddd +
                                            " " +
                                            item.telefoneList[0].numero
                                        : " -- "
                                    )
                                ),
                              ]),
                              item.emailList.length
                                ? _c("p", { staticClass: "mb-3" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.emailList[0].email.length > 25
                                            ? item.emailList[0].email.substr(
                                                0,
                                                25
                                              ) + "..."
                                            : item.emailList[0].email
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("p", { staticClass: "mb-3" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(" " + _vm._s(" -- ")),
                                  ]),
                              _c("p", { staticClass: "mb-0" }, [
                                _c("strong", [_vm._v("Finalização:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formataData(item.dataHoraEncerramento)
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column pa-5" },
            [
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("h3", { staticClass: "header nao-vendidos" }, [
                      _vm._v(" Não vendidos "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "6" } },
                    [
                      _c("span", { staticClass: "count-leads mr-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countLeads.naoVendidos > 1
                                ? _vm.countLeads.naoVendidos + " leads"
                                : _vm.countLeads.naoVendidos + " lead"
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { color: "primary" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" mdi-dots-horizontal ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c("v-list-item", [
                                _c("span", { staticClass: "ordenacao" }, [
                                  _c("strong", [_vm._v("Ordenar por:")]),
                                ]),
                              ]),
                              _vm._l(_vm.ordenacao, function (item, key) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: "G" + key,
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.ordenar(
                                          item.title,
                                          "naoVendidos"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ordenacao-item" },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "overflow",
                  attrs: { id: "naoVendidos-mobile" },
                },
                _vm._l(_vm.leadsNaoVendidos, function (item, key) {
                  return _c(
                    "v-card",
                    {
                      key: "H" + key,
                      staticClass: "card click nao-vendidos mx-auto mb-5 mr-2",
                      attrs: { loading: item.loading, outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showDetails(item, key, "nao-vendidos")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "three-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v(" " + _vm._s(item.nome) + " "),
                                      ]),
                                      _c("v-divider", {
                                        staticClass:
                                          "divider nao-vendidos mb-4",
                                      }),
                                    ],
                                    1
                                  ),
                                  item.motivoNaoVenda !== "Expirado"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right mt-1",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "btn-reativar text-none pa-0",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onClickReativarLead()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$vuetify.breakpoint.xs
                                                      ? "Reativar"
                                                      : "Reativar lead"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("p", { staticClass: "mb-3" }, [
                                _c("strong", [_vm._v("Telefone: ")]),
                                _c("span", { staticClass: "blur" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.telefoneList.length
                                          ? item.telefoneList[0].ddd +
                                              " " +
                                              item.telefoneList[0].numero
                                          : " -- "
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              item.emailList.length
                                ? _c("p", { staticClass: "mb-3" }, [
                                    _c("strong", [_vm._v("E-mail: ")]),
                                    _c("span", { staticClass: "blur" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.emailList[0].email.length > 25
                                              ? item.emailList[0].email.substr(
                                                  0,
                                                  25
                                                ) + "..."
                                              : item.emailList[0].email
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _c("p", { staticClass: "mb-3" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(" " + _vm._s(" -- ")),
                                  ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [_vm._v("Motivo:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.motivoNaoVenda.length > 25
                                              ? item.motivoNaoVenda.substr(
                                                  0,
                                                  25
                                                ) + "..."
                                              : item.motivoNaoVenda
                                          )
                                      ),
                                    ]),
                                  ]),
                                  item.motivoNaoVenda !== "Expirado"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right pb-3",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn-plus",
                                              on: {
                                                click: function ($event) {
                                                  _vm.criarProposta = true
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "35",
                                                    color: "white",
                                                  },
                                                },
                                                [_vm._v("mdi-plus")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "tooltip-plus-text",
                                                },
                                                [_vm._v(" Criar proposta ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": _vm.tipoModal == "vendidos" ? 500 : 1000 },
          on: {
            "click:outside": _vm.close,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.close.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showDetailModal,
            callback: function ($$v) {
              _vm.showDetailModal = $$v
            },
            expression: "showDetailModal",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "card pa-7" },
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "title-modal pa-0" },
                            [_c("strong", [_vm._v(_vm._s(_vm.form.nome))])]
                          ),
                          _c(
                            "v-card-subtitle",
                            { staticClass: "subtitle-modal pt-5 pl-0" },
                            [
                              _c("strong", [
                                _vm._v(
                                  "Segmentação: " + _vm._s(_vm.form.segmentacao)
                                ),
                              ]),
                            ]
                          ),
                          _vm.tipoModal == "nao-vendidos"
                            ? _c(
                                "div",
                                [
                                  _c("v-divider", { staticClass: "mb-5" }),
                                  _c("p", { staticClass: "mb-3" }, [
                                    _c("strong", [
                                      _vm._v(" Motivo da finalização: "),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.form.motivoNaoVenda)),
                                  ]),
                                  _c("v-divider", { staticClass: "mb-5" }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _vm._l(
                                _vm.form.telefoneList,
                                function (item, key) {
                                  return _c("p", { key: "I" + key }, [
                                    _c("strong", [_vm._v(" Telefone: ")]),
                                    _vm.tipoModal == "vendidos"
                                      ? _c(
                                          "span",
                                          [
                                            item.tipo === "Celular"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "green darken-2",
                                                    },
                                                  },
                                                  [_vm._v("mdi-whatsapp")]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "grey darken-2",
                                                    },
                                                  },
                                                  [_vm._v("mdi-phone")]
                                                ),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "pl-0 text-decoration-none black--text text--lighter-1",
                                                attrs: {
                                                  href:
                                                    _vm.linkTelefone(
                                                      item.tipo
                                                    ) +
                                                    (
                                                      item.ddd + item.numero
                                                    ).replace(/[^\d]+/g, ""),
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(item.ddd) +
                                                    ") " +
                                                    _vm._s(item.numero) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("span", { staticClass: "blur" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.ddd + " " + item.numero
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ])
                                }
                              ),
                              _vm._l(_vm.form.emailList, function (item, key) {
                                return _c("p", { key: "J" + key }, [
                                  _c("strong", [_vm._v(" E-mail: ")]),
                                  _vm.tipoModal == "vendidos"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "grey darken-2",
                                              },
                                            },
                                            [_vm._v("mdi-email")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "pl-1 text-caption text-decoration-none black--text text--lighter-1",
                                              attrs: {
                                                href: `mailto:${item.email}`,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.email))]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("span", { staticClass: "blur" }, [
                                        _vm._v(" " + _vm._s(item.email) + " "),
                                      ]),
                                ])
                              }),
                              _vm.form.local
                                ? _c("p", [
                                    _c("strong", [_vm._v("Cidade/UF:")]),
                                    _vm._v(" " + _vm._s(_vm.form.local)),
                                  ])
                                : _vm._e(),
                              _vm.form.dataNascimento
                                ? _c("p", [
                                    _c("strong", [
                                      _vm._v("Data de nascimento:"),
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(_vm.form.dataNascimento)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.form.profissao
                                ? _c("p", [
                                    _c("strong", [_vm._v("Profissão:")]),
                                    _vm._v(" " + _vm._s(_vm.form.profissao)),
                                  ])
                                : _vm._e(),
                              _vm.form.operadora
                                ? _c("p", [
                                    _c("strong", [_vm._v("Operadora:")]),
                                    _vm._v(" " + _vm._s(_vm.form.operadora)),
                                  ])
                                : _vm._e(),
                              _vm.form.origem
                                ? _c("p", [
                                    _c("strong", [_vm._v("Origem:")]),
                                    _vm._v(" " + _vm._s(_vm.form.origem)),
                                  ])
                                : _vm._e(),
                              _vm.form.plano
                                ? _c("p", [
                                    _c("strong", [_vm._v("Plano:")]),
                                    _vm._v(" " + _vm._s(_vm.form.plano)),
                                  ])
                                : _vm._e(),
                              _vm.form.qntVidas
                                ? _c("p", [
                                    _c("strong", [
                                      _vm._v("Quantidade de vidas:"),
                                    ]),
                                    _vm._v(" " + _vm._s(_vm.form.qntVidas)),
                                  ])
                                : _vm._e(),
                              _vm.form.nomeEmpresa
                                ? _c("p", [
                                    _c("strong", [_vm._v("Nome da empresa:")]),
                                    _vm._v(" " + _vm._s(_vm.form.nomeEmpresa)),
                                  ])
                                : _vm._e(),
                              _vm.form.cnpjEmpresa
                                ? _c("p", [
                                    _c("strong", [_vm._v("CNPJ da empresa:")]),
                                    _vm._v(" " + _vm._s(_vm.form.cnpjEmpresa)),
                                  ])
                                : _vm._e(),
                              _vm.form.nota && _vm.form.dataHora
                                ? _c(
                                    "div",
                                    [
                                      _c("h4", { staticClass: "mt-6" }, [
                                        _vm._v("Avaliação"),
                                      ]),
                                      _c("v-rating", {
                                        staticClass: "mt-2 mb-3",
                                        attrs: {
                                          "background-color": "blue lighten-3",
                                          color: "blue",
                                          length: "5",
                                          size: "16",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.form.nota,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "nota", $$v)
                                          },
                                          expression: "form.nota",
                                        },
                                      }),
                                      _c("p", [
                                        _c("strong", [_vm._v("Motivo:")]),
                                        _vm._v(" " + _vm._s(_vm.form.motivo)),
                                      ]),
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v("Justificativa:"),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(_vm.form.justificativa)
                                        ),
                                      ]),
                                      _c("p", [
                                        _c("strong", [_vm._v("Finalização:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formataData(_vm.form.dataHora)
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-expansion-panels",
                                    { attrs: { accordion: "", flat: "" } },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        [
                                          _c(
                                            "v-expansion-panel-header",
                                            { staticClass: "pl-0" },
                                            [
                                              _c("strong", [
                                                _vm._v("Visualizar histórico"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c(
                                                "v-expansion-panels",
                                                {
                                                  attrs: {
                                                    accordion: "",
                                                    flat: "",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.form.historico,
                                                  function (item, i) {
                                                    return _c(
                                                      "v-expansion-panel",
                                                      { key: i },
                                                      [
                                                        _c(
                                                          "v-expansion-panel-header",
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formataData(
                                                                    item[0]
                                                                      .dataHoraCriacao
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-expansion-panel-content",
                                                          _vm._l(
                                                            item,
                                                            function (
                                                              subitem,
                                                              ii
                                                            ) {
                                                              return _c(
                                                                "p",
                                                                {
                                                                  key: ii,
                                                                  staticClass:
                                                                    "mb-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        subitem.descricao
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.tipoModal == "vendidos" &&
                          _vm.$vuetify.breakpoint.xs
                            ? _c(
                                "div",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error", block: "" },
                                      on: { click: _vm.close },
                                    },
                                    [_vm._v(" Fechar ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "my-5",
                        attrs: { vertical: "" },
                      }),
                      _vm.tipoModal == "nao-vendidos"
                        ? _c(
                            "v-col",
                            { staticClass: "mt-5 pa-5" },
                            [
                              _c("div", [
                                _c("p", { staticClass: "mb-3" }, [
                                  _c("strong", [_vm._v(" Reativar o lead ")]),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Após reativar o lead você poderá visualizar todas as informações pessoais do cliente."
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "O lead reativado voltará para a aba "
                                  ),
                                  _c("strong", [_vm._v("Ativos")]),
                                  _vm._v(", na coluna "),
                                  _c("strong", [_vm._v("em Negociação.")]),
                                ]),
                              ]),
                              _c(
                                "v-card-actions",
                                { staticClass: "margin-buttons" },
                                [
                                  _c("v-spacer"),
                                  _vm.form.motivoNaoVenda !== "Expirado"
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            color: "primary",
                                            outlined: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickReativarLead()
                                            },
                                          },
                                        },
                                        [_vm._v(" Reativar o lead ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.close },
                                    },
                                    [_vm._v(" Fechar ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", persistent: "" },
          model: {
            value: _vm.showTabulacaoModal,
            callback: function ($$v) {
              _vm.showTabulacaoModal = $$v
            },
            expression: "showTabulacaoModal",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "card pa-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-card-title", { staticClass: "title" }, [
                        _c("strong", [
                          _vm._v("Qual a interação você teve com o cliente?"),
                        ]),
                      ]),
                      _c(
                        "v-list",
                        { staticClass: "transparent mx-n4" },
                        [
                          _c(
                            "v-expansion-panels",
                            { attrs: { accordion: "", flat: "" } },
                            _vm._l(
                              _vm.form.tratamentos,
                              function (tratamento, key) {
                                return _c(
                                  "v-expansion-panel",
                                  { key: key },
                                  [
                                    _c("v-expansion-panel-header", [
                                      _vm._v(" " + _vm._s(key) + " "),
                                    ]),
                                    _c(
                                      "v-expansion-panel-content",
                                      _vm._l(tratamento, function (item, key) {
                                        return _c(
                                          "v-expansion-panels",
                                          {
                                            key: key,
                                            attrs: { accordion: "", flat: "" },
                                          },
                                          [
                                            item.Descricao
                                              ? _c(
                                                  "v-expansion-panel",
                                                  [
                                                    _c(
                                                      "v-radio-group",
                                                      {
                                                        staticClass:
                                                          "ma-0 pa-0",
                                                        attrs: {
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .opcaoInteracao,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "opcaoInteracao",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.opcaoInteracao",
                                                        },
                                                      },
                                                      [
                                                        _c("v-radio", {
                                                          attrs: {
                                                            value:
                                                              item.Descricao,
                                                            label:
                                                              item.Descricao,
                                                            color: "primary",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !item.Descricao
                                              ? _c(
                                                  "v-expansion-panel",
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(key) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      _vm._l(
                                                        item,
                                                        function (
                                                          subitem,
                                                          key
                                                        ) {
                                                          return _c(
                                                            "v-radio-group",
                                                            {
                                                              key: key,
                                                              staticClass:
                                                                "ma-0 pa-0",
                                                              attrs: {
                                                                "hide-details":
                                                                  "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .opcaoInteracao,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "opcaoInteracao",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.opcaoInteracao",
                                                              },
                                                            },
                                                            [
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  value:
                                                                    subitem.Descricao,
                                                                  label:
                                                                    subitem.Descricao,
                                                                  color:
                                                                    "primary",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-textarea", {
                        staticClass: "mt-5",
                        attrs: {
                          "hide-details": "",
                          outlined: "",
                          "auto-grow": "",
                          label: "Observação",
                        },
                        model: {
                          value: _vm.form.observacao,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "observacao", $$v)
                          },
                          expression: "form.observacao",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { color: "primary", outlined: "" },
                          on: { click: _vm.voltar },
                        },
                        [_vm._v(" Voltar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.salvar },
                        },
                        [_vm._v(" Salvar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }