var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "d-none d-lg-flex" },
        [
          _c("v-col", { attrs: { cols: "12", lg: "4" } }, [
            _c(
              "div",
              { staticClass: "column pa-5" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "10" } }, [
                      _c("h3", { staticClass: "header" }, [
                        _vm._v(" Leads recebidos "),
                      ]),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "primary" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-dots-horizontal ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c("v-list-item", [
                                  _c("span", { staticClass: "ordenacao" }, [
                                    _c("strong", [_vm._v("Ordenar por:")]),
                                  ]),
                                ]),
                                _vm._l(_vm.ordenacao, function (item, key) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: "A" + key,
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ordenar(
                                            item.title,
                                            "recebidos"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "ordenacao-item" },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mb-3" },
                  [
                    _c("v-col", { staticClass: "text-right" }, [
                      _c("span", { staticClass: "count-leads" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countLeads.recebidos > 1
                                ? _vm.countLeads.recebidos + " leads"
                                : _vm.countLeads.recebidos + " lead"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "list-group overflow",
                    attrs: {
                      id: "recebidos",
                      list: _vm.leads.recebidos,
                      group: "leads",
                      move: _vm.onMoveCallback,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChangeCardLead($event, "recebidos")
                      },
                    },
                  },
                  _vm._l(_vm.leadsRecebidos, function (item, key) {
                    return _c(
                      "v-card",
                      {
                        key: "B" + key,
                        staticClass: "card click mx-auto mb-5 mr-2",
                        attrs: { loading: item.loading, outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(item, key, "recebidos")
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "three-line": "" } },
                          [
                            _vm.tipoTab == "ativos"
                              ? _c("v-list-item-avatar", {
                                  class: {
                                    term: true,
                                    "vinte-cinco": item.tipoLead == "25%",
                                    cinquenta: item.tipoLead == "50%",
                                    "setenta-cinco": item.tipoLead == "75%",
                                    cem: item.tipoLead == "100%",
                                  },
                                  attrs: { tile: "", size: "8" },
                                })
                              : _vm._e(),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "9" } }, [
                                      _c(
                                        "span",
                                        { staticClass: "title-custom" },
                                        [_vm._v(_vm._s(item.nome))]
                                      ),
                                      _vm.tipoTab == "ativos"
                                        ? _c(
                                            "p",
                                            { staticClass: "mb-0 custom" },
                                            [
                                              _vm._v(
                                                "Expira em: " +
                                                  _vm._s(item.expiracao)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm.tipoTab == "ativos" || item.visualizacao
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right",
                                            attrs: { cols: "3" },
                                          },
                                          [
                                            _vm.tipoTab == "ativos" &&
                                            item.flagExpirando
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tooltip-clock",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "#FFB61E",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-clock-time-four "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tooltip-clock-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Cliente está quase expirando! "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.tipoTab == "ativos" &&
                                            item.possuiAlerta
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "tooltip-fire",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      [_vm._v("mdi-fire")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tooltip-fire-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Ligue agora! O cliente fez uma nova simulação! "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.visualizacao
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "tooltip-eye",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v("mdi-eye")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tooltip-eye-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Lead já visualizado! "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "mb-4" }),
                                _c("p", { staticClass: "custom" }, [
                                  _c("strong", [_vm._v("Telefone:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.telefoneList.length
                                          ? item.telefoneList[0].ddd +
                                              " " +
                                              item.telefoneList[0].numero
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("p", { staticClass: "custom" }, [
                                  _c("strong", [_vm._v("E-mail:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.emailList.length
                                          ? item.emailList[0].email
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("v-divider", { staticClass: "mt-2 mb-4" }),
                                _c("p", { staticClass: "mb-0 custom" }, [
                                  _vm._v(
                                    "Entrada: " +
                                      _vm._s(
                                        _vm.formataData(item.dataHoraCriacao)
                                      )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", lg: "4" } }, [
            _c(
              "div",
              { staticClass: "column pa-5" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "10" } }, [
                      _c("h3", { staticClass: "header" }, [
                        _vm._v(" Sem sucesso "),
                      ]),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "primary" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-dots-horizontal ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c("v-list-item", [
                                  _c("span", { staticClass: "ordenacao" }, [
                                    _c("strong", [_vm._v("Ordenar por:")]),
                                  ]),
                                ]),
                                _vm._l(_vm.ordenacao, function (item, key) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: "C" + key,
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ordenar(
                                            item.title,
                                            "semSucesso"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "ordenacao-item" },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mb-3" },
                  [
                    _c("v-col", { staticClass: "text-right" }, [
                      _c("span", { staticClass: "count-leads" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countLeads.semSucesso > 1
                                ? _vm.countLeads.semSucesso + " leads"
                                : _vm.countLeads.semSucesso + " lead"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "list-group overflow",
                    attrs: {
                      id: "semSucesso",
                      list: _vm.leads.semSucesso,
                      group: "leads",
                      move: _vm.onMoveCallback,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChangeCardLead($event, "sem-sucesso")
                      },
                    },
                  },
                  _vm._l(_vm.leadsSemSucesso, function (item, key) {
                    return _c(
                      "v-card",
                      {
                        key: "D" + key,
                        staticClass: "card click mx-auto mb-5 mr-2",
                        attrs: { loading: item.loading, outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(item, key, "sem-sucesso")
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "three-line": "" } },
                          [
                            _vm.tipoTab == "ativos"
                              ? _c("v-list-item-avatar", {
                                  class: {
                                    term: true,
                                    "vinte-cinco": item.tipoLead == "25%",
                                    cinquenta: item.tipoLead == "50%",
                                    "setenta-cinco": item.tipoLead == "75%",
                                    cem: item.tipoLead == "100%",
                                  },
                                  attrs: { tile: "", size: "8" },
                                })
                              : _vm._e(),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "9" } }, [
                                      _c(
                                        "span",
                                        { staticClass: "title-custom" },
                                        [_vm._v(_vm._s(item.nome))]
                                      ),
                                      _vm.tipoTab == "ativos"
                                        ? _c(
                                            "p",
                                            { staticClass: "mb-0 custom" },
                                            [
                                              _vm._v(
                                                "Expira em: " +
                                                  _vm._s(item.expiracao)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm.tipoTab == "ativos"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right",
                                            attrs: { cols: "3" },
                                          },
                                          [
                                            item.flagExpirando
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tooltip-clock",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "#FFB61E",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-clock-time-four "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tooltip-clock-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Cliente está quase expirando! "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.possuiAlerta
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "tooltip-fire",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      [_vm._v("mdi-fire")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tooltip-fire-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Ligue agora! O cliente fez uma nova simulação! "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "mb-4" }),
                                _c("p", { staticClass: "custom" }, [
                                  _c("strong", [_vm._v("Telefone:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.telefoneList.length
                                          ? item.telefoneList[0].ddd +
                                              " " +
                                              item.telefoneList[0].numero
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("p", { staticClass: "custom" }, [
                                  _c("strong", [_vm._v("E-mail:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.emailList.length
                                          ? item.emailList[0].email
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("v-divider", { staticClass: "mt-2 mb-4" }),
                                _c("p", { staticClass: "mb-0 custom" }, [
                                  _vm._v(
                                    "Entrada: " +
                                      _vm._s(
                                        _vm.formataData(item.dataHoraCriacao)
                                      )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", lg: "4" } }, [
            _c(
              "div",
              { staticClass: "column pa-5" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "10" } }, [
                      _c("h3", { staticClass: "header" }, [
                        _vm._v(" Em negociação "),
                      ]),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "2" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "primary" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-dots-horizontal ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c("v-list-item", [
                                  _c("span", { staticClass: "ordenacao" }, [
                                    _c("strong", [_vm._v("Ordenar por:")]),
                                  ]),
                                ]),
                                _vm._l(_vm.ordenacao, function (item, key) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: "E" + key,
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ordenar(
                                            item.title,
                                            "negociacao"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "ordenacao-item" },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mb-3" },
                  [
                    _c("v-col", { staticClass: "text-right" }, [
                      _c("span", { staticClass: "count-leads" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countLeads.negociacao > 1
                                ? _vm.countLeads.negociacao + " leads"
                                : _vm.countLeads.negociacao + " lead"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "list-group overflow",
                    attrs: {
                      id: "negociacao",
                      list: _vm.leads.negociacao,
                      group: "leads",
                      move: _vm.onMoveCallback,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChangeCardLead($event, "negociacao")
                      },
                    },
                  },
                  _vm._l(_vm.leadsNegociacao, function (item, key) {
                    return _c(
                      "v-card",
                      {
                        key: "F" + key,
                        staticClass: "card click mx-auto mb-5 mr-2",
                        attrs: { loading: item.loading, outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(item, key, "negociacao")
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "three-line": "" } },
                          [
                            _vm.tipoTab == "ativos"
                              ? _c("v-list-item-avatar", {
                                  class: {
                                    term: true,
                                    "vinte-cinco": item.tipoLead == "25%",
                                    cinquenta: item.tipoLead == "50%",
                                    "setenta-cinco": item.tipoLead == "75%",
                                    cem: item.tipoLead == "100%",
                                  },
                                  attrs: { tile: "", size: "8" },
                                })
                              : _vm._e(),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "9" } }, [
                                      _c(
                                        "span",
                                        { staticClass: "title-custom" },
                                        [_vm._v(_vm._s(item.nome))]
                                      ),
                                      _vm.tipoTab == "ativos"
                                        ? _c(
                                            "p",
                                            { staticClass: "mb-0 custom" },
                                            [
                                              _vm._v(
                                                "Expira em: " +
                                                  _vm._s(item.expiracao)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm.tipoTab == "ativos"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right",
                                            attrs: { cols: "3" },
                                          },
                                          [
                                            item.flagExpirando
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tooltip-clock",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "#FFB61E",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-clock-time-four "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tooltip-clock-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Cliente está quase expirando! "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.possuiAlerta
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "tooltip-fire",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      [_vm._v("mdi-fire")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tooltip-fire-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Ligue agora! O cliente fez uma nova simulação! "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "mb-4" }),
                                _c("p", { staticClass: "custom" }, [
                                  _c("strong", [_vm._v("Telefone:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.telefoneList.length
                                          ? item.telefoneList[0].ddd +
                                              " " +
                                              item.telefoneList[0].numero
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("p", { staticClass: "custom" }, [
                                  _c("strong", [_vm._v("E-mail:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.emailList.length
                                          ? item.emailList[0].email
                                          : " -- "
                                      )
                                  ),
                                ]),
                                _c("v-divider", { staticClass: "mt-2 mb-4" }),
                                _c("p", { staticClass: "mb-0 custom" }, [
                                  _vm._v(
                                    "Entrada: " +
                                      _vm._s(
                                        _vm.formataData(item.dataHoraCriacao)
                                      )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "container px-0 d-flex d-lg-none",
          attrs: { fluid: "" },
        },
        [
          _c(
            "div",
            { staticClass: "column pa-5 mr-5" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c("h3", { staticClass: "header" }, [
                      _vm._v(" Leads recebidos "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { color: "primary" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" mdi-dots-horizontal ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c("v-list-item", [
                                _c("span", { staticClass: "ordenacao" }, [
                                  _c("strong", [_vm._v("Ordenar por:")]),
                                ]),
                              ]),
                              _vm._l(_vm.ordenacao, function (item, key) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: "G" + key,
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.ordenar(
                                          item.title,
                                          "recebidos"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ordenacao-item" },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c("v-col", { staticClass: "text-right" }, [
                    _c("span", { staticClass: "count-leads" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.countLeads.recebidos > 1
                              ? _vm.countLeads.recebidos + " leads"
                              : _vm.countLeads.recebidos + " lead"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "overflow", attrs: { id: "recebidos-mobile" } },
                _vm._l(_vm.leadsRecebidos, function (item, key) {
                  return _c(
                    "v-card",
                    {
                      key: "H" + key,
                      staticClass: "card click mx-auto mb-5 mr-2 d-flex",
                      attrs: { loading: item.loading, outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showDetails(item, key, "recebidos")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "three-line": "" } },
                        [
                          _vm.tipoTab == "ativos"
                            ? _c("v-list-item-avatar", {
                                class: {
                                  term: true,
                                  "vinte-cinco": item.tipoLead == "25%",
                                  cinquenta: item.tipoLead == "50%",
                                  "setenta-cinco": item.tipoLead == "75%",
                                  cem: item.tipoLead == "100%",
                                },
                                attrs: { tile: "", size: "8" },
                              })
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "title-custom" },
                                      [_vm._v(_vm._s(item.nome))]
                                    ),
                                    _vm.tipoTab == "ativos"
                                      ? _c(
                                          "p",
                                          { staticClass: "mb-0 custom" },
                                          [
                                            _vm._v(
                                              "Expira em: " +
                                                _vm._s(item.expiracao)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm.tipoTab == "ativos" || item.visualizacao
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          _vm.tipoTab == "ativos" &&
                                          item.flagExpirando
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "tooltip-clock",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#FFB61E",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-clock-time-four "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-clock-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Cliente está quase expirando! "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.tipoTab == "ativos" &&
                                          item.possuiAlerta
                                            ? _c(
                                                "div",
                                                { staticClass: "tooltip-fire" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("mdi-fire")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-fire-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Ligue agora! O cliente fez uma nova simulação! "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.visualizacao
                                            ? _c(
                                                "div",
                                                { staticClass: "tooltip-eye" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("mdi-eye")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-eye-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Lead já visualizado! "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "mb-4" }),
                              _c("p", { staticClass: "custom" }, [
                                _c("strong", [_vm._v("Telefone:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.telefoneList.length
                                        ? item.telefoneList[0].ddd +
                                            " " +
                                            item.telefoneList[0].numero
                                        : " -- "
                                    )
                                ),
                              ]),
                              item.emailList.length
                                ? _c("p", { staticClass: "custom" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.emailList[0].email.length > 25
                                            ? item.emailList[0].email.substr(
                                                0,
                                                25
                                              ) + "..."
                                            : item.emailList[0].email
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("p", { staticClass: "custom" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(" " + _vm._s(" -- ")),
                                  ]),
                              _c("v-divider", { staticClass: "mt-2 mb-4" }),
                              _c("p", { staticClass: "mb-0 custom" }, [
                                _vm._v(
                                  "Entrada: " +
                                    _vm._s(
                                      _vm.formataData(item.dataHoraCriacao)
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column pa-5 mr-5" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c("h3", { staticClass: "header" }, [
                      _vm._v(" Sem sucesso "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { color: "primary" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" mdi-dots-horizontal ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c("v-list-item", [
                                _c("span", { staticClass: "ordenacao" }, [
                                  _c("strong", [_vm._v("Ordenar por:")]),
                                ]),
                              ]),
                              _vm._l(_vm.ordenacao, function (item, key) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: "I" + key,
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.ordenar(
                                          item.title,
                                          "semSucesso"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ordenacao-item" },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c("v-col", { staticClass: "text-right" }, [
                    _c("span", { staticClass: "count-leads" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.countLeads.semSucesso > 1
                              ? _vm.countLeads.semSucesso + " leads"
                              : _vm.countLeads.semSucesso + " lead"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "overflow", attrs: { id: "semSucesso-mobile" } },
                _vm._l(_vm.leadsSemSucesso, function (item, key) {
                  return _c(
                    "v-card",
                    {
                      key: "J" + key,
                      staticClass: "card click mx-auto mb-5 mr-2 d-flex",
                      attrs: { loading: item.loading, outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showDetails(item, key, "sem-sucesso")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "three-line": "" } },
                        [
                          _vm.tipoTab == "ativos"
                            ? _c("v-list-item-avatar", {
                                class: {
                                  term: true,
                                  "vinte-cinco": item.tipoLead == "25%",
                                  cinquenta: item.tipoLead == "50%",
                                  "setenta-cinco": item.tipoLead == "75%",
                                  cem: item.tipoLead == "100%",
                                },
                                attrs: { tile: "", size: "8" },
                              })
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "title-custom" },
                                      [_vm._v(_vm._s(item.nome))]
                                    ),
                                    _vm.tipoTab == "ativos"
                                      ? _c(
                                          "p",
                                          { staticClass: "mb-0 custom" },
                                          [
                                            _vm._v(
                                              "Expira em: " +
                                                _vm._s(item.expiracao)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm.tipoTab == "ativos"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          item.flagExpirando
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "tooltip-clock",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#FFB61E",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-clock-time-four "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-clock-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Cliente está quase expirando! "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.possuiAlerta
                                            ? _c(
                                                "div",
                                                { staticClass: "tooltip-fire" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("mdi-fire")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-fire-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Ligue agora! O cliente fez uma nova simulação! "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "mb-4" }),
                              _c("p", { staticClass: "custom" }, [
                                _c("strong", [_vm._v("Telefone:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.telefoneList.length
                                        ? item.telefoneList[0].ddd +
                                            " " +
                                            item.telefoneList[0].numero
                                        : " -- "
                                    )
                                ),
                              ]),
                              item.emailList.length
                                ? _c("p", { staticClass: "custom" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.emailList[0].email.length > 25
                                            ? item.emailList[0].email.substr(
                                                0,
                                                25
                                              ) + "..."
                                            : item.emailList[0].email
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("p", { staticClass: "custom" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(" " + _vm._s(" -- ")),
                                  ]),
                              _c("v-divider", { staticClass: "mt-2 mb-4" }),
                              _c("p", { staticClass: "mb-0 custom" }, [
                                _vm._v(
                                  "Entrada: " +
                                    _vm._s(
                                      _vm.formataData(item.dataHoraCriacao)
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column pa-5" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c("h3", { staticClass: "header" }, [
                      _vm._v(" Em negociação "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { color: "primary" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" mdi-dots-horizontal ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c("v-list-item", [
                                _c("span", { staticClass: "ordenacao" }, [
                                  _c("strong", [_vm._v("Ordenar por:")]),
                                ]),
                              ]),
                              _vm._l(_vm.ordenacao, function (item, key) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: "K" + key,
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.ordenar(
                                          item.title,
                                          "negociacao"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ordenacao-item" },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c("v-col", { staticClass: "text-right" }, [
                    _c("span", { staticClass: "count-leads" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.countLeads.negociacao > 1
                              ? _vm.countLeads.negociacao + " leads"
                              : _vm.countLeads.negociacao + " lead"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "overflow", attrs: { id: "negociacao-mobile" } },
                _vm._l(_vm.leadsNegociacao, function (item, key) {
                  return _c(
                    "v-card",
                    {
                      key: "L" + key,
                      staticClass: "card click mx-auto mb-5 mr-2 d-flex",
                      attrs: { loading: item.loading, outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showDetails(item, key, "negociacao")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "three-line": "" } },
                        [
                          _vm.tipoTab == "ativos"
                            ? _c("v-list-item-avatar", {
                                class: {
                                  term: true,
                                  "vinte-cinco": item.tipoLead == "25%",
                                  cinquenta: item.tipoLead == "50%",
                                  "setenta-cinco": item.tipoLead == "75%",
                                  cem: item.tipoLead == "100%",
                                },
                                attrs: { tile: "", size: "8" },
                              })
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "title-custom" },
                                      [_vm._v(_vm._s(item.nome))]
                                    ),
                                    _vm.tipoTab == "ativos"
                                      ? _c(
                                          "p",
                                          { staticClass: "mb-0 custom" },
                                          [
                                            _vm._v(
                                              "Expira em: " +
                                                _vm._s(item.expiracao)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm.tipoTab == "ativos"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          item.flagExpirando
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "tooltip-clock",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#FFB61E",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-clock-time-four "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-clock-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Cliente está quase expirando! "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.possuiAlerta
                                            ? _c(
                                                "div",
                                                { staticClass: "tooltip-fire" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("mdi-fire")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tooltip-fire-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Ligue agora! O cliente fez uma nova simulação! "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "mb-4" }),
                              _c("p", { staticClass: "custom" }, [
                                _c("strong", [_vm._v("Telefone:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.telefoneList.length
                                        ? item.telefoneList[0].ddd +
                                            " " +
                                            item.telefoneList[0].numero
                                        : " -- "
                                    )
                                ),
                              ]),
                              item.emailList.length
                                ? _c("p", { staticClass: "custom" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.emailList[0].email.length > 25
                                            ? item.emailList[0].email.substr(
                                                0,
                                                25
                                              ) + "..."
                                            : item.emailList[0].email
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("p", { staticClass: "custom" }, [
                                    _c("strong", [_vm._v("E-mail:")]),
                                    _vm._v(" " + _vm._s(" -- ")),
                                  ]),
                              _c("v-divider", { staticClass: "mt-2 mb-4" }),
                              _c("p", { staticClass: "mb-0 custom" }, [
                                _vm._v(
                                  "Entrada: " +
                                    _vm._s(
                                      _vm.formataData(item.dataHoraCriacao)
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1000" },
          on: {
            "click:outside": _vm.close,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.close.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          !_vm.showFinishedLeadModal
            ? _c(
                "v-card",
                { staticClass: "card pa-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "pa-5" }, [
                        _vm.form.opcaoMoverLead == "Sem sucesso" ||
                        _vm.form.opcaoMoverLead == "Em negociação"
                          ? _c(
                              "div",
                              [
                                _c("v-card-title", { staticClass: "title" }, [
                                  _c("strong", [
                                    _vm._v(
                                      "Qual a interação você teve com o cliente?"
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "v-list",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.opcaoMoverLead ==
                                          "Sem sucesso",
                                        expression:
                                          "form.opcaoMoverLead == 'Sem sucesso'",
                                      },
                                    ],
                                    staticClass: "transparent mx-n4",
                                  },
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      {
                                        attrs: {
                                          accordion: "",
                                          flat: "",
                                          multiple: "",
                                          value: _vm.checkTratamentoSel(
                                            _vm.form.tratamentosSemSucesso
                                          ),
                                        },
                                      },
                                      _vm._l(
                                        _vm.form.tratamentosSemSucesso,
                                        function (tratamento, key) {
                                          return _c(
                                            "v-expansion-panel",
                                            { key: key },
                                            [
                                              _c("v-expansion-panel-header", [
                                                _vm._v(" " + _vm._s(key) + " "),
                                              ]),
                                              _c(
                                                "v-expansion-panel-content",
                                                _vm._l(
                                                  tratamento,
                                                  function (item, key) {
                                                    return _c(
                                                      "v-expansion-panels",
                                                      {
                                                        key: key,
                                                        attrs: {
                                                          accordion: "",
                                                          flat: "",
                                                          value: 0,
                                                        },
                                                      },
                                                      [
                                                        item.Descricao
                                                          ? _c(
                                                              "v-expansion-panel",
                                                              [
                                                                _c(
                                                                  "v-radio-group",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-0",
                                                                    attrs: {
                                                                      "hide-details":
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .opcaoInteracao,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "opcaoInteracao",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.opcaoInteracao",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-radio",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            item.Descricao,
                                                                          label:
                                                                            item.Descricao,
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !item.Descricao
                                                          ? _c(
                                                              "v-expansion-panel",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel-header",
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          key
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-expansion-panel-content",
                                                                  _vm._l(
                                                                    item,
                                                                    function (
                                                                      subitem,
                                                                      key
                                                                    ) {
                                                                      return _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          key: key,
                                                                          staticClass:
                                                                            "ma-0 pa-0",
                                                                          attrs:
                                                                            {
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .opcaoInteracao,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "opcaoInteracao",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.opcaoInteracao",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    subitem.Descricao,
                                                                                  label:
                                                                                    subitem.Descricao,
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.opcaoMoverLead ==
                                          "Em negociação",
                                        expression:
                                          "form.opcaoMoverLead == 'Em negociação'",
                                      },
                                    ],
                                    staticClass: "transparent mx-n4",
                                  },
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      {
                                        attrs: {
                                          accordion: "",
                                          flat: "",
                                          multiple: "",
                                          value: _vm.checkTratamentoSel(
                                            _vm.form.tratamentosNegociacao
                                          ),
                                        },
                                      },
                                      _vm._l(
                                        _vm.form.tratamentosNegociacao,
                                        function (tratamento, key) {
                                          return _c(
                                            "v-expansion-panel",
                                            { key: key },
                                            [
                                              _c("v-expansion-panel-header", [
                                                _vm._v(" " + _vm._s(key) + " "),
                                              ]),
                                              _c(
                                                "v-expansion-panel-content",
                                                _vm._l(
                                                  tratamento,
                                                  function (item, key) {
                                                    return _c(
                                                      "v-expansion-panels",
                                                      {
                                                        key: key,
                                                        attrs: {
                                                          accordion: "",
                                                          flat: "",
                                                          value: 0,
                                                        },
                                                      },
                                                      [
                                                        item.Descricao
                                                          ? _c(
                                                              "v-expansion-panel",
                                                              [
                                                                _c(
                                                                  "v-radio-group",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-0",
                                                                    attrs: {
                                                                      "hide-details":
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .opcaoInteracao,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "opcaoInteracao",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.opcaoInteracao",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-radio",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            item.Descricao,
                                                                          label:
                                                                            item.Descricao,
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !item.Descricao
                                                          ? _c(
                                                              "v-expansion-panel",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel-header",
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          key
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-expansion-panel-content",
                                                                  _vm._l(
                                                                    item,
                                                                    function (
                                                                      subitem,
                                                                      key
                                                                    ) {
                                                                      return _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          key: key,
                                                                          staticClass:
                                                                            "ma-0 pa-0",
                                                                          attrs:
                                                                            {
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .opcaoInteracao,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "opcaoInteracao",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.opcaoInteracao",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    subitem.Descricao,
                                                                                  label:
                                                                                    subitem.Descricao,
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.permiteAgendamento
                          ? _c(
                              "div",
                              [
                                _c("v-card-title", { staticClass: "title" }, [
                                  _c("strong", [
                                    _vm._v("Faça um agendamento:"),
                                  ]),
                                ]),
                                _c(
                                  "v-card-text",
                                  { staticClass: "card-text" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "Selecione uma nova data e hora para entrar em contato com o cliente. Em seguida, clique em SALVAR."
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "Esse evento será salvo automaticamente no seu calendário."
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "validation-observer",
                                  { ref: "form" },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "agendamento",
                                        vid: "agendamento",
                                        rules: !_vm.form.flagSemAgendamento
                                          ? "required"
                                          : null,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-datetime-picker", {
                                                  attrs: {
                                                    datetime:
                                                      _vm.dataHoraAgendamentoFormatado(
                                                        _vm.form
                                                          .dataHoraAgendamento
                                                      ),
                                                    disabled:
                                                      _vm.form
                                                        .flagSemAgendamento,
                                                    label: "Agendamento",
                                                    "no-title": "",
                                                    "date-format": "dd/MM/yyyy",
                                                    errors: errors,
                                                  },
                                                  on: {
                                                    ok: function ($event) {
                                                      return _vm.ok($event)
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4191672274
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c("v-checkbox", {
                                  attrs: {
                                    label:
                                      "Não quero fazer um agendamento agora",
                                    color: "primary",
                                  },
                                  model: {
                                    value: _vm.form.flagSemAgendamento,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "flagSemAgendamento",
                                        $$v
                                      )
                                    },
                                    expression: "form.flagSemAgendamento",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("v-divider", {
                        staticClass: "my-5",
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "v-col",
                        { staticClass: "mt-5 pa-5" },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.opcoesMoverLead,
                              "item-text": "label",
                              label: "Mover lead para:",
                            },
                            on: {
                              change: function ($event) {
                                _vm.form.opcaoInteracao = null
                              },
                            },
                            model: {
                              value: _vm.form.opcaoMoverLead,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "opcaoMoverLead", $$v)
                              },
                              expression: "form.opcaoMoverLead",
                            },
                          }),
                          _c("v-textarea", {
                            staticClass: "mt-3",
                            attrs: {
                              outlined: "",
                              "auto-grow": "",
                              label: "Observação",
                            },
                            model: {
                              value: _vm.form.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "observacao", $$v)
                              },
                              expression: "form.observacao",
                            },
                          }),
                          _c(
                            "v-card-actions",
                            {
                              class:
                                _vm.form.opcaoMoverLead == "Sem sucesso" ||
                                _vm.form.opcaoMoverLead == "Em negociação"
                                  ? "margin-buttons"
                                  : null,
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mr-4",
                                  attrs: { color: "#FFB600" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.criarProposta(_vm.form.idLead)
                                    },
                                  },
                                },
                                [_vm._v(" Criar proposta ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3",
                                  attrs: { color: "primary", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showFinishedLeadModal = true
                                    },
                                  },
                                },
                                [_vm._v(" Finalizar o lead ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.salvar },
                                },
                                [_vm._v(" Salvar ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                { staticClass: "card pa-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-5", attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-card-title", { staticClass: "title" }, [
                            _c("strong", [_vm._v("Conseguiu fechar a venda?")]),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0 mx-3",
                              attrs: { row: "", "hide-details": "" },
                              model: {
                                value: _vm.form.flagVendaFechada,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "flagVendaFechada", $$v)
                                },
                                expression: "form.flagVendaFechada",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Sim", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Não", value: false },
                              }),
                            ],
                            1
                          ),
                          !_vm.form.flagVendaFechada
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "title mt-3" },
                                    [_c("strong", [_vm._v("Qual o motivo?")])]
                                  ),
                                  _c(
                                    "v-list",
                                    { staticClass: "transparent mx-n4" },
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        { attrs: { accordion: "", flat: "" } },
                                        _vm._l(
                                          _vm.form.motivos,
                                          function (motivo, key) {
                                            return _c(
                                              "v-expansion-panel",
                                              { key: key },
                                              [
                                                _c("v-expansion-panel-header", [
                                                  _vm._v(
                                                    " " + _vm._s(key) + " "
                                                  ),
                                                ]),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  _vm._l(
                                                    motivo,
                                                    function (item, key) {
                                                      return _c(
                                                        "v-expansion-panels",
                                                        {
                                                          key: key,
                                                          attrs: {
                                                            accordion: "",
                                                            flat: "",
                                                          },
                                                        },
                                                        [
                                                          item.Descricao
                                                            ? _c(
                                                                "v-expansion-panel",
                                                                [
                                                                  _c(
                                                                    "v-radio-group",
                                                                    {
                                                                      staticClass:
                                                                        "ma-0 pa-0",
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .motivoNaoVenda,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.form,
                                                                              "motivoNaoVenda",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.motivoNaoVenda",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                item.Descricao,
                                                                              label:
                                                                                item.Descricao,
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          !item.Descricao
                                                            ? _c(
                                                                "v-expansion-panel",
                                                                [
                                                                  _c(
                                                                    "v-expansion-panel-header",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            key
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-expansion-panel-content",
                                                                    _vm._l(
                                                                      item,
                                                                      function (
                                                                        subitem,
                                                                        key
                                                                      ) {
                                                                        return _c(
                                                                          "v-radio-group",
                                                                          {
                                                                            key: key,
                                                                            staticClass:
                                                                              "ma-0 pa-0",
                                                                            attrs:
                                                                              {
                                                                                "hide-details":
                                                                                  "",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .form
                                                                                    .motivoNaoVenda,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.form,
                                                                                      "motivoNaoVenda",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "form.motivoNaoVenda",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      subitem.Descricao,
                                                                                    label:
                                                                                      subitem.Descricao,
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "title mt-3" },
                                    [
                                      _c("strong", [
                                        _vm._v("Número da proposta"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "card-text" },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          "Insira o número da proposta fechada para vincularmos as informações do cliente com a sua venda:"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-0 mb-5 mx-3",
                                      attrs: { row: "", "hide-details": "" },
                                      on: {
                                        change: function ($event) {
                                          _vm.form.nrProposta = null
                                        },
                                      },
                                      model: {
                                        value: _vm.form.flagPesquisarProposta,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "flagPesquisarProposta",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.flagPesquisarProposta",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Pesquisar proposta",
                                          value: true,
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Inserir manualmente",
                                          value: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "validation-observer",
                                    { ref: "form" },
                                    [
                                      _vm.form.flagPesquisarProposta
                                        ? _c("validation-provider", {
                                            attrs: {
                                              name: "proposta",
                                              vid: "proposta",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c(
                                                        "base-autocomplete-field",
                                                        {
                                                          attrs: {
                                                            items:
                                                              _vm.propostaList,
                                                            rules: "required",
                                                            id: "proposta",
                                                            name: "proposta",
                                                            "item-text":
                                                              "nrProposta",
                                                            "sub-text":
                                                              "titular",
                                                            "item-value":
                                                              "nrProposta",
                                                            outlined: "",
                                                            errors: errors,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .nrProposta,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "nrProposta",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.nrProposta",
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1496204156
                                            ),
                                          })
                                        : _c("validation-provider", {
                                            attrs: {
                                              name: "proposta",
                                              vid: "proposta",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        name: "proposta",
                                                        type: "number",
                                                        label: "Proposta",
                                                        "error-messages":
                                                          errors,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.nrProposta,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "nrProposta",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.nrProposta",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "my-5",
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right pa-5",
                          attrs: { cols: "12", sm: "6" },
                        },
                        [
                          _c("v-textarea", {
                            staticClass: "my-3",
                            attrs: {
                              outlined: "",
                              "auto-grow": "",
                              label: "Observação",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.form.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "observacao", $$v)
                              },
                              expression: "form.observacao",
                            },
                          }),
                          _c("v-card-title", [
                            _c("strong", [_vm._v("Avaliação do Lead")]),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "validation-observer",
                                    {
                                      ref: "avaliacao",
                                      staticClass: "mb-10",
                                      attrs: { autocomplete: "off" },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.submit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-rating", {
                                        staticClass: "text-center",
                                        attrs: {
                                          color: "blue",
                                          hover: "",
                                          length: "5",
                                          size: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? 32
                                            : 64,
                                        },
                                        on: { input: _vm.getAvaliacaoMotivos },
                                        model: {
                                          value: _vm.form.notaAvaliacao,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "notaAvaliacao",
                                              $$v
                                            )
                                          },
                                          expression: "form.notaAvaliacao",
                                        },
                                      }),
                                      _c("validation-provider", {
                                        ref: "textFieldProvider",
                                        attrs: { slim: "", rules: "required" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.form.notaAvaliacao,
                                                      expression:
                                                        "form.notaAvaliacao",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "hidden",
                                                    name: "nota",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.form.notaAvaliacao,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.form,
                                                        "notaAvaliacao",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "v-messages theme--light error--text text-center",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm.form.motivosAvaliacao &&
                                      _vm.form.motivosAvaliacao.length
                                        ? _c("validation-provider", {
                                            attrs: {
                                              name: "tratamento",
                                              vid: "tratamento",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c(
                                                        "v-radio-group",
                                                        {
                                                          staticClass:
                                                            "px-14 d-flex mt-0",
                                                          attrs: {
                                                            "error-messages":
                                                              errors,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .descricaoAvaliacao,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "descricaoAvaliacao",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.descricaoAvaliacao",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.form
                                                            .motivosAvaliacao,
                                                          function (
                                                            motivoAvaliacao,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "v-list-item-content",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "pl-4 align-self-flex-start",
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c("v-radio", {
                                                                  attrs: {
                                                                    value:
                                                                      motivoAvaliacao.descricao,
                                                                    label:
                                                                      motivoAvaliacao.descricao,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeMotivo(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2296458352
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.showJustificativa
                                        ? _c("base-text-field", {
                                            attrs: {
                                              mode: "aggressive",
                                              rules: "required",
                                              id: "justificativa",
                                              name: "Justificativa",
                                              label: "Justificativa *",
                                              clearable: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.justificativaAvaliacao,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "justificativaAvaliacao",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.justificativaAvaliacao",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              class: _vm.$vuetify.breakpoint.smAndUp
                                ? "margin-buttons-finalizacao"
                                : null,
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3",
                                  attrs: { color: "primary", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showFinishedLeadModal = false
                                    },
                                  },
                                },
                                [_vm._v(" Voltar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.finalizarLead()
                                    },
                                  },
                                },
                                [_vm._v(" Finalizar o lead ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1050" },
          on: {
            "click:outside": _vm.close,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.close.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showDetailModal,
            callback: function ($$v) {
              _vm.showDetailModal = $$v
            },
            expression: "showDetailModal",
          },
        },
        [
          !_vm.showFinishedLeadModal
            ? _c(
                "v-card",
                { staticClass: "card pa-3" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-row",
                        [
                          _vm.tipoTab == "ativos"
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "1" },
                                },
                                [
                                  _c("v-list-item-avatar", {
                                    class: {
                                      "term modal": true,
                                      "vinte-cinco": _vm.form.tipoLead == "25%",
                                      cinquenta: _vm.form.tipoLead == "50%",
                                      "setenta-cinco":
                                        _vm.form.tipoLead == "75%",
                                      cem: _vm.form.tipoLead == "100%",
                                    },
                                    attrs: { tile: "", size: "15" },
                                  }),
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c(
                                        "span",
                                        { staticClass: "tooltip-term-text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.tipoLead == "0%" ||
                                                  _vm.form.tipoLead == "25%"
                                                  ? "Lead Frio"
                                                  : "Lead Quente"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              class: _vm.tipoTab == "ativos" ? "pl-0" : "pl-7",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "10" } },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          class: {
                                            "title-modal pa-0": true,
                                            "ml-3":
                                              _vm.tipoTab == "ativos" &&
                                              _vm.$vuetify.breakpoint.xs,
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.form.nome)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.tipoTab == "ativos" &&
                                  _vm.form.possuiAlerta
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { "align-self": "center" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tooltip-fire" },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "red" } },
                                                [_vm._v("mdi-fire")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "tooltip-fire-text",
                                                },
                                                [
                                                  _vm._v(
                                                    " Ligue agora! O cliente fez uma nova simulação! "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      class: {
                                        "subtitle-modal pt-2 pl-0": true,
                                        "ml-3":
                                          _vm.tipoTab == "ativos" &&
                                          _vm.$vuetify.breakpoint.xs,
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          "Segmentação: " +
                                            _vm._s(_vm.form.segmentacao)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class: {
                                    "mt-3": true,
                                    "ml-3":
                                      _vm.tipoTab == "ativos" &&
                                      _vm.$vuetify.breakpoint.xs,
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.form.telefoneList,
                                    function (item, key) {
                                      return _c(
                                        "p",
                                        { key: "M" + key },
                                        [
                                          _c("strong", [_vm._v(" Telefone: ")]),
                                          item.tipo === "Celular"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "green darken-2",
                                                  },
                                                },
                                                [_vm._v("mdi-whatsapp")]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "grey darken-2",
                                                  },
                                                },
                                                [_vm._v("mdi-phone")]
                                              ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "pl-0 text-decoration-none black--text text--lighter-1",
                                              attrs: {
                                                href:
                                                  _vm.linkTelefone(item.tipo) +
                                                  (
                                                    item.ddd + item.numero
                                                  ).replace(/[^\d]+/g, ""),
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(item.ddd) +
                                                  ") " +
                                                  _vm._s(item.numero) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._l(
                                    _vm.form.emailList,
                                    function (item, key) {
                                      return _c(
                                        "p",
                                        { key: "N" + key },
                                        [
                                          _c("strong", [_vm._v(" E-mail: ")]),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "grey darken-2",
                                              },
                                            },
                                            [_vm._v("mdi-email")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "pl-1 text-caption text-decoration-none black--text text--lighter-1",
                                              attrs: {
                                                href: `mailto:${item.email}`,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.email))]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm.form.local
                                    ? _c("p", [
                                        _c("strong", [_vm._v("Cidade/UF:")]),
                                        _vm._v(" " + _vm._s(_vm.form.local)),
                                      ])
                                    : _vm._e(),
                                  _vm.form.dataNascimento
                                    ? _c("p", [
                                        _c("strong", [
                                          _vm._v("Data de nascimento:"),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(_vm.form.dataNascimento)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.form.profissao
                                    ? _c("p", [
                                        _c("strong", [_vm._v("Profissão:")]),
                                        _vm._v(
                                          " " + _vm._s(_vm.form.profissao)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.form.operadora
                                    ? _c("p", [
                                        _c("strong", [_vm._v("Operadora:")]),
                                        _vm._v(
                                          " " + _vm._s(_vm.form.operadora)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.form.origem
                                    ? _c("p", [
                                        _c("strong", [_vm._v("Origem:")]),
                                        _vm._v(" " + _vm._s(_vm.form.origem)),
                                      ])
                                    : _vm._e(),
                                  _vm.form.plano
                                    ? _c("p", [
                                        _c("strong", [_vm._v("Plano:")]),
                                        _vm._v(" " + _vm._s(_vm.form.plano)),
                                      ])
                                    : _vm._e(),
                                  _vm.form.qntVidas
                                    ? _c("p", [
                                        _c("strong", [
                                          _vm._v("Quantidade de vidas:"),
                                        ]),
                                        _vm._v(" " + _vm._s(_vm.form.qntVidas)),
                                      ])
                                    : _vm._e(),
                                  _vm.form.nomeEmpresa
                                    ? _c("p", [
                                        _c("strong", [
                                          _vm._v("Nome da empresa:"),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(_vm.form.nomeEmpresa)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.form.cnpjEmpresa
                                    ? _c("p", [
                                        _c("strong", [
                                          _vm._v("CNPJ da empresa:"),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(_vm.form.cnpjEmpresa)
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm.tipoTab == "ativos" &&
                              _vm.$vuetify.breakpoint.smAndDown
                                ? _c(
                                    "p",
                                    {
                                      class: {
                                        "margin-tipo-lead": true,
                                        "ml-3": _vm.$vuetify.breakpoint.xs,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.tipoLead == "0%" ||
                                              _vm.form.tipoLead == "25%"
                                              ? "Lead Frio"
                                              : "Lead Quente"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                {
                                  class: {
                                    "ml-0":
                                      _vm.tipoTab == "ativos" &&
                                      _vm.$vuetify.breakpoint.xs,
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        { attrs: { accordion: "", flat: "" } },
                                        [
                                          _c(
                                            "v-expansion-panel",
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                { staticClass: "pl-0" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Visualizar histórico"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-expansion-panels",
                                                    {
                                                      attrs: {
                                                        accordion: "",
                                                        flat: "",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.form.historico,
                                                      function (item, i) {
                                                        return _c(
                                                          "v-expansion-panel",
                                                          { key: i },
                                                          [
                                                            _c(
                                                              "v-expansion-panel-header",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formataData(
                                                                        item[0]
                                                                          .dataHoraCriacao
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-expansion-panel-content",
                                                              _vm._l(
                                                                item,
                                                                function (
                                                                  subitem,
                                                                  ii
                                                                ) {
                                                                  return _c(
                                                                    "p",
                                                                    {
                                                                      key: ii,
                                                                      staticClass:
                                                                        "mb-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            subitem.descricao
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", {
                            staticClass: "my-5",
                            attrs: { vertical: "" },
                          }),
                          _c(
                            "v-col",
                            { staticClass: "mt-5 pa-5" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.opcoesMoverLead,
                                  "item-text": "label",
                                  label: "Mover lead para:",
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.form.opcaoInteracao = null
                                  },
                                },
                                model: {
                                  value: _vm.form.opcaoMoverLead,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "opcaoMoverLead", $$v)
                                  },
                                  expression: "form.opcaoMoverLead",
                                },
                              }),
                              _vm.form.opcaoMoverLead == "Sem sucesso" ||
                              _vm.form.opcaoMoverLead == "Em negociação"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "title-modal pa-0 mb-3",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              "Qual a interação você teve com o cliente?"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-list",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.form.opcaoMoverLead ==
                                                "Sem sucesso",
                                              expression:
                                                "form.opcaoMoverLead == 'Sem sucesso'",
                                            },
                                          ],
                                          staticClass: "transparent mx-n4",
                                        },
                                        [
                                          _c(
                                            "v-expansion-panels",
                                            {
                                              attrs: {
                                                accordion: "",
                                                flat: "",
                                                multiple: "",
                                                value: _vm.checkTratamentoSel(
                                                  _vm.form.tratamentosSemSucesso
                                                ),
                                              },
                                            },
                                            _vm._l(
                                              _vm.form.tratamentosSemSucesso,
                                              function (tratamento, key) {
                                                return _c(
                                                  "v-expansion-panel",
                                                  { key: key },
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(key) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      _vm._l(
                                                        tratamento,
                                                        function (item, key) {
                                                          return _c(
                                                            "v-expansion-panels",
                                                            {
                                                              key: key,
                                                              attrs: {
                                                                accordion: "",
                                                                flat: "",
                                                                value: 0,
                                                              },
                                                            },
                                                            [
                                                              item.Descricao
                                                                ? _c(
                                                                    "v-expansion-panel",
                                                                    [
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          staticClass:
                                                                            "ma-0 pa-0",
                                                                          attrs:
                                                                            {
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .opcaoInteracao,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "opcaoInteracao",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.opcaoInteracao",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    item.Descricao,
                                                                                  label:
                                                                                    item.Descricao,
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              !item.Descricao
                                                                ? _c(
                                                                    "v-expansion-panel",
                                                                    [
                                                                      _c(
                                                                        "v-expansion-panel-header",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                key
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-expansion-panel-content",
                                                                        _vm._l(
                                                                          item,
                                                                          function (
                                                                            subitem,
                                                                            key
                                                                          ) {
                                                                            return _c(
                                                                              "v-radio-group",
                                                                              {
                                                                                key: key,
                                                                                staticClass:
                                                                                  "ma-0 pa-0",
                                                                                attrs:
                                                                                  {
                                                                                    "hide-details":
                                                                                      "",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .form
                                                                                        .opcaoInteracao,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.form,
                                                                                          "opcaoInteracao",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "form.opcaoInteracao",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-radio",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          subitem.Descricao,
                                                                                        label:
                                                                                          subitem.Descricao,
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.form.opcaoMoverLead ==
                                                "Em negociação",
                                              expression:
                                                "form.opcaoMoverLead == 'Em negociação'",
                                            },
                                          ],
                                          staticClass: "transparent mx-n4",
                                        },
                                        [
                                          _c(
                                            "v-expansion-panels",
                                            {
                                              attrs: {
                                                accordion: "",
                                                flat: "",
                                                multiple: "",
                                                value: _vm.checkTratamentoSel(
                                                  _vm.form.tratamentosNegociacao
                                                ),
                                              },
                                            },
                                            _vm._l(
                                              _vm.form.tratamentosNegociacao,
                                              function (tratamento, key) {
                                                return _c(
                                                  "v-expansion-panel",
                                                  { key: key },
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(key) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      _vm._l(
                                                        tratamento,
                                                        function (item, key) {
                                                          return _c(
                                                            "v-expansion-panels",
                                                            {
                                                              key: key,
                                                              attrs: {
                                                                accordion: "",
                                                                flat: "",
                                                                value: 0,
                                                              },
                                                            },
                                                            [
                                                              item.Descricao
                                                                ? _c(
                                                                    "v-expansion-panel",
                                                                    [
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          staticClass:
                                                                            "ma-0 pa-0",
                                                                          attrs:
                                                                            {
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .opcaoInteracao,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "opcaoInteracao",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.opcaoInteracao",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    item.Descricao,
                                                                                  label:
                                                                                    item.Descricao,
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              !item.Descricao
                                                                ? _c(
                                                                    "v-expansion-panel",
                                                                    [
                                                                      _c(
                                                                        "v-expansion-panel-header",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                key
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-expansion-panel-content",
                                                                        _vm._l(
                                                                          item,
                                                                          function (
                                                                            subitem,
                                                                            key
                                                                          ) {
                                                                            return _c(
                                                                              "v-radio-group",
                                                                              {
                                                                                key: key,
                                                                                staticClass:
                                                                                  "ma-0 pa-0",
                                                                                attrs:
                                                                                  {
                                                                                    "hide-details":
                                                                                      "",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .form
                                                                                        .opcaoInteracao,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.form,
                                                                                          "opcaoInteracao",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "form.opcaoInteracao",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-radio",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          subitem.Descricao,
                                                                                        label:
                                                                                          subitem.Descricao,
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.opcaoMoverLead == "Sem sucesso" ||
                              _vm.form.opcaoMoverLead == "Em negociação"
                                ? _c("v-textarea", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      outlined: "",
                                      "auto-grow": "",
                                      rows: "3",
                                      label: "Observação",
                                    },
                                    model: {
                                      value: _vm.form.observacao,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "observacao", $$v)
                                      },
                                      expression: "form.observacao",
                                    },
                                  })
                                : _vm._e(),
                              _vm.form.permiteAgendamento
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "title-modal pa-0" },
                                        [_c("strong", [_vm._v("Agendamentos")])]
                                      ),
                                      _c("p", { staticClass: "my-3" }, [
                                        _vm._v(
                                          " Esse evento será salvo automaticamente no seu calendário. "
                                        ),
                                      ]),
                                      _c(
                                        "validation-observer",
                                        { ref: "form" },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "agendamento",
                                              vid: "agendamento",
                                              rules: !_vm.form
                                                .flagSemAgendamento
                                                ? "required"
                                                : null,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-datetime-picker", {
                                                        attrs: {
                                                          datetime:
                                                            _vm.dataHoraAgendamentoFormatado(
                                                              _vm.form
                                                                .dataHoraAgendamento
                                                            ),
                                                          disabled:
                                                            _vm.form
                                                              .flagSemAgendamento,
                                                          label: "Agendamento",
                                                          "no-title": "",
                                                          "date-format":
                                                            "dd/MM/yyyy",
                                                          errors: errors,
                                                        },
                                                        on: {
                                                          ok: function (
                                                            $event
                                                          ) {
                                                            return _vm.ok(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4191672274
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-checkbox", {
                                        staticClass: "mt-0 pt-0",
                                        attrs: {
                                          label:
                                            "Não quero fazer um agendamento agora",
                                          color: "primary",
                                        },
                                        model: {
                                          value: _vm.form.flagSemAgendamento,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "flagSemAgendamento",
                                              $$v
                                            )
                                          },
                                          expression: "form.flagSemAgendamento",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.flagExpirando
                                ? _c("v-divider", { staticClass: "mb-5" })
                                : _vm._e(),
                              _vm.form.flagExpirando
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        type: "warning",
                                        icon: false,
                                        color: "#FFFD99",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "black" } },
                                        [_vm._v("mdi-alert")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "alert mx-2" },
                                        [
                                          _vm._v(
                                            "Expira em: " +
                                              _vm._s(_vm.form.expiracao)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$vuetify.breakpoint.lgAndUp
                                ? _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "white--text mr-4",
                                          attrs: { color: "#FFB600" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.criarProposta(
                                                _vm.form.idLead
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Criar proposta ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            color: "primary",
                                            outlined: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showFinishedLeadModal = true
                                            },
                                          },
                                        },
                                        [_vm._v(" Finalizar o lead ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: { click: _vm.salvar },
                                        },
                                        [_vm._v(" Salvar ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "white--text",
                                          attrs: {
                                            color: "#FFB600",
                                            block: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.criarProposta(
                                                _vm.form.idLead
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Criar proposta ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-3",
                                          attrs: {
                                            color: "primary",
                                            block: "",
                                            outlined: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showFinishedLeadModal = true
                                            },
                                          },
                                        },
                                        [_vm._v(" Finalizar o lead ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-3",
                                          attrs: {
                                            color: "primary",
                                            block: "",
                                          },
                                          on: { click: _vm.salvar },
                                        },
                                        [_vm._v(" Salvar ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "error", block: "" },
                                          on: { click: _vm.close },
                                        },
                                        [_vm._v(" Fechar ")]
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                { staticClass: "card pa-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-5", attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-card-title", { staticClass: "title" }, [
                            _c("strong", [_vm._v("Conseguiu fechar a venda?")]),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0 mx-3",
                              attrs: { row: "", "hide-details": "" },
                              model: {
                                value: _vm.form.flagVendaFechada,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "flagVendaFechada", $$v)
                                },
                                expression: "form.flagVendaFechada",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Sim", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Não", value: false },
                              }),
                            ],
                            1
                          ),
                          !_vm.form.flagVendaFechada
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "title mt-3" },
                                    [_c("strong", [_vm._v("Qual o motivo?")])]
                                  ),
                                  _c(
                                    "v-list",
                                    { staticClass: "transparent mx-n4" },
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        { attrs: { accordion: "", flat: "" } },
                                        _vm._l(
                                          _vm.form.motivos,
                                          function (motivo, key) {
                                            return _c(
                                              "v-expansion-panel",
                                              { key: key },
                                              [
                                                _c("v-expansion-panel-header", [
                                                  _vm._v(
                                                    " " + _vm._s(key) + " "
                                                  ),
                                                ]),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  _vm._l(
                                                    motivo,
                                                    function (item, key) {
                                                      return _c(
                                                        "v-expansion-panels",
                                                        {
                                                          key: key,
                                                          attrs: {
                                                            accordion: "",
                                                            flat: "",
                                                          },
                                                        },
                                                        [
                                                          item.Descricao
                                                            ? _c(
                                                                "v-expansion-panel",
                                                                [
                                                                  _c(
                                                                    "v-radio-group",
                                                                    {
                                                                      staticClass:
                                                                        "ma-0 pa-0",
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .motivoNaoVenda,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.form,
                                                                              "motivoNaoVenda",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.motivoNaoVenda",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                item.Descricao,
                                                                              label:
                                                                                item.Descricao,
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          !item.Descricao
                                                            ? _c(
                                                                "v-expansion-panel",
                                                                [
                                                                  _c(
                                                                    "v-expansion-panel-header",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            key
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-expansion-panel-content",
                                                                    _vm._l(
                                                                      item,
                                                                      function (
                                                                        subitem,
                                                                        key
                                                                      ) {
                                                                        return _c(
                                                                          "v-radio-group",
                                                                          {
                                                                            key: key,
                                                                            staticClass:
                                                                              "ma-0 pa-0",
                                                                            attrs:
                                                                              {
                                                                                "hide-details":
                                                                                  "",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .form
                                                                                    .motivoNaoVenda,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.form,
                                                                                      "motivoNaoVenda",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "form.motivoNaoVenda",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      subitem.Descricao,
                                                                                    label:
                                                                                      subitem.Descricao,
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "title mt-3" },
                                    [
                                      _c("strong", [
                                        _vm._v("Número da proposta"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "card-text" },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          "Insira o número da proposta fechada para vincularmos as informações do cliente com a sua venda:"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-0 mb-5 mx-3",
                                      attrs: { row: "", "hide-details": "" },
                                      on: {
                                        change: function ($event) {
                                          _vm.form.nrProposta = null
                                        },
                                      },
                                      model: {
                                        value: _vm.form.flagPesquisarProposta,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "flagPesquisarProposta",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.flagPesquisarProposta",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Pesquisar proposta",
                                          value: true,
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Inserir manualmente",
                                          value: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "validation-observer",
                                    { ref: "form" },
                                    [
                                      _vm.form.flagPesquisarProposta
                                        ? _c("validation-provider", {
                                            attrs: {
                                              name: "proposta",
                                              vid: "proposta",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c(
                                                        "base-autocomplete-field",
                                                        {
                                                          attrs: {
                                                            items:
                                                              _vm.propostaList,
                                                            rules: "required",
                                                            id: "proposta",
                                                            name: "proposta",
                                                            "item-text":
                                                              "nrProposta",
                                                            "sub-text":
                                                              "titular",
                                                            "item-value":
                                                              "nrProposta",
                                                            outlined: "",
                                                            errors: errors,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .nrProposta,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "nrProposta",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.nrProposta",
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1496204156
                                            ),
                                          })
                                        : _c("validation-provider", {
                                            attrs: {
                                              name: "proposta",
                                              vid: "proposta",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        name: "proposta",
                                                        type: "number",
                                                        label: "Proposta",
                                                        "error-messages":
                                                          errors,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.nrProposta,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "nrProposta",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.nrProposta",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "my-5",
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right pa-5",
                          attrs: { cols: "12", sm: "6" },
                        },
                        [
                          _c("v-textarea", {
                            staticClass: "my-3",
                            attrs: {
                              outlined: "",
                              "auto-grow": "",
                              label: "Observação",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.form.observacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "observacao", $$v)
                              },
                              expression: "form.observacao",
                            },
                          }),
                          _c("v-card-title", [
                            _c("strong", [_vm._v("Avaliação do Lead")]),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "validation-observer",
                                    {
                                      ref: "avaliacao",
                                      staticClass: "mb-10",
                                      attrs: { autocomplete: "off" },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.submit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-rating", {
                                        staticClass: "text-center",
                                        attrs: {
                                          color: "blue",
                                          hover: "",
                                          length: "5",
                                          size: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? 32
                                            : 64,
                                        },
                                        on: { input: _vm.getAvaliacaoMotivos },
                                        model: {
                                          value: _vm.form.notaAvaliacao,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "notaAvaliacao",
                                              $$v
                                            )
                                          },
                                          expression: "form.notaAvaliacao",
                                        },
                                      }),
                                      _c("validation-provider", {
                                        ref: "textFieldProvider",
                                        attrs: { slim: "", rules: "required" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.form.notaAvaliacao,
                                                      expression:
                                                        "form.notaAvaliacao",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "hidden",
                                                    name: "nota",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.form.notaAvaliacao,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.form,
                                                        "notaAvaliacao",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "v-messages theme--light error--text text-center",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm.form.motivosAvaliacao &&
                                      _vm.form.motivosAvaliacao.length
                                        ? _c("validation-provider", {
                                            attrs: {
                                              name: "tratamento",
                                              vid: "tratamento",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c(
                                                        "v-radio-group",
                                                        {
                                                          staticClass:
                                                            "px-14 d-flex mt-0",
                                                          attrs: {
                                                            "error-messages":
                                                              errors,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .descricaoAvaliacao,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "descricaoAvaliacao",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.descricaoAvaliacao",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.form
                                                            .motivosAvaliacao,
                                                          function (
                                                            motivoAvaliacao,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "v-list-item-content",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "pl-4 align-self-flex-start",
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c("v-radio", {
                                                                  attrs: {
                                                                    value:
                                                                      motivoAvaliacao.descricao,
                                                                    label:
                                                                      motivoAvaliacao.descricao,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeMotivo(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2296458352
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.showJustificativa
                                        ? _c("base-text-field", {
                                            attrs: {
                                              mode: "aggressive",
                                              rules: "required",
                                              id: "justificativa",
                                              name: "Justificativa",
                                              label: "Justificativa *",
                                              clearable: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.justificativaAvaliacao,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "justificativaAvaliacao",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.justificativaAvaliacao",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              class: _vm.$vuetify.breakpoint.smAndUp
                                ? "margin-buttons-finalizacao"
                                : null,
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3",
                                  attrs: { color: "primary", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showFinishedLeadModal = false
                                    },
                                  },
                                },
                                [_vm._v(" Voltar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.finalizarLead()
                                    },
                                  },
                                },
                                [_vm._v(" Finalizar o lead ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }