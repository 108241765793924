var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.handleClickOutside,
              expression: "handleClickOutside",
            },
          ],
          staticClass: "sidebar",
          attrs: {
            app: "",
            right: "",
            temporary: "",
            "disable-resize-watcher": "",
          },
          model: {
            value: _vm.drawerComp,
            callback: function ($$v) {
              _vm.drawerComp = $$v
            },
            expression: "drawerComp",
          },
        },
        [
          _c(
            "div",
            { staticClass: "sidebar-content" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("div", { staticClass: "sidebar-title" }, [
                      _vm._v(" Filtros "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-limpar text-none pa-0",
                          attrs: { text: "" },
                          on: { click: _vm.limpar },
                        },
                        [_vm._v(" Limpar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.tab === 0 || _vm.tab === 1
                ? _c(
                    "div",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [_c("span", [_vm._v(" Leads sem agendamento ")])]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "#14CB2D",
                                  dense: "",
                                  inset: "",
                                },
                                model: {
                                  value: _vm.form.leadsSemAgendamento,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "leadsSemAgendamento",
                                      $$v
                                    )
                                  },
                                  expression: "form.leadsSemAgendamento",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [_c("span", [_vm._v(" Data de recebimento ")])]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "#14CB2D",
                                  dense: "",
                                  disabled: _vm.form.dataAtualizacao,
                                  inset: "",
                                },
                                model: {
                                  value: _vm.form.dataRecebimento,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dataRecebimento", $$v)
                                  },
                                  expression: "form.dataRecebimento",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.dataRecebimento
                        ? _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _vm._v(" De/até: "),
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialogRecebimentoInicioFim",
                                      attrs: {
                                        "return-value":
                                          _vm.form.dataRecebimentoInicioFim,
                                        persistent: "",
                                        width: "290px",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataRecebimentoInicioFim",
                                            $event
                                          )
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataRecebimentoInicioFim",
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label: "Datas",
                                                          "prepend-inner-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                          clearable: "",
                                                          "clear-icon-cb":
                                                            _vm.clearDataRecebimentoInicioFim,
                                                          solo: "",
                                                          disabled:
                                                            !_vm.form
                                                              .dataRecebimento ||
                                                            _vm.form
                                                              .dataAtualizacao,
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dataRecebimentoInicioFimFormatada,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.dataRecebimentoInicioFimFormatada =
                                                              $$v
                                                          },
                                                          expression:
                                                            "dataRecebimentoInicioFimFormatada",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2656456602
                                      ),
                                      model: {
                                        value:
                                          _vm.modalDataRecebimentoInicioFim,
                                        callback: function ($$v) {
                                          _vm.modalDataRecebimentoInicioFim =
                                            $$v
                                        },
                                        expression:
                                          "modalDataRecebimentoInicioFim",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            scrollable: "",
                                            range: "",
                                            locale: "pt",
                                            "selected-items-text":
                                              "2 selecionadas",
                                          },
                                          model: {
                                            value:
                                              _vm.form.dataRecebimentoInicioFim,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "dataRecebimentoInicioFim",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.dataRecebimentoInicioFim",
                                          },
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.clearDataRecebimentoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Limpar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.modalDataRecebimentoInicioFim = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancelar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.applyDataRecebimentoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Aplicar ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [_c("span", [_vm._v(" Data de atualização ")])]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "#14CB2D",
                                  dense: "",
                                  disabled: _vm.form.dataRecebimento,
                                  inset: "",
                                },
                                model: {
                                  value: _vm.form.dataAtualizacao,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dataAtualizacao", $$v)
                                  },
                                  expression: "form.dataAtualizacao",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.dataAtualizacao
                        ? _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _vm._v(" De/até: "),
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialogAtualizacaoInicioFim",
                                      attrs: {
                                        "return-value":
                                          _vm.form.dataAtualizacaoInicioFim,
                                        persistent: "",
                                        width: "290px",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataAtualizacaoInicioFim",
                                            $event
                                          )
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataAtualizacaoInicioFim",
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label: "Datas",
                                                          "prepend-inner-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                          clearable: "",
                                                          "clear-icon-cb":
                                                            _vm.clearDataAtualizacaoInicioFim,
                                                          solo: "",
                                                          disabled:
                                                            !_vm.form
                                                              .dataAtualizacao ||
                                                            _vm.form
                                                              .dataRecebimento,
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dataAtualizacaoInicioFimFormatada,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.dataAtualizacaoInicioFimFormatada =
                                                              $$v
                                                          },
                                                          expression:
                                                            "dataAtualizacaoInicioFimFormatada",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1390493210
                                      ),
                                      model: {
                                        value:
                                          _vm.modalDataAtualizacaoInicioFim,
                                        callback: function ($$v) {
                                          _vm.modalDataAtualizacaoInicioFim =
                                            $$v
                                        },
                                        expression:
                                          "modalDataAtualizacaoInicioFim",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            scrollable: "",
                                            range: "",
                                            locale: "pt",
                                            "selected-items-text":
                                              "2 selecionadas",
                                          },
                                          model: {
                                            value:
                                              _vm.form.dataAtualizacaoInicioFim,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "dataAtualizacaoInicioFim",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.dataAtualizacaoInicioFim",
                                          },
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.clearDataAtualizacaoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Limpar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.modalDataAtualizacaoInicioFim = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancelar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.applyDataAtualizacaoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Aplicar ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [_c("span", [_vm._v(" Cotação enviada ")])]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "#14CB2D",
                                  dense: "",
                                  inset: "",
                                },
                                model: {
                                  value: _vm.form.cotacaoEnviada,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cotacaoEnviada", $$v)
                                  },
                                  expression: "form.cotacaoEnviada",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [_c("span", [_vm._v(" Propostas enviadas ")])]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: { color: "green", dense: "", inset: "" },
                                model: {
                                  value: _vm.form.propostasEnviadas,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "propostasEnviadas", $$v)
                                  },
                                  expression: "form.propostasEnviadas",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [
                              _c("span", [
                                _vm._v(" Negociação iniciada no Hotline "),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: { color: "green", dense: "", inset: "" },
                                model: {
                                  value: _vm.form.negociacaoHotline,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "negociacaoHotline", $$v)
                                  },
                                  expression: "form.negociacaoHotline",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [
                              _c("span", [
                                _vm._v(" Negociação iniciada no Qualiatende "),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: { color: "green", dense: "", inset: "" },
                                model: {
                                  value: _vm.form.negociacaoQualiatende,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "negociacaoQualiatende",
                                      $$v
                                    )
                                  },
                                  expression: "form.negociacaoQualiatende",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-5" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", "x-large": "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.applyFilters("default")
                            },
                          },
                        },
                        [_vm._v(" FILTRAR (" + _vm._s(_vm.countFilters) + ") ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === 2
                ? _c(
                    "div",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [_c("span", [_vm._v(" Data de recebimento ")])]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "#14CB2D",
                                  dense: "",
                                  disabled: _vm.form.dataFinalizacao,
                                  inset: "",
                                },
                                model: {
                                  value: _vm.form.dataRecebimento,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dataRecebimento", $$v)
                                  },
                                  expression: "form.dataRecebimento",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.dataRecebimento
                        ? _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _vm._v(" De/até: "),
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialogRecebimentoInicioFim",
                                      attrs: {
                                        "return-value":
                                          _vm.form.dataRecebimentoInicioFim,
                                        persistent: "",
                                        width: "290px",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataRecebimentoInicioFim",
                                            $event
                                          )
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataRecebimentoInicioFim",
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label: "Datas",
                                                          "prepend-inner-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                          clearable: "",
                                                          "clear-icon-cb":
                                                            _vm.clearDataRecebimentoInicioFim,
                                                          solo: "",
                                                          disabled:
                                                            !_vm.form
                                                              .dataRecebimento ||
                                                            _vm.form
                                                              .dataFinalizacao,
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dataRecebimentoInicioFimFormatada,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.dataRecebimentoInicioFimFormatada =
                                                              $$v
                                                          },
                                                          expression:
                                                            "dataRecebimentoInicioFimFormatada",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3661949947
                                      ),
                                      model: {
                                        value:
                                          _vm.modalDataRecebimentoInicioFim,
                                        callback: function ($$v) {
                                          _vm.modalDataRecebimentoInicioFim =
                                            $$v
                                        },
                                        expression:
                                          "modalDataRecebimentoInicioFim",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            scrollable: "",
                                            range: "",
                                            locale: "pt",
                                            "selected-items-text":
                                              "2 selecionadas",
                                          },
                                          model: {
                                            value:
                                              _vm.form.dataRecebimentoInicioFim,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "dataRecebimentoInicioFim",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.dataRecebimentoInicioFim",
                                          },
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.clearDataRecebimentoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Limpar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.modalDataRecebimentoInicioFim = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancelar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.applyDataRecebimentoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Aplicar ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { "align-self": "center" },
                            },
                            [_c("span", [_vm._v(" Data de finalização ")])]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 py-2",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "#14CB2D",
                                  dense: "",
                                  disabled: _vm.form.dataRecebimento,
                                  inset: "",
                                },
                                model: {
                                  value: _vm.form.dataFinalizacao,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dataFinalizacao", $$v)
                                  },
                                  expression: "form.dataFinalizacao",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.dataFinalizacao
                        ? _c(
                            "v-row",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _vm._v(" De/até: "),
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialogFinalizacaoInicioFim",
                                      attrs: {
                                        "return-value":
                                          _vm.form.dataFinalizacaoInicioFim,
                                        persistent: "",
                                        width: "290px",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataFinalizacaoInicioFim",
                                            $event
                                          )
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "dataFinalizacaoInicioFim",
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label: "Datas",
                                                          "prepend-inner-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                          clearable: "",
                                                          "clear-icon-cb":
                                                            _vm.clearDataFinalizacaoInicioFim,
                                                          solo: "",
                                                          disabled:
                                                            !_vm.form
                                                              .dataFinalizacao ||
                                                            _vm.form
                                                              .dataRecebimento,
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dataFinalizacaoInicioFimFormatada,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.dataFinalizacaoInicioFimFormatada =
                                                              $$v
                                                          },
                                                          expression:
                                                            "dataFinalizacaoInicioFimFormatada",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1198327355
                                      ),
                                      model: {
                                        value:
                                          _vm.modalDataFinalizacaoInicioFim,
                                        callback: function ($$v) {
                                          _vm.modalDataFinalizacaoInicioFim =
                                            $$v
                                        },
                                        expression:
                                          "modalDataFinalizacaoInicioFim",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            scrollable: "",
                                            range: "",
                                            locale: "pt",
                                            "selected-items-text":
                                              "2 selecionadas",
                                          },
                                          model: {
                                            value:
                                              _vm.form.dataFinalizacaoInicioFim,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "dataFinalizacaoInicioFim",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.dataFinalizacaoInicioFim",
                                          },
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.clearDataFinalizacaoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Limpar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.modalDataFinalizacaoInicioFim = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancelar ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.applyDataFinalizacaoInicioFim,
                                              },
                                            },
                                            [_vm._v(" Aplicar ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider", { staticClass: "mb-5" }),
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", "x-large": "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.applyFilters("finalizados")
                            },
                          },
                        },
                        [_vm._v(" FILTRAR (" + _vm._s(_vm.countFilters) + ") ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }