var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.dialogWidth },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      disabled: _vm.disabled,
                      loading: _vm.loading,
                      label: _vm.label,
                      value: _vm.formattedDatetime,
                      "error-messages": _vm.errors,
                      readonly: "",
                      outlined: "",
                      "append-icon": "mdi-calendar",
                      color: "primary",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "progress",
                          fn: function () {
                            return [
                              _vm._t("progress", function () {
                                return [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      color: "primary",
                                      indeterminate: "",
                                      absolute: "",
                                      height: "2",
                                    },
                                  }),
                                ]
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.display,
        callback: function ($$v) {
          _vm.display = $$v
        },
        expression: "display",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "" },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    [
                      _vm._t("dateIcon", function () {
                        return [_c("v-icon", [_vm._v("mdi-calendar")])]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-tab-item",
                    { key: "calendar" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "container-agenda" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-date-picker",
                                    _vm._b(
                                      {
                                        staticClass: "pr-5",
                                        attrs: {
                                          locale: "pt",
                                          id: "startDate",
                                          "allowed-dates": _vm.allowedDates,
                                        },
                                        model: {
                                          value: _vm.date,
                                          callback: function ($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date",
                                        },
                                      },
                                      "v-date-picker",
                                      _vm.datePickerProps,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "clock" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("span", [_vm._v("Horas")]),
                                      _c("v-select", {
                                        attrs: {
                                          solo: "",
                                          items: _vm.timeHours,
                                          "return-object": "",
                                        },
                                        model: {
                                          value: _vm.time.hours,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.time, "hours", $$v)
                                          },
                                          expression: "time.hours",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("span", [_vm._v("Minutos")]),
                                      _c("v-select", {
                                        attrs: {
                                          solo: "",
                                          items: _vm.timeMinutes,
                                          "return-object": "",
                                        },
                                        model: {
                                          value: _vm.time.minutes,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.time, "minutes", $$v)
                                          },
                                          expression: "time.minutes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._t(
                "actions",
                function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "grey lighten-1", text: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.clearHandler.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.clearText))]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", text: "" },
                        on: { click: _vm.okHandler },
                      },
                      [_vm._v(_vm._s(_vm.okText))]
                    ),
                  ]
                },
                { parent: this }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }