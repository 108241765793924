var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "container", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mt-3 div-default" },
        [
          _c("div", { staticClass: "header mt-md-7 mr-md-3" }, [
            _vm._v(" Controle de Leads "),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "5", xl: "4" } },
            [
              _c("base-text-field", {
                attrs: {
                  id: "pesquisa",
                  name: "pesquisa",
                  label: "Pesquisar por nome, e-mail, telefone ou origem",
                  "icon-append": "mdi-magnify",
                  clearable: "",
                  outlined: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.pesquisa,
                  callback: function ($$v) {
                    _vm.pesquisa = $$v
                  },
                  expression: "pesquisa",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", outlined: "" },
                  on: {
                    click: function ($event) {
                      _vm.showFilters = !_vm.showFilters
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { size: "25", color: "primary" },
                    },
                    [_vm._v(" mdi-filter-variant ")]
                  ),
                  _vm._v(" FILTROS (" + _vm._s(_vm.countFilters) + ") "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "div-default" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "background-color": "#fff", color: "primary" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.colLeads, function (item, key) {
                      return _c(
                        "v-tab",
                        {
                          key: key,
                          staticClass: "text-capitalize width-100",
                          attrs: { disabled: _vm.loading },
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.tab == 0
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _vm._l(_vm.boolFilters, function (item, key) {
                    return _c(
                      "v-btn",
                      {
                        key: key,
                        staticClass: "btn-filter mr-2 my-3 my-md-1",
                        attrs: { color: "primary", rounded: "", height: "30" },
                      },
                      [
                        item.simpleKey && _vm.$vuetify.breakpoint.xs
                          ? _c(
                              "div",
                              [
                                _vm._v(" Ord.: "),
                                _c(
                                  "v-icon",
                                  { attrs: { size: "20", color: "white" } },
                                  [_vm._v(" " + _vm._s(item.icon) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.simpleKey) + " "),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(item.newKey))]),
                        _c(
                          "span",
                          {
                            staticClass: "btn-x ml-2",
                            on: {
                              click: function ($event) {
                                return _vm.onClickX(item.key)
                              },
                            },
                          },
                          [_vm._v(" x ")]
                        ),
                      ]
                    )
                  }),
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticClass: "loading",
                        attrs: { color: "primary", indeterminate: "" },
                      })
                    : _c("default-kanban", {
                        staticClass: "mt-5",
                        attrs: {
                          tipoTab: "ativos",
                          leads: _vm.filteredLeads.ativos,
                          defaultURL: _vm.defaultURL,
                          leadSelecionado: _vm.leadDefaultSelecionado,
                        },
                        on: {
                          "carrega-dados": _vm.carregaDados,
                          visualizado: _vm.changeFlagVisualizado,
                          modal: _vm.modal,
                          setOrdenacao: _vm.setOrdenacao,
                        },
                      }),
                ],
                2
              )
            : _vm._e(),
          _vm.tab == 1
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _vm._l(_vm.boolFilters, function (item, key) {
                    return _c(
                      "v-btn",
                      {
                        key: key,
                        staticClass: "btn-filter mr-2 my-3 my-md-1",
                        attrs: { color: "primary", rounded: "", height: "30" },
                      },
                      [
                        item.simpleKey && _vm.$vuetify.breakpoint.xs
                          ? _c(
                              "div",
                              [
                                _vm._v(" Ord.: "),
                                _c(
                                  "v-icon",
                                  { attrs: { size: "20", color: "white" } },
                                  [_vm._v(" " + _vm._s(item.icon) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.simpleKey) + " "),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(item.newKey))]),
                        _c(
                          "span",
                          {
                            staticClass: "btn-x ml-2",
                            on: {
                              click: function ($event) {
                                return _vm.onClickX(item.key)
                              },
                            },
                          },
                          [_vm._v(" x ")]
                        ),
                      ]
                    )
                  }),
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticClass: "loading",
                        attrs: { color: "primary", indeterminate: "" },
                      })
                    : _c("default-kanban", {
                        staticClass: "mt-5",
                        attrs: {
                          tipoTab: "mailing",
                          leads: _vm.filteredLeads.mailing,
                          defaultURL: _vm.defaultURL,
                          leadSelecionado: _vm.leadDefaultSelecionado,
                        },
                        on: {
                          "carrega-dados": _vm.carregaDados,
                          visualizado: _vm.changeFlagVisualizado,
                          modal: _vm.modal,
                          setOrdenacao: _vm.setOrdenacao,
                        },
                      }),
                ],
                2
              )
            : _vm._e(),
          _vm.tab == 2
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _vm._l(_vm.boolFilters, function (item, key) {
                    return _c(
                      "v-btn",
                      {
                        key: key,
                        staticClass: "btn-filter mr-2 my-3 my-md-1",
                        attrs: { color: "primary", rounded: "", height: "30" },
                      },
                      [
                        item.simpleKey && _vm.$vuetify.breakpoint.xs
                          ? _c(
                              "div",
                              [
                                _vm._v(" Ord.: "),
                                _c(
                                  "v-icon",
                                  { attrs: { size: "20", color: "white" } },
                                  [_vm._v(" " + _vm._s(item.icon) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.simpleKey) + " "),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(item.newKey))]),
                        _c(
                          "span",
                          {
                            staticClass: "btn-x ml-2",
                            on: {
                              click: function ($event) {
                                return _vm.onClickX(item.key)
                              },
                            },
                          },
                          [_vm._v(" x ")]
                        ),
                      ]
                    )
                  }),
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticClass: "loading",
                        attrs: { color: "primary", indeterminate: "" },
                      })
                    : _c("finalizados", {
                        staticClass: "mt-5",
                        attrs: {
                          leads: _vm.filteredLeads.finalizados,
                          defaultURL: _vm.defaultURL,
                          leadSelecionado: _vm.leadFinalizadoSelecionado,
                        },
                        on: {
                          "carrega-dados": _vm.carregaDados,
                          modal: _vm.modal,
                          setOrdenacao: _vm.setOrdenacao,
                        },
                      }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("filter-component", {
        attrs: {
          drawer: _vm.showFilters,
          form: _vm.filtersSideBar,
          tab: _vm.tab,
        },
        on: {
          "apply-filters": _vm.applyFilters,
          "close-filters": _vm.closeFilters,
          "clear-filters": _vm.clearFilters,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }