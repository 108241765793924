<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        :error-messages="errors"
        v-on="on"
        readonly
        outlined
        append-icon="mdi-calendar"
        color="primary"
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab" >
          <v-tab>
            <slot name="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-container class="container-agenda">
              <v-col cols="12">
                <v-row>
                  <v-date-picker
                    v-model="date"
                    v-bind="datePickerProps"
                    locale="pt"
                    class="pr-5"
                    id="startDate"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-row>
                <v-row class="clock">
                  <v-col cols="6">
                    <span>Horas</span>
                    <v-select
                      solo
                      :items="timeHours"
                      v-model="time.hours"
                      return-object
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <span>Minutos</span>
                    <v-select
                      solo
                      :items="timeMinutes"
                      v-model="time.minutes"
                      return-object
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
          <v-btn color="primary" text @click="okHandler">{{ okText }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parse } from 'date-fns';
import moment from 'moment';

const DEFAULT_DATE = '';
const DEFAULT_TIME = {};
const DEFAULT_TIME_STRING = "00:00:00";
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_DIALOG_WIDTH = 400;
const DEFAULT_CLEAR_TEXT = 'LIMPAR';
const DEFAULT_OK_TEXT = 'SALVAR';
export default {
  name: 'v-datetime-picker',
  model: {
    prop: 'datetime',
    event: 'input',
  },
  components: { },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: 'HH:mm',
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
    errors: {

    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateTimeFormat() {
      return `${this.dateFormat} ${this.timeFormat}`;
    },
    defaultDateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedDatetime() {
      return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : '';
    },
    formattedDatetimeEua() {
      return this.selectedDatetime ? format(this.selectedDatetime, this.defaultDateTimeFormat) : '';
    },
    selectedDatetime() {
      if (this.date && this.time && this.time.hours && this.time.minutes) {
        const timeString = this.convertObjectTimeInString();
        const datetimeString = `${this.date} ${timeString}`;
        return parse(datetimeString, this.defaultDateTimeFormat, new Date());
      }
      return null;
    },
    dateSelected() {
      return !this.date;
    },
    timeHours: {
      get() {
        const hours = [];
        const hoursAvailable = moment().format('HH');
        const maxHour = 24;
        if (!moment().isAfter(this.date)) {
          for (let cont = 0; cont <= maxHour; cont++) {
            if (cont.toString().length === 1) {
              hours.push(`0${cont.toString()}`);
            } else {
              hours.push(cont.toString());
            }
          }
        } else {
          for (let cont = parseInt(hoursAvailable, 10); cont <= maxHour; cont++) {
            if (cont.toString().length === 1) {
              hours.push(`0${cont.toString()}`);
            } else {
              hours.push(cont.toString());
            }
          }
        }

        return hours;
      },
      set(newValue) {
        this.timeHours = newValue;
      },
    },
    timeMinutes: {
      get() {
        const minutes = [];
        const minutesAndHour = moment().format('HH:mm').split(":");
        const maxMinutes = 59;

        if (this.time != null
        && this.time.hours != null
        && this.time.hours.toString() === minutesAndHour[0]
        && this.date === moment().format('YYYY-MM-DD')) {
          for (let cont = parseInt(minutesAndHour[1], 10); cont <= maxMinutes; cont++) {
            if (cont.toString().length === 1) {
              minutes.push(`0${cont.toString()}`);
            } else {
              minutes.push(cont.toString());
            }
          }
        } else {
          for (let cont = 0; cont <= maxMinutes; cont++) {
            if (cont.toString().length === 1) {
              minutes.push(`0${cont.toString()}`);
            } else {
              minutes.push(cont.toString());
            }
          }
        }

        return minutes;
      },
      set(newValue) {
        this.timeMinutes = newValue;
      },
    },
  },
  methods: {
    init() {
      this.time = {};
      if (!this.datetime) {
        return;
      }
      let initDateTime;
      if (typeof this.datetime === 'string' || this.datetime instanceof String) {
        initDateTime = parse(this.datetime, this.dateTimeFormat, new Date());
        this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
        this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
        const time = this.time.split(":");
        this.time = { hours: time[0], minutes: time[1] };
      }
    },
    okHandler() {
      this.resetPicker();
      this.$emit('ok', this.formattedDatetimeEua);
    },
    clearHandler() {
      this.date = DEFAULT_DATE;
      this.time = {};
      this.resetPicker();
      this.$emit('ok', null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    convertObjectTimeInString() {
      const { hours, minutes } = this.time;

      if (!Object.keys(this.time || {}).length) {
        return DEFAULT_TIME_STRING;
      }

      const time = hours.concat(`:${minutes}`);
      return time;
    },
    allowedDates(val) {
      const dt = moment().isBefore(val) || val === moment(new Date()).format('YYYY-MM-DD');
      return dt;
    },
  },
  watch: {
    datetime() {
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';

#startDate {
    width: 100%;
    float: left;

    @media (max-width: $media-sm) {
    width: 100%;
  }
}

.clock {
  width: 100%;
}

.container-agenda{
  height: 35em;
}

</style>
