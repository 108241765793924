<template>
  <div>
    <v-row class="d-none d-lg-flex">
      <v-col cols="12" lg="6">
        <div class="column pa-5">
          <v-row class="mb-3">
            <v-col cols="8">
              <h3 class="header vendidos">
                Vendidos
              </h3>
            </v-col>
            <v-col cols="4" align-self="start" class="text-right">
              <span class="count-leads mr-3"> Total: {{ countLeads.vendidos > 1 ? countLeads.vendidos + " leads" : countLeads.vendidos + " lead" }} </span>
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item>
                    <span class="ordenacao">
                      <strong>Ordenar por:</strong>
                    </span>
                  </v-list-item>
                  <v-list-item link v-for="(item, key) in ordenacao" :key="'A' + key" @click="ordenar(item.title, 'vendidos')">
                    <v-list-item-title class="ordenacao-item">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <div id="vendidos" class="overflow">
            <v-card v-for="(item, key) in leadsVendidos" :key="'B' + key" class="card click vendidos mx-auto mb-5 mr-2" :loading="item.loading" outlined @click="showDetails(item, key, 'vendidos')">
              <v-list-item three-line>
                <v-list-item-content>

                  <v-row>
                    <v-col cols="7" md="8" class="pb-0">
                      <span class="title">
                        {{ item.nome }}
                      </span>
                      <v-divider class="divider vendidos mb-4" />
                    </v-col>
                    <v-col cols="5" md="4" class="text-right mt-1" v-if="!!item.propostaGerada">
                      <v-btn text class="btn-reativar text-none pa-0" @click="onClickReativarLead()">
                        Reativar lead
                      </v-btn>
                    </v-col>
                  </v-row>
                  <p class="mb-3"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                  <p class="mb-3"><strong>E-mail:</strong> {{ item.emailList.length ? item.emailList[0].email : " -- " }}</p>
                  <p class="mb-0"><strong>Finalização:</strong> {{ formataData(item.dataHoraEncerramento) }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="column pa-5">
          <v-row class="mb-3">
            <v-col cols="8">
              <h3 class="header nao-vendidos">
                Não vendidos
              </h3>
            </v-col>
            <v-col cols="4" class="text-right">
              <span class="count-leads mr-3"> Total: {{ countLeads.naoVendidos > 1 ? countLeads.naoVendidos + " leads" : countLeads.naoVendidos + " lead" }} </span>
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item>
                    <span class="ordenacao">
                      <strong>Ordenar por:</strong>
                    </span>
                  </v-list-item>
                  <v-list-item link v-for="(item, key) in ordenacao" :key="'C' + key" @click="ordenar(item.title, 'naoVendidos')">
                    <v-list-item-title class="ordenacao-item">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <div id="naoVendidos" class="overflow">
            <v-card
              v-for="(item, key) in leadsNaoVendidos"
              :key="'D' + key"
              class="card click nao-vendidos mx-auto mb-5 mr-2"
              :loading="item.loading"
              outlined
              @click="showDetails(item, key, 'nao-vendidos')"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-row>
                    <v-col cols="7" md="8" class="pb-0">
                      <span class="title">
                        {{ item.nome }}
                      </span>
                      <v-divider class="divider nao-vendidos mb-4" />
                    </v-col>
                    <v-col cols="5" md="4" class="text-right mt-1" v-if="item.motivoNaoVenda !== 'Expirado'">
                      <v-btn text class="btn-reativar text-none pa-0" @click="onClickReativarLead()">
                        Reativar lead
                      </v-btn>
                    </v-col>
                  </v-row>
                  <p class="mb-3">
                    <strong>Telefone: </strong>
                    <span class="blur">
                      {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}
                    </span>
                  </p>
                  <p class="mb-3">
                    <strong>E-mail: </strong>
                    <span class="blur">
                      {{ item.emailList.length ? item.emailList[0].email : " -- " }}
                    </span>
                  </p>
                  <v-row>
                    <v-col cols="9" md="8" sm="10">
                      <p class="mb-0"><strong>Motivo:</strong> {{ item.motivoNaoVenda }}</p>
                    </v-col>
                    <v-col cols="3" md="4" sm="2" class="text-right pb-3" v-if="item.motivoNaoVenda !== 'Expirado'">
                      <div class="btn-plus" @click="criarProposta = true">
                        <v-icon size="35" color="white">mdi-plus</v-icon>
                        <span class="tooltip-plus-text">
                          Criar proposta
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Estrutura Mobile -->
    <v-container fluid class="container px-0 d-flex d-lg-none">
      <div class="column pa-5 mr-5">
        <v-row class="mb-3">
          <v-col cols="6">
            <h3 class="header vendidos">
              Vendidos
            </h3>
          </v-col>
          <v-col cols="6" align-self="start" class="text-right">
            <span class="count-leads mr-3">
              {{ countLeads.vendidos > 1 ? countLeads.vendidos + " leads" : countLeads.vendidos + " lead" }}
            </span>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <span class="ordenacao">
                    <strong>Ordenar por:</strong>
                  </span>
                </v-list-item>
                <v-list-item link v-for="(item, key) in ordenacao" :key="'E' + key" @click="ordenar(item.title, 'vendidos')">
                  <v-list-item-title class="ordenacao-item">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <div id="vendidos-mobile" class="overflow">
          <v-card v-for="(item, key) in leadsVendidos" :key="'F' + key" class="card click vendidos mx-auto mb-5 mr-2" :loading="item.loading" outlined @click="showDetails(item, key, 'vendidos')">
            <v-list-item three-line>
              <v-list-item-content>
                <span class="title">
                  {{ item.nome }}
                </span>
                <v-divider class="divider vendidos mb-4" />
                <p class="mb-3"><strong>Telefone:</strong> {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}</p>
                <p v-if="item.emailList.length" class="mb-3">
                  <strong>E-mail:</strong> {{ item.emailList[0].email.length > 25 ? item.emailList[0].email.substr(0, 25) + "..." : item.emailList[0].email }}
                </p>
                <p v-else class="mb-3"><strong>E-mail:</strong> {{ " -- " }}</p>
                <p class="mb-0"><strong>Finalização:</strong> {{ formataData(item.dataHoraEncerramento) }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
      <div class="column pa-5">
        <v-row class="mb-3">
          <v-col cols="6">
            <h3 class="header nao-vendidos">
              Não vendidos
            </h3>
          </v-col>
          <v-col cols="6" class="text-right">
            <span class="count-leads mr-3">
              {{ countLeads.naoVendidos > 1 ? countLeads.naoVendidos + " leads" : countLeads.naoVendidos + " lead" }}
            </span>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <span class="ordenacao">
                    <strong>Ordenar por:</strong>
                  </span>
                </v-list-item>
                <v-list-item link v-for="(item, key) in ordenacao" :key="'G' + key" @click="ordenar(item.title, 'naoVendidos')">
                  <v-list-item-title class="ordenacao-item">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <div id="naoVendidos-mobile" class="overflow">
          <v-card
            v-for="(item, key) in leadsNaoVendidos"
            :key="'H' + key"
            class="card click nao-vendidos mx-auto mb-5 mr-2"
            :loading="item.loading"
            outlined
            @click="showDetails(item, key, 'nao-vendidos')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-row>
                  <v-col cols="6" class="pb-0">
                    <span class="title">
                      {{ item.nome }}
                    </span>
                    <v-divider class="divider nao-vendidos mb-4" />
                  </v-col>
                  <v-col cols="6" class="text-right mt-1" v-if="item.motivoNaoVenda !== 'Expirado'">
                    <v-btn text class="btn-reativar text-none pa-0" @click="onClickReativarLead()">
                      {{ $vuetify.breakpoint.xs ? "Reativar" : "Reativar lead" }}
                    </v-btn>
                  </v-col>
                </v-row>
                <p class="mb-3">
                  <strong>Telefone: </strong>
                  <span class="blur">
                    {{ item.telefoneList.length ? item.telefoneList[0].ddd + " " + item.telefoneList[0].numero : " -- " }}
                  </span>
                </p>
                <p v-if="item.emailList.length" class="mb-3">
                  <strong>E-mail: </strong>
                  <span class="blur">
                    {{ item.emailList[0].email.length > 25 ? item.emailList[0].email.substr(0, 25) + "..." : item.emailList[0].email }}
                  </span>
                </p>
                <p v-else class="mb-3"><strong>E-mail:</strong> {{ " -- " }}</p>
                <v-row>
                  <v-col cols="6">
                    <p class="mb-0"><strong>Motivo:</strong> {{ item.motivoNaoVenda.length > 25 ? item.motivoNaoVenda.substr(0, 25) + "..." : item.motivoNaoVenda }}</p>
                  </v-col>
                  <v-col cols="6" class="text-right pb-3" v-if="item.motivoNaoVenda !== 'Expirado'">
                    <div class="btn-plus" @click="criarProposta = true">
                      <v-icon size="35" color="white">mdi-plus</v-icon>
                      <span class="tooltip-plus-text">
                        Criar proposta
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="showDetailModal" :max-width="tipoModal == 'vendidos' ? 500 : 1000" @click:outside="close" @keydown.esc="close">
      <v-card class="card pa-7">
        <v-list-item-content>
          <v-row>
            <v-col>
              <v-card-title class="title-modal pa-0">
                <strong>{{ form.nome }}</strong>
              </v-card-title>
              <v-card-subtitle class="subtitle-modal pt-5 pl-0">
                <strong>Segmentação: {{ form.segmentacao }}</strong>
              </v-card-subtitle>
              <div v-if="tipoModal == 'nao-vendidos'">
                <v-divider class="mb-5" />
                <p class="mb-3">
                  <strong>
                    Motivo da finalização:
                  </strong>
                </p>
                <p>{{ form.motivoNaoVenda }}</p>
                <v-divider class="mb-5" />
              </div>
              <div class="mt-3">
                <p v-for="(item, key) in form.telefoneList" :key="'I' + key">
                  <strong>
                    Telefone:
                  </strong>
                  <span v-if="tipoModal == 'vendidos'">
                    <v-icon v-if="item.tipo === 'Celular'" small color="green darken-2">mdi-whatsapp</v-icon>
                    <v-icon v-else small color="grey darken-2">mdi-phone</v-icon>
                    <a :href="linkTelefone(item.tipo) + (item.ddd + item.numero).replace(/[^\d]+/g, '')" class="pl-0 text-decoration-none black--text text--lighter-1" target="_blank">
                      ({{ item.ddd }}) {{ item.numero }}
                    </a>
                  </span>
                  <span v-else class="blur">
                    {{ item.ddd + " " + item.numero }}
                  </span>
                </p>
                <p v-for="(item, key) in form.emailList" :key="'J' + key">
                  <strong>
                    E-mail:
                  </strong>
                  <span v-if="tipoModal == 'vendidos'">
                    <v-icon small color="grey darken-2">mdi-email</v-icon>
                    <a :href="`mailto:${item.email}`" class="pl-1 text-caption text-decoration-none black--text text--lighter-1">{{ item.email }}</a>
                  </span>
                  <span v-else class="blur">
                    {{ item.email }}
                  </span>
                </p>
                <p v-if="form.local"><strong>Cidade/UF:</strong> {{ form.local }}</p>
                <p v-if="form.dataNascimento"><strong>Data de nascimento:</strong> {{ form.dataNascimento }}</p>
                <p v-if="form.profissao"><strong>Profissão:</strong> {{ form.profissao }}</p>
                <p v-if="form.operadora"><strong>Operadora:</strong> {{ form.operadora }}</p>
                <p v-if="form.origem"><strong>Origem:</strong> {{ form.origem }}</p>
                <p v-if="form.plano"><strong>Plano:</strong> {{ form.plano }}</p>
                <p v-if="form.qntVidas"><strong>Quantidade de vidas:</strong> {{ form.qntVidas }}</p>
                <p v-if="form.nomeEmpresa"><strong>Nome da empresa:</strong> {{ form.nomeEmpresa }}</p>
                <p v-if="form.cnpjEmpresa"><strong>CNPJ da empresa:</strong> {{ form.cnpjEmpresa }}</p>
                <div v-if="form.nota && form.dataHora">
                  <h4 class="mt-6">Avaliação</h4>
                  <v-rating v-model="form.nota" background-color="blue lighten-3" color="blue" length="5" size="16" readonly class="mt-2 mb-3" />
                  <p><strong>Motivo:</strong> {{ form.motivo }}</p>
                  <p><strong>Justificativa:</strong> {{ form.justificativa }}</p>
                  <p><strong>Finalização:</strong> {{ formataData(form.dataHora) }}</p>
                </div>
              </div>
              <v-row>
                <v-col>
                  <v-expansion-panels accordion flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pl-0">
                        <strong>Visualizar histórico</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels accordion flat>
                          <v-expansion-panel v-for="(item, i) in form.historico" :key="i">
                            <v-expansion-panel-header>
                              {{ formataData(item[0].dataHoraCriacao) }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p v-for="(subitem, ii) in item" :key="ii" class="mb-2">
                                {{ subitem.descricao }}
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <div v-if="tipoModal == 'vendidos' && $vuetify.breakpoint.xs" class="mt-3">
                <v-btn color="error" block @click="close">
                  Fechar
                </v-btn>
              </div>
            </v-col>
            <v-divider vertical class="my-5" />
            <v-col v-if="tipoModal == 'nao-vendidos'" class="mt-5 pa-5">
              <div>
                <p class="mb-3">
                  <strong>
                    Reativar o lead
                  </strong>
                </p>
                <p>Após reativar o lead você poderá visualizar todas as informações pessoais do cliente.</p>
                <p>O lead reativado voltará para a aba <strong>Ativos</strong>, na coluna <strong>em Negociação.</strong></p>
              </div>
              <v-card-actions class="margin-buttons">
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined class="mr-3" @click="onClickReativarLead()" v-if="form.motivoNaoVenda !== 'Expirado'">
                  Reativar o lead
                </v-btn>
                <v-btn color="primary" @click="close">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTabulacaoModal" max-width="500" persistent>
      <v-card class="card pa-5">
        <v-row>
          <v-col>
            <v-card-title class="title">
              <strong>Qual a interação você teve com o cliente?</strong>
            </v-card-title>
            <v-list class="transparent mx-n4">
              <v-expansion-panels accordion flat>
                <v-expansion-panel v-for="(tratamento, key) in form.tratamentos" :key="key">
                  <v-expansion-panel-header>
                    {{ key }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels v-for="(item, key) in tratamento" :key="key" accordion flat>
                      <v-expansion-panel v-if="item.Descricao">
                        <v-radio-group v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                          <v-radio :value="item.Descricao" :label="item.Descricao" color="primary" />
                        </v-radio-group>
                      </v-expansion-panel>
                      <v-expansion-panel v-if="!item.Descricao">
                        <v-expansion-panel-header>
                          {{ key }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-radio-group v-for="(subitem, key) in item" :key="key" v-model="form.opcaoInteracao" hide-details class="ma-0 pa-0">
                            <v-radio :value="subitem.Descricao" :label="subitem.Descricao" color="primary" />
                          </v-radio-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list>
            <v-textarea hide-details outlined auto-grow label="Observação" v-model="form.observacao" class="mt-5" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="primary" outlined class="mr-3" @click="voltar">
              Voltar
            </v-btn>
            <v-btn color="primary" @click="salvar">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import leadService from "@/services/leadService";
import { mapActions, mapState } from "vuex";

export default {
  name: "DefaultKanban",
  props: {
    leads: {
      type: Object,
      required: true,
    },
    defaultURL: {
      type: String,
      required: true,
      default: null,
    },
    leadSelecionado: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {},
      tipoModal: null,
      showDetailModal: false,
      showTabulacaoModal: false,
      criarProposta: false,
      reativarLead: false,
      ordenacao: [{ title: "Atualizações mais recentes" }, { title: "Atualizações menos recentes" }, { title: "Data de recebimento mais recente" }, { title: "Data de recebimento menos recente" }],
      qtdItensVendidos: 20,
      qtdItensNaoVendidos: 20,
    };
  },
  mounted() {
    // Funcionalidade de carregar lista conforme scroll
    const listElm = ["#vendidos", "#naoVendidos", "#vendidos-mobile", "#naoVendidos-mobile"];
    for (let i = 0; i < listElm.length; i++) {
      const elem = document.querySelector(listElm[i]);
      elem.addEventListener("scroll", (e) => {
        if (elem.scrollTop + elem.offsetHeight >= elem.scrollHeight - 100) this.loadMore(listElm[i]);
      });
    }
    this.loadMore();

    if (this.leadSelecionado) this.showDetailsLeadSelecionado(this.leadSelecionado);
  },
  methods: {
    ...mapActions({
      setIdLead: "cadastroProposta/setIdLead",
    }),
    async showDetails(item, key, option) {
      /* eslint-disable */
      if (!this.criarProposta && !this.reativarLead) {
        let nomeColuna = null;

        try {
          if (option === "vendidos") nomeColuna = "vendidos";
          else nomeColuna = "naoVendidos";

          this.$set(this.leads[nomeColuna][key], "loading", true);

          const statusFinalizacao = ["Expirado", "Proposta gerada por outro corretor", "Convertido", "Convertido por outro corretor", "Proposta cancelada", "Não trabalhado", "Finalizado"];
          const lead = await leadService.getLead({ codigo: item.codigo });
          const historicoLead = await leadService.searchHistory(item.codigo);
          const groupByHistoricoLead = groupBy(historicoLead, "dataHoraCriacao");

          const sortedGroupByHistoricoLead = Object.keys(groupByHistoricoLead)
            .sort()
            .reverse()
            .reduce((accumulator, key) => {
              accumulator[key] = groupByHistoricoLead[key];
              return accumulator;
            }, {});

          let newOrigem = lead.plataforma ? lead.plataforma : "";
          if (newOrigem !== "" && lead.grupoOrigem) {
            newOrigem = newOrigem.concat(" - ".concat(lead.grupoOrigem ? lead.grupoOrigem : ""));
          }

          this.form = {
            idLead: lead.idLead,
            nome: lead.nome,
            codigo: lead.codigo,
            segmentacao: lead.segmentacao || "--",
            telefoneList: lead.telefones && lead.telefones.length ? lead.telefones : [],
            emailList: lead.emails && lead.emails.length ? lead.emails : [],
            local: lead.cidade && lead.estado ? lead.cidade.concat(" - ").concat(lead.estado) : null,
            origem: newOrigem || "--",
            motivoNaoVenda: lead.tratamentoAplicado ? lead.tratamentoAplicado : statusFinalizacao.includes(lead.status) ? lead.status : "--",
            tratamentos: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos) : {},
            opcaoInteracao: null,
            historico: sortedGroupByHistoricoLead ?? {},
            observacao: lead.observacao || null,
            justificativa: lead.justificativaAvaliacao || null,
            motivo: lead.motivoAvaliacao || null,
            nota: lead.notaAvaliacao || null,
            dataHora: lead.datahoraAvaliacao || null,
            dataNascimento: lead.dataNascimento ? this.formataDataNascimento(lead.dataNascimento) : null,
            profissao: lead.profissao || null,
            operadora: lead.operadora || null,
            plano: lead.plano || null,
            qntVidas: lead.qntVidas || null,
            nomeEmpresa: lead.nomeEmpresa || null,
            cnpjEmpresa: lead.cnpjEmpresa ? lead.cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : null,
          };

          history.pushState({}, null, `${this.defaultURL}/${item.codigo}`);
          this.$emit("modal", false);

          this.checkChanges = cloneDeep(this.form);

          this.tipoModal = option;
          this.showDetailModal = true;
        } catch (error) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Erro ao carregar dados do lead",
          });
          history.pushState({}, null, this.defaultURL);
          this.$emit("modal", true);
        } finally {
          this.$set(this.leads[nomeColuna][key], "loading", false);
        }
      } else if (this.criarProposta) {
        this.setIdLead(item.idLead);
        this.$router.push({ name: "areaLogada.criarProposta", query: { idLead: item.idLead } });
        this.criarProposta = false;
      } else if (this.reativarLead) {
        // Código para reativar lead
        const lead = await leadService.getLead({ codigo: item.codigo });
        this.form = {
          codigo: lead.codigo,
          tratamentos: lead.tratamentos.length ? this.setTratamentos(lead.tratamentos) : {},
          opcaoInteracao: null,
          observacao: lead.observacao || null,
          reativado: this.reativarLead,
          idLead: lead.idLead,
        };
        this.checkChanges = cloneDeep(this.form);
        this.reativarLead = false;
      }
      /* eslint-enable */
    },
    async showDetailsLeadSelecionado(codigo) {
      /* eslint-disable */
      try {
        const statusFinalizacao = ["Expirado", "Proposta gerada por outro corretor", "Convertido", "Convertido por outro corretor", "Proposta cancelada", "Não trabalhado", "Finalizado"];
        const lead = await leadService.getLead({ codigo });
        const historicoLead = await leadService.searchHistory(codigo);
        const groupByHistoricoLead = groupBy(historicoLead, "dataHoraCriacao");

        const sortedGroupByHistoricoLead = Object.keys(groupByHistoricoLead)
          .sort()
          .reverse()
          .reduce((accumulator, key) => {
            accumulator[key] = groupByHistoricoLead[key];
            return accumulator;
          }, {});

        let newOrigem = lead.plataforma ? lead.plataforma : "";
        if (newOrigem !== "" && lead.grupoOrigem) {
          newOrigem = newOrigem.concat(" - ".concat(lead.grupoOrigem ? lead.grupoOrigem : ""));
        }

        this.form = {
          idLead: lead.idLead,
          nome: lead.nome,
          codigo: lead.codigo,
          segmentacao: lead.segmentacao || "--",
          telefoneList: lead.telefones && lead.telefones.length ? lead.telefones : [],
          emailList: lead.emails && lead.emails.length ? lead.emails : [],
          local: lead.cidade && lead.estado ? lead.cidade.concat(" - ").concat(lead.estado) : null,
          origem: newOrigem || "--",
          motivoNaoVenda: lead.tratamentoAplicado ? lead.tratamentoAplicado : statusFinalizacao.includes(lead.status) ? lead.status : "--",
          tratamentos: lead.tratamentos && lead.tratamentos.length ? this.setTratamentos(lead.tratamentos) : {},
          opcaoInteracao: null,
          historico: sortedGroupByHistoricoLead ?? {},
          observacao: lead.observacao || null,
          justificativa: lead.justificativaAvaliacao || null,
          motivo: lead.motivoAvaliacao || null,
          nota: lead.notaAvaliacao || null,
          dataHora: lead.datahoraAvaliacao || null,
          dataNascimento: lead.dataNascimento ? this.formataDataNascimento(lead.dataNascimento) : null,
          profissao: lead.profissao || null,
          operadora: lead.operadora || null,
          plano: lead.plano || null,
          qntVidas: lead.qntVidas || null,
          nomeEmpresa: lead.nomeEmpresa || null,
          cnpjEmpresa: lead.cnpjEmpresa ? lead.cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : null,
        };

        this.$emit("modal", false);

        this.checkChanges = cloneDeep(this.form);

        this.tipoModal = lead.nrPropostaVenda ? "vendidos" : "nao-vendidos";
        this.showDetailModal = true;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao carregar dados do lead",
        });
        this.$emit("modal", true);
      }
      /* eslint-enable */
    },
    formataData(data) {
      return moment(data).format("DD/MM/YYYY HH[h]mm");
    },
    formataDataNascimento(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    ordenar(item, coluna) {
      /* eslint-disable */
      if (item === "Atualizações mais recentes") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao)).reverse();
      else if (item === "Atualizações menos recentes") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraTratamento || e.dataHoraCriacao));
      else if (item === "Data de recebimento mais recente") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraCriacao)).reverse();
      else if (item === "Data de recebimento menos recente") this.leads[coluna] = sortBy(this.leads[coluna], (e) => new Date(e.dataHoraCriacao));
      this.$emit("setOrdenacao", { item, coluna });
      /* eslint-enable */
    },
    async onClickReativarLead() {
      /* eslint-disable */
      this.reativarLead = true;
      const resp = await this.$root.$confirmdialog.open(
        "Reativar o lead",
        `Após reativar o lead você poderá visualizar todas as informações pessoais do cliente.
        O lead reativado voltará para a aba <strong>Ativos</strong>, na coluna <strong>Em negociação</strong>.
        <strong>Tem certeza que deseja continuar?`
      );
      if (!resp) return;
      this.showTabulacaoModal = true;
      /* eslint-enable */
    },
    showMessage(msg, tipo) {
      this.$root.$snackBar.open({
        color: tipo,
        message: msg,
      });
    },
    voltar() {
      this.showMessage("Lead não reativado por falta de tabulação", "warning");
      this.showTabulacaoModal = false;
    },
    async salvar() {
      /* eslint-disable */
      try {
        const { codigo, opcaoInteracao, observacao, idLead } = this.form;

        if (!opcaoInteracao) {
          this.showMessage("O campo interação deve ser selecionado", "warning");
          return;
        }

        const formData = {
          codigo,
          tipoTratamento: opcaoInteracao,
          agendamento: { dataHoraAgendamento: "", msgDataAgendamento: "" },
          observacao: observacao || "",
          status: "Em negociação",
          reativado: true,
          idLead: idLead,
        };

        await leadService.alterar({ codigo, body: formData });

        const historico = [
          {
            tipo: "alteracao-tratamento",
            descricao: `Alterou o tratamento para: ${opcaoInteracao}`,
          },
        ];

        if (observacao && !this.checkChanges.observacao)
          historico.push({
            tipo: "inclusao-observacao",
            descricao: `Foi inserida uma observação: ${observacao}`,
          });
        else if (observacao !== this.checkChanges.observacao)
          historico.push({
            tipo: "alteracao-observacao",
            descricao: `Alterou a observação para: ${observacao}`,
          });

        const historicoFormData = {
          historico,
        };

        await leadService.updateHistoryTreatment(formData.codigo, historicoFormData);

        // Recarrega lista de leads
        this.$emit("carrega-dados");
        this.showMessage("Lead reativado com sucesso", "success");

        history.pushState({}, null, this.defaultURL);
        this.$emit("modal", true);

        this.showDetailModal = false;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao reativar o lead",
        });
      }
      this.showTabulacaoModal = false;
      /* eslint-enable */
    },
    setTratamentos(tratamentos) {
      const tratamentoList = {};
      for (let i = 0; i < tratamentos.length; i++) {
        const tratamento = tratamentos[i];
        if (tratamento.Status === "Ativo" && tratamento.statusLead === "Em negociação" && tratamento.Descricao !== "Finalização") {
          if (tratamento.Lista && !tratamento.Lista.length) tratamentoList[tratamento.Descricao] = tratamento;
          else tratamentoList[tratamento.Descricao] = {};
          for (let ii = 0; ii < tratamento.Lista.length; ii++) {
            const itemList = tratamento.Lista[ii];
            if (itemList.Status === "Ativo" && itemList.statusLead === "Em negociação") {
              if (itemList.Lista && !itemList.Lista.length) tratamentoList[tratamento.Descricao][itemList.Descricao] = itemList;
              else tratamentoList[tratamento.Descricao][itemList.Descricao] = {};
              for (let iii = 0; iii < itemList.Lista.length; iii++) {
                tratamentoList[tratamento.Descricao][itemList.Descricao][itemList.Lista[iii].Descricao] = itemList.Lista[iii];
              }
            }
          }
        }
      }
      const objKeys = Object.keys(tratamentoList);
      for (let i = 0; i < objKeys.length; i++) {
        if (tratamentoList[objKeys[i]].id) {
          tratamentoList[objKeys[i]] = { "Não categorizado": tratamentoList[objKeys[i]] };
        }
      }
      const sortedTratamentoList = Object.keys(tratamentoList)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = tratamentoList[key];
          return accumulator;
        }, {});
      return sortedTratamentoList;
    },
    close() {
      /* eslint-disable */
      this.showDetailModal = false;
      history.pushState({}, null, this.defaultURL);
      this.$emit("modal", true);
      /* eslint-enable */
    },
    /* eslint-disable */
    loadMore(elem) {
      if (elem === "#vendidos" || elem === "#vendidos-mobile") this.qtdItensVendidos = this.qtdItensVendidos + 20;
      if (elem === "#naoVendidos" || elem === "#naoVendidos-mobile") this.qtdItensNaoVendidos = this.qtdItensNaoVendidos + 20;
    },
    /* eslint-enable */
    linkTelefone(tipo) {
      return tipo === "Celular" ? "https://api.whatsapp.com/send?phone=55" : "tel:+55";
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
    countLeads() {
      return {
        vendidos: this.leads.vendidos.length,
        naoVendidos: this.leads.naoVendidos.length,
      };
    },
    leadsVendidos() {
      const { vendidos } = this.leads;
      return vendidos.slice(0, this.qtdItensVendidos);
    },
    leadsNaoVendidos() {
      const { naoVendidos } = this.leads;
      return naoVendidos.slice(0, this.qtdItensNaoVendidos);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.column {
  background-color: #fff;
  max-height: 100%;
  border: 1px solid darken(#f4f2f1, 10%);
  border-radius: 10px;

  @media (max-width: $media-sm) {
    min-width: 80%;
  }

  @media (min-width: $media-sm) and (max-width: $media-md) {
    min-width: 70%;
  }

  @media (min-width: $media-md) and (max-width: $media-lg) {
    min-width: 60%;
  }

  @media (min-width: $media-sm) and (max-width: $media-lg) {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  }
}

.container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  @media (min-width: $media-sm) and (max-width: $media-lg) {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  }
}

.container::-webkit-scrollbar {
  height: 7.5px;
  background-color: #f4f2f1;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 100px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #616166;
}

.overflow {
  height: calc(100vh - 23.5rem);
  @media (max-width: $media-md) {
    height: calc(100vh - 29rem);
  }
  width: 100%;
  overflow-y: auto;
}

.overflow::-webkit-scrollbar {
  width: 7.5px;
  background-color: #f4f2f1;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 100px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #616166;
}

.header {
  font-size: 1.3rem;

  &.vendidos {
    color: #0f9922;
  }

  &.nao-vendidos {
    color: #cc1414;
  }
}

.ordenacao {
  color: $azul-qualicorp-hex;
  font-size: 1.2rem;
}

.count-leads,
.ordenacao-item:hover {
  color: $azul-qualicorp-hex;
}

.card {
  .title,
  .subtitle-modal {
    font-size: 1.2rem;
  }

  .motivo {
    max-width: 150px;
  }

  .margin-buttons {
    @media (max-width: $media-md) {
      margin-top: 10%;
    }

    @media (min-width: $media-md) {
      margin-top: 100%;
    }
  }

  &.click {
    cursor: pointer;
  }

  &.vendidos {
    background-color: #ccffd3;
    border: 1px solid #6dcc7a;
  }

  &.nao-vendidos {
    background-color: #ffe5e5;
    border: 1px solid #ffb2b2;
  }

  .divider {
    &.vendidos {
      border: 1px solid #6dcc7a;
    }

    &.nao-vendidos {
      border: 1px solid #ffb2b2;
    }
  }

  .btn-reativar {
    color: $azul-qualicorp-hex;
    font-size: 1rem;
    text-decoration: underline 2px;
    text-underline-offset: 6px;
  }

  .btn-plus {
    height: 40px;
    width: 40px;
    margin: -20px 0 0 13px;
    line-height: 42px;
    padding: 0 5px 0 2.5px;
    background-color: $azul-qualicorp-hex;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    top: -15px;
    cursor: pointer;
  }

  .btn-plus .tooltip-plus-text {
    visibility: hidden;
    background-color: $azul-qualicorp-hex;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    line-height: 20px;
    top: 5px;
    width: 110px;
    left: -115px;
    padding: 5px;
    position: absolute;
    z-index: 1;
  }

  .btn-plus:hover .tooltip-plus-text {
    visibility: visible;
  }

  .blur {
    filter: blur(3px);
    user-select: none;
  }
}
</style>
