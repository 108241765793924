<template>
  <div>
    <v-navigation-drawer v-model="drawerComp" app right temporary disable-resize-watcher v-click-outside="handleClickOutside" class="sidebar">
      <div class="sidebar-content">
        <v-row>
          <v-col cols="6">
            <div class="sidebar-title">
              Filtros
            </div>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn text class="btn-limpar text-none pa-0" @click="limpar">
              Limpar
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="tab === 0 || tab === 1" class="pa-3">
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Leads sem agendamento
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="#14CB2D" dense v-model="form.leadsSemAgendamento" inset />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Data de recebimento
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="#14CB2D" dense :disabled="form.dataAtualizacao" v-model="form.dataRecebimento" inset />
            </v-col>
          </v-row>
          <v-row v-if="form.dataRecebimento" class="my-0">
            <v-col class="pt-0">
              De/até:
              <v-dialog ref="dialogRecebimentoInicioFim" v-model="modalDataRecebimentoInicioFim" :return-value.sync="form.dataRecebimentoInicioFim" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataRecebimentoInicioFimFormatada"
                    label="Datas"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :clear-icon-cb="clearDataRecebimentoInicioFim"
                    solo
                    :disabled="!form.dataRecebimento || form.dataAtualizacao"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.dataRecebimentoInicioFim" scrollable range locale="pt" selected-items-text="2 selecionadas">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="clearDataRecebimentoInicioFim"> Limpar </v-btn>
                  <v-btn text color="primary" @click="modalDataRecebimentoInicioFim = false"> Cancelar </v-btn>
                  <v-btn text color="primary" @click="applyDataRecebimentoInicioFim"> Aplicar </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Data de atualização
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="#14CB2D" dense :disabled="form.dataRecebimento" v-model="form.dataAtualizacao" inset />
            </v-col>
          </v-row>
          <v-row v-if="form.dataAtualizacao" class="my-0">
            <v-col class="pt-0">
              De/até:
              <v-dialog ref="dialogAtualizacaoInicioFim" v-model="modalDataAtualizacaoInicioFim" :return-value.sync="form.dataAtualizacaoInicioFim" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataAtualizacaoInicioFimFormatada"
                    label="Datas"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :clear-icon-cb="clearDataAtualizacaoInicioFim"
                    solo
                    :disabled="!form.dataAtualizacao || form.dataRecebimento"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.dataAtualizacaoInicioFim" scrollable range locale="pt" selected-items-text="2 selecionadas">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="clearDataAtualizacaoInicioFim"> Limpar </v-btn>
                  <v-btn text color="primary" @click="modalDataAtualizacaoInicioFim = false"> Cancelar </v-btn>
                  <v-btn text color="primary" @click="applyDataAtualizacaoInicioFim"> Aplicar </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Cotação enviada
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="#14CB2D" dense v-model="form.cotacaoEnviada" inset />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Propostas enviadas
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="green" dense v-model="form.propostasEnviadas" inset />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Negociação iniciada no Hotline
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="green" dense v-model="form.negociacaoHotline" inset />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Negociação iniciada no Qualiatende
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="green" dense v-model="form.negociacaoQualiatende" inset />
            </v-col>
          </v-row>
          <v-divider class="mb-5" />
          <v-btn block x-large color="primary" @click="applyFilters('default')"> FILTRAR ({{ countFilters }}) </v-btn>
        </div>
        <div v-if="tab === 2" class="pa-3">
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Data de recebimento
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="#14CB2D" dense :disabled="form.dataFinalizacao" v-model="form.dataRecebimento" inset />
            </v-col>
          </v-row>
          <v-row v-if="form.dataRecebimento" class="my-0">
            <v-col class="pt-0">
              De/até:
              <v-dialog ref="dialogRecebimentoInicioFim" v-model="modalDataRecebimentoInicioFim" :return-value.sync="form.dataRecebimentoInicioFim" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataRecebimentoInicioFimFormatada"
                    label="Datas"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :clear-icon-cb="clearDataRecebimentoInicioFim"
                    solo
                    :disabled="!form.dataRecebimento || form.dataFinalizacao"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.dataRecebimentoInicioFim" scrollable range locale="pt" selected-items-text="2 selecionadas">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="clearDataRecebimentoInicioFim"> Limpar </v-btn>
                  <v-btn text color="primary" @click="modalDataRecebimentoInicioFim = false"> Cancelar </v-btn>
                  <v-btn text color="primary" @click="applyDataRecebimentoInicioFim"> Aplicar </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col align-self="center" class="py-2">
              <span>
                Data de finalização
              </span>
            </v-col>
            <v-col cols="2" class="text-right mr-3 py-2">
              <v-switch color="#14CB2D" dense :disabled="form.dataRecebimento" v-model="form.dataFinalizacao" inset />
            </v-col>
          </v-row>
          <v-row v-if="form.dataFinalizacao" class="my-0">
            <v-col class="pt-0">
              De/até:
              <v-dialog ref="dialogFinalizacaoInicioFim" v-model="modalDataFinalizacaoInicioFim" :return-value.sync="form.dataFinalizacaoInicioFim" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataFinalizacaoInicioFimFormatada"
                    label="Datas"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :clear-icon-cb="clearDataFinalizacaoInicioFim"
                    solo
                    :disabled="!form.dataFinalizacao || form.dataRecebimento"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.dataFinalizacaoInicioFim" scrollable range locale="pt" selected-items-text="2 selecionadas">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="clearDataFinalizacaoInicioFim"> Limpar </v-btn>
                  <v-btn text color="primary" @click="modalDataFinalizacaoInicioFim = false"> Cancelar </v-btn>
                  <v-btn text color="primary" @click="applyDataFinalizacaoInicioFim"> Aplicar </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider class="mb-5" />
          <v-btn block x-large color="primary" @click="applyFilters('finalizados')"> FILTRAR ({{ countFilters }}) </v-btn>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { parse } from "date-fns";
import ClickOutside from "vue-click-outside";

export default {
  components: {},
  directives: {
    ClickOutside,
  },
  data() {
    return {
      modalDataRecebimentoInicioFim: false,
      modalDataAtualizacaoInicioFim: false,
      modalDataFinalizacaoInicioFim: false,
    };
  },
  props: {
    drawer: {
      default: false,
      type: Boolean,
    },
    form: {},
    tab: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    drawerComp: {
      get() {
        return this.drawer;
      },
      set() {},
    },
    dataRecebimentoInicioFimFormatada: {
      get() {
        return this.formatDate(this.form.dataRecebimentoInicioFim);
      },
      set(dataRecebimentoInicioFim) {
        this.form.dataRecebimentoInicioFim = dataRecebimentoInicioFim;
        this.$refs.dialogRecebimentoInicioFim.save(this.form.dataRecebimentoInicioFim);
        this.menu = false;
      },
    },
    dataAtualizacaoInicioFimFormatada: {
      get() {
        return this.formatDate(this.form.dataAtualizacaoInicioFim);
      },
      set(dataAtualizacaoInicioFim) {
        this.form.dataAtualizacaoInicioFim = dataAtualizacaoInicioFim;
        this.$refs.dialogAtualizacaoInicioFim.save(this.form.dataAtualizacaoInicioFim);
        this.menu = false;
      },
    },
    dataFinalizacaoInicioFimFormatada: {
      get() {
        return this.formatDate(this.form.dataFinalizacaoInicioFim);
      },
      set(dataFinalizacaoInicioFim) {
        this.form.dataFinalizacaoInicioFim = dataFinalizacaoInicioFim;
        this.$refs.dialogFinalizacaoInicioFim.save(this.form.dataFinalizacaoInicioFim);
        this.menu = false;
      },
    },
    countFilters() {
      return Object.values(this.form).filter((item) => item === true).length;
    },
  },
  methods: {
    async applyFilters(type) {
      if (type === "default") {
        const { dataRecebimento, dataRecebimentoInicioFim } = this.form;
        const dataRecebimentoInicio = dataRecebimentoInicioFim && dataRecebimentoInicioFim.length ? dataRecebimentoInicioFim[0] : null;
        const dataRecebimentoFim = dataRecebimentoInicioFim && dataRecebimentoInicioFim.length ? dataRecebimentoInicioFim[1] : null;
        let checkDataRecebimento = false;

        if (dataRecebimento) {
          if (dataRecebimentoInicio && dataRecebimentoFim) {
            checkDataRecebimento = parse(dataRecebimentoFim, "yyyy-MM-dd", new Date()) >= parse(dataRecebimentoInicio, "yyyy-MM-dd", new Date());
          } else checkDataRecebimento = false;
        } else {
          this.form.dataRecebimentoInicioFim = [];
          checkDataRecebimento = true;
        }

        const { dataAtualizacao, dataAtualizacaoInicioFim } = this.form;
        const dataAtualizacaoInicio = dataAtualizacaoInicioFim && dataAtualizacaoInicioFim.length ? dataAtualizacaoInicioFim[0] : null;
        const dataAtualizacaoFim = dataAtualizacaoInicioFim && dataAtualizacaoInicioFim.length ? dataAtualizacaoInicioFim[1] : null;
        let checkDataAtualizacao = false;

        if (dataAtualizacao) {
          if (dataAtualizacaoInicio && dataAtualizacaoFim) {
            checkDataAtualizacao = parse(dataAtualizacaoFim, "yyyy-MM-dd", new Date()) >= parse(dataAtualizacaoInicio, "yyyy-MM-dd", new Date());
          } else checkDataAtualizacao = false;
        } else {
          this.form.dataAtualizacaoInicioFim = [];
          checkDataAtualizacao = true;
        }

        if (checkDataRecebimento && checkDataAtualizacao) {
          const copyForm = JSON.parse(JSON.stringify(this.form));
          this.drawerComp = false;
          this.$emit("apply-filters", copyForm);
        } else {
          this.$root.$snackBar.open({
            color: "warning",
            message: "Datas inválidas",
          });
        }
      } else if (type === "finalizados") {
        const { dataRecebimento, dataRecebimentoInicioFim } = this.form;
        const dataRecebimentoInicio = dataRecebimentoInicioFim && dataRecebimentoInicioFim.length ? dataRecebimentoInicioFim[0] : null;
        const dataRecebimentoFim = dataRecebimentoInicioFim && dataRecebimentoInicioFim.length ? dataRecebimentoInicioFim[1] : null;
        let checkDataRecebimento = false;

        if (dataRecebimento) {
          if (dataRecebimentoInicio && dataRecebimentoFim) {
            checkDataRecebimento = parse(dataRecebimentoFim, "yyyy-MM-dd", new Date()) >= parse(dataRecebimentoInicio, "yyyy-MM-dd", new Date());
          } else checkDataRecebimento = false;
        } else {
          this.form.dataRecebimentoInicioFim = [];
          checkDataRecebimento = true;
        }

        const { dataFinalizacao, dataFinalizacaoInicioFim } = this.form;
        const dataFinalizacaoInicio = dataFinalizacaoInicioFim && dataFinalizacaoInicioFim.length ? dataFinalizacaoInicioFim[0] : null;
        const dataFinalizacaoFim = dataFinalizacaoInicioFim && dataFinalizacaoInicioFim.length ? dataFinalizacaoInicioFim[1] : null;
        let checkDataFinalizacao = false;

        if (dataFinalizacao) {
          if (dataFinalizacaoInicio && dataFinalizacaoFim) {
            checkDataFinalizacao = parse(dataFinalizacaoFim, "yyyy-MM-dd", new Date()) >= parse(dataFinalizacaoInicio, "yyyy-MM-dd", new Date());
          } else checkDataFinalizacao = false;
        } else {
          this.form.dataFinalizacaoInicioFim = [];
          checkDataFinalizacao = true;
        }

        if (checkDataRecebimento && checkDataFinalizacao) {
          const copyForm = JSON.parse(JSON.stringify(this.form));
          this.drawerComp = false;
          this.$emit("apply-filters", copyForm);
        } else {
          this.$root.$snackBar.open({
            color: "warning",
            message: "Datas inválidas",
          });
        }
      }
    },
    ordenarPorData(dates) {
      return dates.sort((a, b) => {
        const date1 = new Date(a);
        const date2 = new Date(b);
        return date1 - date2;
      });
    },
    formatDate(dates) {
      if (!dates) return null;
      if (dates.length >= 2) {
        const sortedDates = this.ordenarPorData(dates);
        const dateRange = sortedDates.map((item) => {
          const [year, month, day] = item.split("-");
          return `${day}/${month}/${year}`;
        });
        return dateRange.join(" ~ ");
      }
      return null;
    },
    applyDataRecebimentoInicioFim() {
      if (this.form.dataRecebimentoInicioFim && this.form.dataRecebimentoInicioFim.length >= 2) this.$refs.dialogRecebimentoInicioFim.save(this.form.dataRecebimentoInicioFim);
      this.modalDataRecebimentoInicioFim = false;
    },
    applyDataAtualizacaoInicioFim() {
      if (this.form.dataAtualizacaoInicioFim && this.form.dataAtualizacaoInicioFim.length >= 2) this.$refs.dialogAtualizacaoInicioFim.save(this.form.dataAtualizacaoInicioFim);
      this.modalDataAtualizacaoInicioFim = false;
    },
    applyDataFinalizacaoInicioFim() {
      if (this.form.dataFinalizacaoInicioFim && this.form.dataFinalizacaoInicioFim.length >= 2) this.$refs.dialogFinalizacaoInicioFim.save(this.form.dataFinalizacaoInicioFim);
      this.modalDataFinalizacaoInicioFim = false;
    },
    clearDataRecebimentoInicioFim() {
      this.form.dataRecebimentoInicioFim = [];
      this.$refs.dialogRecebimentoInicioFim.save(this.form.dataRecebimentoInicioFim);
      this.modalDataRecebimentoInicioFim = false;
    },
    clearDataAtualizacaoInicioFim() {
      this.form.dataAtualizacaoInicioFim = [];
      this.$refs.dialogAtualizacaoInicioFim.save(this.form.dataAtualizacaoInicioFim);
      this.modalDataAtualizacaoInicioFim = false;
    },
    clearDataFinalizacaoInicioFim() {
      this.form.dataFinalizacaoInicioFim = [];
      this.$refs.dialogFinalizacaoInicioFim.save(this.form.dataFinalizacaoInicioFim);
      this.modalDataFinalizacaoInicioFim = false;
    },
    limpar() {
      this.$emit("clear-filters");
    },
    handleClickOutside() {
      /* eslint-disable */
      const checkClosedModals = !this.modalDataRecebimentoInicioFim && !this.modalDataAtualizacaoInicioFim && !this.modalDataFinalizacaoInicioFim;
      if (this.drawerComp && checkClosedModals) this.$emit("close-filters");
      /* eslint-enable */
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.sidebar {
  @media (max-width: $media-sm) {
    min-width: 80%;
  }
  @media (min-width: $media-sm) and (max-width: $media-md) {
    min-width: 60%;
  }
  @media (min-width: $media-md) and (max-width: $media-lg) {
    min-width: 40%;
  }
  @media (min-width: $media-lg) and (max-width: $media-xl) {
    min-width: 30%;
  }
  @media (min-width: $media-xl) {
    min-width: 18%;
  }
}

.sidebar-content {
  min-height: 100vh;
}

.sidebar-title {
  color: $azul-qualicorp-hex;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.3rem 1rem 1rem 1rem;
}

.btn-limpar {
  color: $azul-qualicorp-hex;
  font-size: 1.3rem;
  margin: 1rem;
  cursor: pointer;
  text-decoration: underline 2px;
  text-underline-offset: 4px;
}
</style>
